import { ScheduleImpactSummaryType } from "./model";
import ScheduleImpactSummary from "./schedule-impact-summary";
import CompareScheduleV3 from "../compare-schedule-3";

export function ScheduleImpactList({
  scheduleImpactSummary
}: {
  scheduleImpactSummary: ScheduleImpactSummaryType;
}) {
  return (
    <div className="h-full">
      <ScheduleImpactSummary scheduleImpactSummary={scheduleImpactSummary} />
      <div className="flex">
        <CompareScheduleV3 />
      </div>
    </div>
  );
}
