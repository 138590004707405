import { Button } from "antd";

function ReportCard({
  icon,
  title,
  detail,
  onClick
}: {
  icon: any;
  title: string;
  detail: string;
  onClick: () => void;
}) {
  return (
    <div className="flex flex-col w-96 h-64 border-solid border-gray-300 border-[1px]">
      <div className="w-full bg-gray-100 font-medium items-center py-1 px-2">
        {title}
      </div>
      <div className="grow">
        <div className="flex h-full p-1">
          <div className="p-1 justify-center">{icon}</div>
          <div className="px-1 text-justify">{detail}</div>
        </div>
      </div>
      <Button type="primary" className="h-8 w-full " onClick={onClick}>
        VIEW
      </Button>
    </div>
  );
}
export default ReportCard;
