import { Modal, DatePicker, Button } from "antd";
import "./date-range-picker.css";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useMemo, useCallback, useContext } from "react";
import moment, { Moment } from "moment";

const { RangePicker } = DatePicker;

function DateRangePickerModal(props: any) {
  const { title, isOpen, setIsOpen, setCustomDateRange, onOkClick } = props;
  const { calHolidaysWeekends }: TProjectContext = useContext(ProjectContext);

  const holidays: any = useMemo(
    () => calHolidaysWeekends?.holidays,
    [calHolidaysWeekends?.holidays]
  );

  const weekends: any = useMemo(
    () => calHolidaysWeekends?.weekends,
    [calHolidaysWeekends?.weekends]
  );

  const applyHolidays = useCallback(
    (current: Moment) => {
      let disableHolidaysResult = false;
      let disableWeekendsResult = false;

      if (holidays) {
        disableHolidaysResult = !!holidays.find((hld: any) =>
          current.isSame(moment(hld?.date), "D")
        );
      }

      if (weekends) {
        disableWeekendsResult = weekends.some((wkend: number) => {
          return current.day() === wkend;
        });
      }
      return disableHolidaysResult || disableWeekendsResult;
    },
    [holidays, weekends]
  );

  return (
    <div>
      <Modal
        title={title}
        open={isOpen}
        className="custom-date-picker"
        onCancel={() => {
          onOkClick();
          setIsOpen(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setCustomDateRange();
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onOkClick();
              setIsOpen(false);
            }}
          >
            Apply
          </Button>
        ]}
      >
        <RangePicker
          autoFocus
          open
          className="customRangePicker1"
          format="DD-MM-YYYY"
          onChange={(value) => setCustomDateRange(value)}
          defaultOpen
          getPopupContainer={(trigger) => {
            return trigger;
          }}
          disabledDate={applyHolidays}
        />
      </Modal>
    </div>
  );
}

export default DateRangePickerModal;
