import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Space, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useCIQMutation, useCIQQuerySubscription } from "hooks/ciq-gql-hooks";
import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { GET_MDB_TEMPLATES } from "services/graphQL/ciq-queries";
import DefaultTag from "components/widgets/default-tag";
import {
  MUTATION_COPY_MDB_TEMPLATE,
  MUTATION_SET_DEFAULT_MDB_TEMPLATE,
  MUTATION_UPDATE_PROJECT_MDB_TEMPLATE,
  MUTATION_UPDATE_PROJECT_TEMPLATE_API
} from "services/graphQL/mutations";
import { TSaveWorkflowModelInput } from "components/workflow-template-editor/model";
import WorkflowTemplateEditor from "components/workflow-template-editor";
import {
  ErrorMessages,
  ProjectPermissionEnum,
  SuccessMessages
} from "../../../../../constants";
import MaterialTemplateList from "./material-template-list";

export default function MaterialTemplates() {
  const { gqlClientForProject, tokenContents }: TProjectContext =
    useContext(ProjectContext);

  const [detailsViewData, setdetailsViewData] = useState<any>(null);
  const [savingDefaultTemplate, setSavingDefaultTemplate] = useState(false);
  const [materialTemplates, setmaterialTemplates] = useState<
    any[] | undefined
  >();

  const { data: MDBTemplates } = useCIQQuerySubscription<{
    project_workflow_templates: Array<any>;
  }>(GET_MDB_TEMPLATES, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const clonedMDBTemplatesSoruce = useMemo(() => {
    if (!MDBTemplates || !MDBTemplates.project_workflow_templates)
      return undefined;
    return structuredClone(MDBTemplates.project_workflow_templates);
  }, [MDBTemplates]);

  useEffect(() => {
    if (!MDBTemplates || !MDBTemplates.project_workflow_templates) return;
    setmaterialTemplates(clonedMDBTemplatesSoruce);
  }, [MDBTemplates, clonedMDBTemplatesSoruce]);

  const [saveProjectTemplateMutation] = useCIQMutation(
    MUTATION_UPDATE_PROJECT_TEMPLATE_API,
    { client: gqlClientForProject }
  );

  const [setDefaultMDBTemplate] = useCIQMutation(
    MUTATION_SET_DEFAULT_MDB_TEMPLATE,
    {
      client: gqlClientForProject
    }
  );

  const [copyMDBTemplateMutation] = useCIQMutation(MUTATION_COPY_MDB_TEMPLATE, {
    client: gqlClientForProject
  });

  const [disableMDBTemplate] = useCIQMutation(
    MUTATION_UPDATE_PROJECT_MDB_TEMPLATE,
    {
      client: gqlClientForProject
    }
  );

  const canEditMaterialDBTemplate = !isPermissionNotGrantted(
    ProjectPermissionEnum.EditMaterialDBTemplate,
    tokenContents?.role!
  );

  const templateObjectForDetailsView = useMemo(() => {
    if (!detailsViewData || !MDBTemplates?.project_workflow_templates)
      return undefined;
    return MDBTemplates.project_workflow_templates.find((template: any) => {
      return template.id === detailsViewData.data.id;
    });
  }, [MDBTemplates, detailsViewData]);

  const onSaveTemplate = async (data: TSaveWorkflowModelInput) => {
    const res = await saveProjectTemplateMutation({
      variables: data
    });
    if (res.data) {
      message.success("Updated template successfully.");
    }
    if (res.errors && res.errors.length > 0) {
      message.error(res.errors[0].message);
    }
  };

  const onChangeDefaultTemplate = useCallback(
    async (cellData: any) => {
      if (!MDBTemplates || !MDBTemplates?.project_workflow_templates) return;
      setSavingDefaultTemplate(true);

      const updatedTemplates = MDBTemplates.project_workflow_templates.map(
        (template: any) => {
          if (cellData.id === template.id) {
            return {
              ...template,
              default: true
            };
          }
          return {
            ...template,
            default: false
          };
        }
      );

      setmaterialTemplates([...updatedTemplates]);

      const payload: any = {
        variables: {
          materialTemplateId: cellData.id
        }
      };

      const setDefaultTemplateResponse = await setDefaultMDBTemplate(payload);

      if (setDefaultTemplateResponse.success) {
        message.success(SuccessMessages.MDBDefaultTemplateSet);
      } else {
        setmaterialTemplates(clonedMDBTemplatesSoruce);
      }
      setSavingDefaultTemplate(false);
    },
    [MDBTemplates, clonedMDBTemplatesSoruce, setDefaultMDBTemplate]
  );

  const onWorkflowCopy = async (payload: any) => {
    const copyTemplateResponse = await copyMDBTemplateMutation(payload);
    if (copyTemplateResponse.success) {
      message.success(SuccessMessages.MDBTemplateCopiedSuccessfully);
    }
  };

  const onToggleTemplateDisabled = async (data: any) => {
    setSavingDefaultTemplate(true);

    const clonedTemplatesToRevert = structuredClone(materialTemplates);
    const updatedToggleMaterialList = materialTemplates?.map(
      (template: any) => {
        if (template.id === data.id) {
          return {
            ...template,
            disabled: !data.disabled
          };
        }
        return template;
      }
    );

    setmaterialTemplates(updatedToggleMaterialList);
    const toggleDisableResp = await disableMDBTemplate({
      variables: {
        pk_columns: { id: data.id },
        _set: { disabled: !data.disabled }
      }
    });

    if (!toggleDisableResp.success) {
      setmaterialTemplates(clonedTemplatesToRevert);
      message.error(ErrorMessages.disableTemplateFailed);
      return;
    }
    setSavingDefaultTemplate(false);
    message.success(
      !data.disabled
        ? SuccessMessages.MDBTemplateDisabledSuccessfully
        : SuccessMessages.MDBTemplateEnabledSuccessfully
    );
  };

  return (
    <div className="py-[2px] px-4">
      {!detailsViewData && (
        <MaterialTemplateList
          setdetailsViewData={setdetailsViewData}
          workflowTemplates={materialTemplates}
          canEditMaterialDBTemplate={canEditMaterialDBTemplate}
          onChangeDefaultTemplate={onChangeDefaultTemplate}
          savingDefaultTemplate={savingDefaultTemplate}
          onWorkflowCopy={onWorkflowCopy}
          onToggleTemplateDisabled={onToggleTemplateDisabled}
        />
      )}
      {templateObjectForDetailsView && (
        <div>
          <div className="flex items-center space-x-3 py-1 mt-0.5">
            <Space
              className="flex items-center cursor-pointer"
              onClick={() => {
                setdetailsViewData(null);
              }}
            >
              <ArrowLeftOutlined />
              <span className="text-base font-medium uppercase">
                {templateObjectForDetailsView.name}
              </span>
            </Space>
            {templateObjectForDetailsView.default && <DefaultTag />}
          </div>
          <div>
            Create the material date block templates you wish to use in the
            project. It&apos;s important to note that modifications made to the
            template here will impact the materials that utilize it.
          </div>
          <div className="py-3">
            <WorkflowTemplateEditor
              milestones={
                templateObjectForDetailsView.project_template_milestones
              }
              workflowTemplate={templateObjectForDetailsView}
              otherTemplateNames={MDBTemplates?.project_workflow_templates
                .filter((x: any) => x.id !== templateObjectForDetailsView.id)
                .map((x: any) => x?.name?.toLowerCase())}
              disable={
                !canEditMaterialDBTemplate ||
                templateObjectForDetailsView.disabled
              }
              openInEditMode={detailsViewData.openInEditMode}
              onSave={onSaveTemplate}
              isTemplateUsed={
                templateObjectForDetailsView.date_blocks_aggregate.aggregate
                  .count > 0
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
