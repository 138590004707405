import { useHistory, useParams } from "react-router";
import { Tabs } from "antd";
import { useState } from "react";

import MaterialTemplateSubscriptionSettings from "./m-template-subscription-settings";
import "../../../project-setting-menu/module-settings/module-settings.scss";

function MaterialSubscriptionSettings() {
  const history = useHistory();
  const { tab3Id } = useParams() as any;
  const [tabIndex, setTabIndex] = useState<string>(tab3Id || "dateblock");
  const tabItems = [
    {
      label: "Material Templates",
      key: "dateblock",
      children: <MaterialTemplateSubscriptionSettings />
    }
  ];
  return (
    <div className="w-full bg-white h-full module-settings--tabs">
      <div className="flex flex-col">
        <Tabs
          tabPosition="left"
          tabBarGutter={2}
          tabBarStyle={{ width: "180px" }}
          activeKey={tabIndex}
          items={tabItems}
          onChange={(activeKey) => {
            history.push(`/settings/modules/materials/${activeKey.toString()}`);
            setTabIndex(activeKey);
          }}
        />
      </div>
    </div>
  );
}
export default MaterialSubscriptionSettings;
