import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Spin } from "antd";
import { ProjectContext, ProjectSettingType } from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DateFilter } from "utils/dateutils";
import { nextDeadlineCellRenderer } from "components/cell-renders";
import { Link } from "react-router-dom";
import { SubmittalStatusToStrMap } from "../../../../constants";
import {
  ActionItemType,
  FilterNamesEnum,
  GcDashBoardType,
  NavigationPageEnum
} from "../models";

enum ActionItemViewEnum {
  Submittals,
  Materials
}

const MAX_ROW_SHOW_NUMBER = 6;

function ActionItems({
  projectId,
  dashboardData,
  projectDetails,
  goToFiltered
}: {
  projectId: string;
  dashboardData: GcDashBoardType | undefined;
  projectDetails: ProjectSettingType | undefined;
  goToFiltered: (page: NavigationPageEnum, filter: FilterNamesEnum) => void;
}) {
  const [isGridReady, setGridReady] = useState(false);
  const [numberRowDisplayed, setNumberRowDisplayed] = useState<number>();
  const {
    columnHeaders: { materialHeaderMap, submittalHeaderMap }
  } = useContext(ProjectContext);

  const gridRef = useRef<AgGridReact>(null);
  const [selectedView, setSelectedView] = useState<ActionItemViewEnum>(
    ActionItemViewEnum.Materials
  );

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: []
    };
  }, []);

  const columnDefsSubmittal: ColDef[] = useMemo(
    () => [
      {
        colId: "submittal_id",
        field: "submittal_id",
        headerName: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        width: 100,
        valueGetter: ({ data }) =>
          projectDetails?.spec_section && data.spec_no
            ? `${data.spec_no} - ${data.submittal_id}`
            : `${data.submittal_id}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <Link
              className="gridIdLink"
              to={{
                pathname: `/project/${projectId}/submittals/${params.data.id}`
              }}
            >
              {params.value}
            </Link>
          );
        },
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "title",
        field: "title",
        tooltipField: "title",
        headerName: submittalHeaderMap?.title?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.title?.toUpperCase() || ""
      },
      {
        colId: "status",
        field: "workflow_status",
        headerName: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        valueGetter: ({ data }) =>
          SubmittalStatusToStrMap[data.workflow_status],
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "next_planned_deadline",
        field: "next_planned_deadline",
        sort: "asc",
        headerName:
          submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        comparator: DateFilter.comparator,
        cellRenderer: nextDeadlineCellRenderer,
        width: 160
      },
      {
        colId: "risk_level",
        field: "risk_level",
        headerName: submittalHeaderMap?.risk_level?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.risk_level?.toUpperCase() || ""
      },
      {
        colId: "responsible_contractor",
        field: "responsible_contractor",
        tooltipField: "responsible_contractor",
        headerName:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || ""
      }
    ],
    [submittalHeaderMap, projectId, projectDetails?.spec_section]
  );

  const columnDefsMaterial: ColDef[] = useMemo(
    () => [
      {
        field: "material_id",
        colId: "material_id",
        tooltipField: "material_id",
        headerName: materialHeaderMap?.material_id?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.material_id?.toUpperCase() || "",
        width: 80,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <Link
              className="gridIdLink"
              to={{
                pathname: `/project/${projectId}/materials/${params.data.id}`
              }}
            >
              {params.value}
            </Link>
          );
        }
      },
      {
        colId: "name",
        field: "name",
        headerName: materialHeaderMap?.name?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.name?.toUpperCase() || "",
        tooltipField: "name"
      },
      {
        field: "status",
        colId: "status",
        tooltipField: "status",
        headerName: materialHeaderMap?.status?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.status?.toUpperCase() || "",
        width: 110
      },
      {
        colId: "next_planned_deadline",
        field: "next_planned_deadline",
        sort: "asc",
        headerName:
          materialHeaderMap?.next_planned_deadline?.toUpperCase() || "",
        comparator: DateFilter.comparator,
        cellRenderer: nextDeadlineCellRenderer,
        width: 160
      },
      {
        colId: "risk_level",
        field: "risk_level",
        headerName: materialHeaderMap?.risk_level?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.risk_level?.toUpperCase() || ""
      },
      {
        colId: "trade_partner",
        field: "trade_partner",
        headerName: materialHeaderMap?.trade_partner?.toUpperCase() || "",
        headerTooltip: materialHeaderMap?.trade_partner?.toUpperCase() || "",
        tooltipField: "trade_partner"
      }
    ],
    [materialHeaderMap, projectId]
  );

  const [gridData, setGridData] = useState<{
    rowData: ActionItemType[] | undefined;
    defaultColDef: ColDef;
    columnDefs: ColDef[];
  }>({
    rowData: undefined,
    defaultColDef,
    columnDefs: columnDefsSubmittal
  });

  useEffect(() => {
    const setData = (
      rowData: ActionItemType[],
      columnDefs: ColDef[],
      totalNumberOfItems: number
    ) => {
      const showRows = [
        ...rowData.sort((a, b) =>
          a.next_planned_deadline < b.next_planned_deadline ? -1 : 1
        )
      ];
      if (totalNumberOfItems > MAX_ROW_SHOW_NUMBER) {
        showRows.length = MAX_ROW_SHOW_NUMBER;
        setNumberRowDisplayed(MAX_ROW_SHOW_NUMBER);
      } else {
        setNumberRowDisplayed(totalNumberOfItems);
      }
      setGridData((pre) => ({
        ...pre,
        rowData: showRows,
        columnDefs
      }));
    };

    if (dashboardData) {
      if (selectedView === ActionItemViewEnum.Submittals) {
        setData(
          dashboardData.submittals.list,
          columnDefsSubmittal,
          dashboardData.submittals.count
        );
      }
      if (selectedView === ActionItemViewEnum.Materials) {
        setData(
          dashboardData.materials.list,
          columnDefsMaterial,
          dashboardData.materials.count
        );
      }
    }
  }, [columnDefsMaterial, columnDefsSubmittal, dashboardData, selectedView]);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);
  return (
    <div className="">
      <div className="w-full flex justify-between items-end h-11 py-2">
        <div className="font-semibold uppercase">Actions due in 2 weeks</div>
        <div className="flex items-end">
          <Button
            className="p-0 w-[152px] h-[35px] rounded-none border-0"
            onClick={() => {
              setSelectedView(ActionItemViewEnum.Submittals);
            }}
          >
            <div
              className={`font-medium text-sm uppercase h-full w-full  flex justify-center items-center ${
                selectedView === ActionItemViewEnum.Submittals
                  ? " text-white  bg-[#3B3B3B]"
                  : " text-[#3B3B3BCC]  bg-[#D1D1CF]"
              }`}
            >
              Submittals{" "}
              {dashboardData ? `(${dashboardData.submittals.count})` : ""}
            </div>
          </Button>
          <Button
            className="p-0 w-[152px] h-[35px] rounded-none border-0"
            onClick={() => {
              setSelectedView(ActionItemViewEnum.Materials);
            }}
          >
            <div
              className={`font-medium text-sm uppercase h-full w-full flex justify-center items-center ${
                selectedView === ActionItemViewEnum.Materials
                  ? " text-white  bg-[#3B3B3B]"
                  : " text-[#3B3B3BCC]  bg-[#D1D1CF]"
              }`}
            >
              Materials{" "}
              {dashboardData ? `(${dashboardData.materials.count})` : ""}
            </div>
          </Button>
        </div>
      </div>
      <div className="h-80 grow flex-col ag-theme-alpine">
        <AgGridReact<ActionItemType>
          ref={gridRef}
          rowData={gridData.rowData}
          defaultColDef={gridData.defaultColDef}
          columnDefs={gridData.columnDefs}
          headerHeight={36}
          onGridReady={(ref) => {
            ref.api.sizeColumnsToFit();
            setGridReady(true);
          }}
          onRowDataUpdated={() => {
            gridRef.current?.api?.sizeColumnsToFit();
          }}
          loadingOverlayComponent={Spin}
          overlayNoRowsTemplate="You have no actions due in 2 weeks."
          context={{
            isCurrentUserGC: true
          }}
        />
      </div>
      <div className="flex w-full justify-end text-xs h-6">
        {gridData.rowData && gridData.rowData?.length > 0 ? (
          <div className="flex space-x-2.5 items-end h-6">
            <div>
              {selectedView === ActionItemViewEnum.Submittals
                ? `Showing ${numberRowDisplayed}/${dashboardData?.submittals.count} Submittals`
                : `Showing ${numberRowDisplayed}/${dashboardData?.materials.count} Materials`}
            </div>
            <Button
              type="link"
              className="p-0 h-fit text-xs"
              onClick={() => {
                if (selectedView === ActionItemViewEnum.Submittals)
                  goToFiltered(
                    NavigationPageEnum.SubmittalLog,
                    FilterNamesEnum.DueIn2Week
                  );
                if (selectedView === ActionItemViewEnum.Materials)
                  goToFiltered(
                    NavigationPageEnum.MaterialLog,
                    FilterNamesEnum.DueIn2Week
                  );
              }}
            >
              <div className="underline underline-offset-2">View in Log</div>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ActionItems;
