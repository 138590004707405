import { EditOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Popover,
  Tooltip,
  Typography,
  message
} from "antd";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { MUTATION_INSERT_MATERIAL } from "services/graphQL/mutations";
import { CopyIcon } from "components/svg-icons";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";

const { Paragraph } = Typography;
export default function MaterialTitlePanel(props: {
  materialDetailsState: any;
  setMaterialDetailsState: any;
  saveFieldChange: any;
  projectId: string;
}) {
  const {
    materialDetailsState,

    setMaterialDetailsState,
    saveFieldChange,
    projectId
  } = props;
  const [open, setOpen] = useState(false);
  const [copiedName, setCopyName] = useState<string>(
    `${materialDetailsState?.name} (copy)`
  );
  const [isIncludeLinks, setInduleLinks] = useState({
    submittals: true,
    activities: false
  });
  const { gqlClientForProject, tokenContents } = useContext(ProjectContext);

  const canInlineEditMaterialPrimaryFields: any = !isPermissionNotGrantted(
    ProjectPermissionEnum.InlineEditMaterialPrimaryFields,
    tokenContents?.role!
  );
  useEffect(() => {
    setCopyName(`${materialDetailsState?.name} (copy)`);
  }, [materialDetailsState?.name]);

  const [addMaterial] = useMutation<any>(MUTATION_INSERT_MATERIAL, {
    client: gqlClientForProject
  });

  const onDuplicateMaterial = async (name: string) => {
    const duplicateMaterial = {
      name: name.trim(),
      spec_section_no: materialDetailsState.spec_section_no,
      spec_section_name: materialDetailsState.spec_section_name,
      trade_partner: materialDetailsState.trade_partner,
      assignee: materialDetailsState.assignee,
      gc_representative: materialDetailsState.gc_representative
    } as any;

    if (isIncludeLinks.submittals) {
      duplicateMaterial.submittal_material_links = {
        data: materialDetailsState.submittal_material_links.map((s: any) => ({
          submittal_id: s.submittal_id
        }))
      };
    }

    if (isIncludeLinks.activities) {
      duplicateMaterial.material_schedule_links = {
        data: materialDetailsState.material_schedule_links.map((s: any) => ({
          linked_to_end_date: s.linked_to_end_date,
          task_id: s.gantt_task.id
        }))
      };
    }

    try {
      const addMaterialResponse = await addMaterial({
        variables: { newMaterial: duplicateMaterial }
      });

      if (addMaterialResponse?.data) {
        const messageText = (
          <div>
            Duplicate Material created. Click
            <span className="mx-1">
              <a
                href={`/project/${projectId}/materials/${addMaterialResponse?.data?.insert_material_one?.id}`}
                className="underline"
              >
                here
              </a>
            </span>
            to view details.
          </div>
        );
        message.success(messageText, 10);
      }

      if (addMaterialResponse?.errors) {
        message.error(addMaterialResponse?.errors[0].message);
      }
    } catch (ex) {
      console.log("ex ", ex);
      message.error("An error occured");
    }
  };

  const materialWFstatus = useMemo(() => {
    if (
      !materialDetailsState ||
      materialDetailsState?.date_block_materials?.length === 0
    )
      return "";
    return materialDetailsState.date_block_materials[0]?.milestone_state || "";
  }, [materialDetailsState]);

  const content = (
    <div className="flex flex-col text-xs w-72 space-y-2">
      <div className="flex text-xs">
        Provide Material Name for the duplicate.
      </div>
      <div className="flex items-center">
        <div className="font-semibold pr-1 w-14">Name : </div>
        <Input
          type="text"
          className="grow"
          value={copiedName}
          onChange={(env) => {
            const name = env.target.value;
            setCopyName(name);
          }}
        />
      </div>
      <div className="flex flex-col text-xs pt-4 pb-1 space-y-2">
        <div>Do you want to copy the links from original ?</div>
        <div className="flex justify-between">
          <Tooltip
            title={
              materialDetailsState.submittal_material_links.length < 1
                ? ErrorMessages.NoLinksPresent
                : null
            }
          >
            <Checkbox
              className="text-xs"
              checked={
                materialDetailsState.submittal_material_links.length > 0 &&
                isIncludeLinks.submittals
              }
              disabled={
                materialDetailsState.submittal_material_links.length < 1
              }
              onChange={(event) => {
                setInduleLinks((pre) => ({
                  ...pre,
                  submittals: event.target.checked
                }));
              }}
            >
              Linked Submittals
            </Checkbox>
          </Tooltip>
          <Tooltip
            title={
              materialDetailsState.material_schedule_links.length < 1
                ? ErrorMessages.NoLinksPresent
                : null
            }
          >
            <Checkbox
              className="text-xs"
              checked={
                materialDetailsState.material_schedule_links.length > 0 &&
                isIncludeLinks.activities
              }
              disabled={materialDetailsState.material_schedule_links.length < 1}
              onChange={(event) => {
                setInduleLinks((pre) => ({
                  ...pre,
                  activities: event.target.checked
                }));
              }}
            >
              Linked Activities
            </Checkbox>
          </Tooltip>
        </div>
      </div>
      <Divider className="py-1" />
      <div className="flex justify-end space-x-2 text-xs">
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={copiedName === ""}
          onClick={() => {
            setOpen(!open);
            onDuplicateMaterial(copiedName);
          }}
        >
          Create
        </Button>
      </div>
    </div>
  );

  return (
    <div className="w-full flex bg-white px-5 py-3 items-center">
      <div>{materialDetailsState?.material_id} - </div>
      <div className="grow px-1">
        {materialDetailsState?.name && (
          <Paragraph
            className="!m-0 text-two !font-normal w-full"
            editable={
              canInlineEditMaterialPrimaryFields
                ? {
                    icon: <EditOutlined className="ml-2 text-color-two" />,
                    tooltip: "",
                    maxLength: 1000,
                    onChange: (name: string) => {
                      if (name.trim() === "") return;
                      setMaterialDetailsState((prev: any) => {
                        return { ...prev, name };
                      });
                      saveFieldChange({ name });
                    }
                  }
                : false
            }
          >
            {materialDetailsState?.name}
          </Paragraph>
        )}
      </div>
      <div className="flex space-x-2">
        <div className="flex items-center uppercase">{materialWFstatus}</div>
        <Popover
          open={canInlineEditMaterialPrimaryFields && open}
          placement="bottomRight"
          content={content}
          trigger="click"
          onOpenChange={(newOpen: boolean) => {
            setOpen(newOpen);
          }}
          destroyTooltipOnHide
        >
          <Button disabled={!canInlineEditMaterialPrimaryFields}>
            <div className="flex items-center space-x-2">
              <div>Duplicate</div>
              <CopyIcon />
            </div>
          </Button>
        </Popover>
      </div>
    </div>
  );
}
