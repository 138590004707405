/* eslint-disable react/destructuring-assignment */
import "./schedule-cell-renderer.scss";
import {
  cellWithActualDateDifference,
  cellWithActualPlannedDates,
  cellWithDataDifference,
  cellWithPlannedDate
} from "components/schedule-impact-cells/data-difference-cell";
import { DateUtils } from "utils/dateutils";

const fieldAndName = [
  { field: "assigned_date", name: "Assigned to Subcontractor" },
  { field: "trade_partner_submitted_date", name: "Submitted by Subcontractor" },
  { field: "ext_review_submitted_date", name: "Submitted for Design Review" },
  { field: "ext_review_completed_date", name: "Design Review Complete" },
  { field: "submittal_distributed_date", name: "Submittal Distributed" }
];

function ScheduleCellRenderer(params: any) {
  const { isDeleted } = params;
  const renderSubmittalUI = () => {
    if (params.colDef.field === "assigned") {
      const assignedNewValue = params.data?.SDB?.new?.SI_calculations?.SI_PFMD
        ? DateUtils.format(params.data?.SDB?.new?.SI_calculations?.SI_PFMD)
        : "";
      const assignedOldValue = params.data?.SDB?.old?.SI_calculations?.SI_PFMD
        ? DateUtils.format(params.data?.SDB?.old?.SI_calculations?.SI_PFMD)
        : "";

      if (isDeleted && !params.data?.SDB?.old?.SI_calculations?.SI_AFMD) {
        return cellWithPlannedDate(assignedOldValue);
      }

      if (params.data?.SDB?.old?.SI_calculations?.SI_AFMD) {
        const actualValue = DateUtils.format(
          params.data?.SDB?.old?.SI_calculations?.SI_AFMD
        );

        if (assignedNewValue === assignedOldValue) {
          return cellWithActualPlannedDates(assignedOldValue, actualValue);
        }

        return cellWithActualDateDifference("", assignedOldValue, actualValue);
      }

      if (assignedNewValue === assignedOldValue) {
        return cellWithPlannedDate(assignedNewValue);
      }
      return cellWithDataDifference(assignedNewValue, assignedOldValue);
    }

    if (params.colDef.field === "SI_FDD") {
      const sbNewValue = params.data?.SDB?.new?.SI_calculations?.SI_FDD
        ? DateUtils.format(params.data?.SDB?.new?.SI_calculations?.SI_FDD)
        : "";

      const sbOldValue = params.data?.SDB?.old?.SI_calculations?.SI_FDD
        ? DateUtils.format(params.data?.SDB?.old?.SI_calculations?.SI_FDD)
        : "";

      if (sbNewValue === sbOldValue) {
        return cellWithPlannedDate(sbNewValue);
      }
      return cellWithDataDifference(sbNewValue, sbOldValue);
    }

    if (params.colDef.field === "next_milestone") {
      let nextMilestone = "";
      const nextMilestoneId = params.data?.SDB?.new?.next_milestone;
      if (nextMilestoneId)
        nextMilestone =
          fieldAndName.find((f) => f.field === nextMilestoneId)?.name || "";
      const nextMilestoneDiv = (
        <div className="flex w-48 overflow-hidden leading-normal">
          {nextMilestone}
        </div>
      );

      const sbNewValue = params.data?.SDB?.new?.SI_calculations?.SI_PNMD
        ? DateUtils.format(params.data?.SDB?.new?.SI_calculations?.SI_PNMD)
        : "";

      const sbOldValue = params.data?.SDB?.old?.SI_calculations?.SI_PNMD
        ? DateUtils.format(params.data?.SDB?.old?.SI_calculations?.SI_PNMD)
        : "";

      if (sbNewValue === sbOldValue) {
        return (
          <div className="flex space-x-1">
            {nextMilestoneDiv} {cellWithPlannedDate(sbNewValue)}
          </div>
        );
      }
      return (
        <div className="flex space-x-1">
          {nextMilestoneDiv}
          {cellWithDataDifference(sbNewValue, sbOldValue)}
        </div>
      );
    }

    if (params.colDef.field === "submitted_for_external_review") {
      const sbNewValue = params.data?.SDB?.new?.ext_review_submitted_date
        ? DateUtils.format(params.data?.SDB?.new?.ext_review_submitted_date)
        : "";

      const sbOldValue = params.data?.SDB?.old?.ext_review_submitted_date
        ? DateUtils.format(params.data?.SDB?.old?.ext_review_submitted_date)
        : "";

      if (isDeleted && !params.data.SDB.old?.actual_ext_review_submitted_date) {
        return cellWithPlannedDate(sbOldValue);
      }

      if (params.data.SDB.old?.actual_ext_review_submitted_date) {
        const actualValue = DateUtils.format(
          params.data.SDB.old?.actual_ext_review_submitted_date
        );

        if (sbNewValue === sbOldValue) {
          return cellWithActualPlannedDates(sbOldValue, actualValue);
        }

        return cellWithActualDateDifference("", sbOldValue, actualValue);
      }

      if (sbNewValue === sbOldValue) {
        return cellWithPlannedDate(sbNewValue);
      }
      return cellWithDataDifference(sbNewValue, sbOldValue);
    }

    if (params.colDef.field === "external_review_complete") {
      const extReviewNewValue = params.data?.SDB?.new?.ext_review_completed_date
        ? DateUtils.format(params.data?.SDB?.new?.ext_review_completed_date)
        : "";

      const extReviewOldValue = params.data?.SDB?.old?.ext_review_completed_date
        ? DateUtils.format(params.data?.SDB?.old?.ext_review_completed_date)
        : "";

      if (
        isDeleted &&
        !params.data?.SDB?.old?.actual_ext_review_completed_date
      ) {
        return cellWithPlannedDate(extReviewOldValue);
      }

      if (params.data?.SDB?.old?.actual_ext_review_completed_date) {
        const actualValue = DateUtils.format(
          params.data.SDB.old?.actual_ext_review_completed_date
        );

        if (extReviewNewValue === extReviewOldValue) {
          return cellWithActualPlannedDates(extReviewOldValue, actualValue);
        }

        return cellWithActualDateDifference("", extReviewOldValue, actualValue);
      }

      if (extReviewNewValue === extReviewOldValue) {
        return cellWithPlannedDate(extReviewNewValue);
      }
      return cellWithDataDifference(extReviewNewValue, extReviewOldValue);
    }

    if (params.colDef.field === "submittal_distributed") {
      const sdNewValue = params.data?.SDB?.new?.SI_calculations?.SI_PLMD
        ? DateUtils.format(params.data?.SDB?.new?.SI_calculations?.SI_PLMD)
        : "";

      const sdOldValue = params.data?.SDB?.old?.SI_calculations?.SI_PLMD
        ? DateUtils.format(params.data?.SDB?.old?.SI_calculations?.SI_PLMD)
        : "";

      if (
        isDeleted &&
        !params.data?.SDB?.old?.actual_submittal_distributed_date
      ) {
        return cellWithPlannedDate(sdOldValue);
      }

      if (params.data?.SDB?.old?.actual_submittal_distributed_date) {
        const actualValue = DateUtils.format(
          params.data.SDB.old?.actual_submittal_distributed_date
        );

        if (sdNewValue === sdOldValue) {
          return cellWithActualPlannedDates(sdOldValue, actualValue);
        }

        return cellWithActualDateDifference("", sdOldValue, actualValue);
      }

      if (sdNewValue === sdOldValue) {
        return cellWithPlannedDate(sdNewValue);
      }
      return cellWithDataDifference(sdNewValue, sdOldValue);
    }

    if (params.colDef.field === "float") {
      const newSI = params.data?.SDB?.new?.SI_calculations;
      const oldSI = params.data?.SDB?.old?.SI_calculations;
      const finalNewFloatValue =
        newSI?.SI_EF === null ? newSI?.SI_F : newSI?.SI_EF;
      const finalOldFloatValue =
        oldSI?.SI_EF === null ? oldSI?.SI_F : oldSI?.SI_EF;

      if (isDeleted) {
        return cellWithPlannedDate(finalOldFloatValue);
      }

      if (finalNewFloatValue === finalOldFloatValue) {
        return `${finalOldFloatValue}  Day(s)`;
      }

      return cellWithDataDifference(
        `${finalNewFloatValue}  Day(s)`,
        `${finalOldFloatValue}  Day(s)`
      );
    }

    if (params.colDef.field === "trade_partner_submitted_date") {
      const tpNewValue = params.data?.SDB?.new?.trade_partner_submitted_date
        ? DateUtils.format(params.data?.SDB?.new?.trade_partner_submitted_date)
        : "";
      const tpOldValue = params.data?.SDB?.old?.trade_partner_submitted_date
        ? DateUtils.format(params.data?.SDB?.old?.trade_partner_submitted_date)
        : "";

      if (
        isDeleted &&
        !params.data?.SDB?.old?.actual_trade_partner_submitted_date
      ) {
        return cellWithPlannedDate(tpOldValue);
      }

      if (params.data?.SDB?.old?.actual_trade_partner_submitted_date) {
        const actualValue = DateUtils.format(
          params.data.SDB.old?.actual_trade_partner_submitted_date
        );

        if (tpNewValue === tpOldValue) {
          return cellWithActualPlannedDates(tpOldValue, actualValue);
        }

        return cellWithActualDateDifference("", tpOldValue, actualValue);
      }

      if (tpNewValue === tpOldValue) {
        return cellWithPlannedDate(tpNewValue);
      }
      return cellWithDataDifference(tpNewValue, tpOldValue);
    }

    return "";
  };

  return <div className="flex w-full py-1">{renderSubmittalUI()}</div>;
}

export default ScheduleCellRenderer;
