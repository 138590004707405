import { ErrorBoundary } from "@sentry/react";
import { AgGridReact } from "ag-grid-react";

import { GridApi } from "ag-grid-community";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { Spin, message } from "antd";
import { priorities } from "components/submittal-details/submittal-details";
import { GridStatusMessage } from "components/widgets/grid-status-message";
import SubmittalListFilterComponent from "pages/submittals-list/filter-bar";
import { FilterItem } from "models/types";
import { AppContext } from "context/AppProvider";

import {
  getSubmittalCompanyById,
  getSubmittalUserById
} from "hooks/project-participants";

import FileViewerModal from "components/file-viewer-modal/file-viewer-modal";
import ImportSubmittalLogs from "components/import-submittal-logs";
import SubmittalLogCreateComponent from "pages/submittal-log-create";
import { getUser } from "services/auth";
import { applySubmittalLogFilters } from "utils/ag-grid-programmatic-filtering";

import { useShowDesignAndSpec } from "hooks/common";
import BulkEditSubmittalDetails from "components/bulk-edit-submittal/bulk-edit-submittal-details";
import BulkEditSubmittalDurations from "components/bulk-edit-submittal/bulk-edit-submittal-durations";

import { withRouter } from "react-router";
import { useSubmittalLogsData } from "./hooks/ciq-submittals-log-data";
import { useSubmittalLogAgGrid } from "./hooks/ciq-submittals-log-ag-grid";

import { FilterProps } from "../submittals-list/filter-bar/submittal-list-filter-bar";
import { OpenNotification } from "./components/open-notification";
import { SpecFileModalHeader } from "./components/spec-file-modal-header";
import { exportSubmittalLogAsCSV, getRowId } from "./utils";
import { useSubmittalLogMutations } from "./hooks/ciq-submittals-log-mutations";

import {
  submittalLogFilterTitles,
  SubmittalStatusToStrMap
} from "../../constants";
import "../../index.css";
import "../submittals-list/submittal-list.css";

function SubmittalLogPage() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [isGridReady, setGridReady] = useState(false);
  const pageData = useSubmittalLogsData();
  const gridData = useSubmittalLogAgGrid(gridRef, pageData);

  const {
    gqlClientForProject,
    projectParticipants,
    submittals,
    typesMap,
    statusesMap,
    maxNumberOfMilestone,
    showSubmittalList,
    submittalListLoading,
    submittalListData,
    submittalListLoadedOnce,
    projectDetails,
    isIntegrationMode,
    isIntegrationConfigured,
    isCurrentUserGC,
    projectId,
    tokenRetrievalState,
    tokenContents,
    submittalHeaderMap
  } = pageData;

  const {
    hiddenColumns,
    milestonesColumns,
    savedColumns,
    columnDefs,
    defaultColDef,
    autoGroupColumnDef,
    sideBar,
    getColumnStateFromGrid,
    setFilterStateFromData,
    onSaveColumnState,
    onColumnVisible,
    onDragStopped,
    onEditCell
  } = gridData;

  const [showSubmittalBulkEditDetails, setShowSubmittalBulkEditDetails] =
    useState(false);

  const [showSubmittalBulkEditDurations, setShowSubmittalBulkEditDurations] =
    useState(false);

  const { updateSubmittalById, updateSDB } = useSubmittalLogMutations({
    gqlClientForProject
  });

  const { customDateFilter, setCustomDateFilter } = gridData;

  const { isDesignTabEnabled } = useShowDesignAndSpec();
  const [isFirstDataRendered, setIsFirstDataRendered] = useState(false);

  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const [specFileModalData, setSpecFileModalData] = useState<null | {
    file_key: string;
    pageid: number;
    spec_no: string;
    spec_name: string;
  }>(null);

  const onSelectionChanged = ({ api }: { api: GridApi }) => {
    setSelectedRows(api.getSelectedRows());
  };

  const onSDBFloatChange = useCallback(
    async (event: any) => {
      if (!event.newValue) return;

      const oldData = { ...event.data };

      event.node.setData({
        ...oldData,
        float: event.newValue
      });

      const updateVars = {
        variables: {
          where: { submittal_id: { _in: [event.data.id] } },
          set: { float: event.newValue }
        }
      };
      const res = await updateSDB(updateVars);
      if (res.data) {
        message.success("Updated successfully");
      }
      if (res.errors) {
        event.node.setData({ ...oldData });
        message.error(res.errors[0].message);
      }
    },
    [updateSDB]
  );

  const onCustomSpecSectionCellEdit = useCallback(
    async (event: any) => {
      if (!event.newValue.specIdValue) return;

      const specNo = event?.newValue?.specNoValue?.trim() || null;
      const specName = event?.newValue?.specNameValue?.trim() || null;

      const oldData = { ...event.data };

      event.node.setData({
        ...oldData,
        spec_no: specNo,
        spec_name: specName
      });

      const variables: any = {
        id: event.data.id,
        submittalValues: {
          spec_no: specNo,
          spec_name: specName,
          spec_section_id: event.newValue.specIdValue
        }
      };

      try {
        const updateResponse: any = await updateSubmittalById({ variables });
        if (updateResponse.errors) {
          message.error({ content: updateResponse.errors[0].message });
          event.node.setData(oldData);
        } else {
          message.success("Updated successfully");
        }
      } catch (ex) {
        message.error({ content: "An error occured" });
      }
    },
    [updateSubmittalById]
  );

  const cellEditRequest = useCallback(
    async (event: any) => {
      if (event.column.colId === "custom_spec_section") {
        onCustomSpecSectionCellEdit(event);
        return;
      }
      if (event.column.colId === "float") {
        onSDBFloatChange(event);
        return;
      }
      const skipNullFields = ["watchers"];

      if (
        !skipNullFields.some((x) => x === event.column.colId) &&
        !event.newValue
      )
        return;

      const existingRowData = { ...event.data };
      const changedData: any = {};

      const variables: any = {
        id: event.data.id,
        submittalValues: {}
      };

      switch (event.colDef.colId) {
        case "design_reviewer": {
          const designReviewer = getSubmittalUserById(
            event.newValue,
            projectParticipants.designReviewers
          );

          if (designReviewer.type === "actual") {
            variables.submittalValues.design_reviewer = event.newValue;
            variables.submittalValues.design_reviewer_unregistered = "";

            changedData.design_reviewer_unregistered = "";
            changedData.design_reviewer_unregistered_org = "";

            changedData.design_reviewer_user_id = event.newValue;
            changedData.design_reviewer_first_name = designReviewer.first_name;
            changedData.design_reviewer_last_name = designReviewer.last_name;
            changedData.design_reviewer_org = designReviewer.company.name;
          } else if (designReviewer.type === "poc") {
            variables.submittalValues.design_reviewer_unregistered =
              event.newValue;
            variables.submittalValues.design_reviewer_vendor_unregistered =
              designReviewer.company.name;
            variables.submittalValues.design_reviewer = null;

            changedData.design_reviewer_user_id = null;
            changedData.design_reviewer_unregistered = event.newValue;
            changedData.design_reviewer_unregistered_org =
              designReviewer.company.name;
          }
          break;
        }

        case "submitter": {
          const submitter = getSubmittalUserById(
            event.newValue,
            projectParticipants.submitterUsers
          );

          if (submitter.type === "actual") {
            variables.submittalValues.submitter = event.newValue;
            variables.submittalValues.submitter_unregistered = "";

            changedData.submitter_user_id = event.newValue;
            changedData.submitter_first_name = submitter.first_name;
            changedData.submitter_last_name = submitter.last_name;
            changedData.submitter_org = submitter.company?.name;
          } else if (submitter.type === "poc") {
            variables.submittalValues.submitter_unregistered = event.newValue;
            variables.submittalValues.submitter = null;

            changedData.submitter_user_id = null;
            changedData.submitter_unregistered = event.newValue;
          }
          break;
        }

        case "responsible_contractor": {
          variables.submittalValues.responsible_contractor = event.newValue;
          variables.submittalValues.submitter = null;
          variables.submittalValues.submitter_unregistered = "";

          const responsibleContractor = getSubmittalCompanyById(
            event.newValue,
            projectParticipants.responsibleContractors
          );
          changedData.responsible_contractor_id = event.newValue;
          changedData.responsible_contractor_name =
            responsibleContractor.subscription_vendor?.name;
          changedData.submitter_user_id = null;
          changedData.submitter_unregistered = "";
          break;
        }

        case "gc_reviewer": {
          variables.submittalValues.gc_reviewer = event.newValue;
          const gcReviewer = getSubmittalUserById(
            event.newValue,
            projectParticipants.gcReviewers
          );
          changedData.gc_reviewer_user_id = event.newValue;
          changedData.gc_reviewer_first_name = gcReviewer.first_name;
          changedData.gc_reviewer_last_name = gcReviewer.last_name;
          changedData.gc_reviewer_org = gcReviewer.company.name;
          break;
        }
        case "type": {
          const typeObj: any = Object.values(event.context.typesMap).find(
            (type: any) => type.id === event.newValue
          );

          if (typeObj) {
            changedData.type = typeObj?.value || "";
            variables.submittalValues.type = event.newValue;
            variables.submittalValues.raw_type = typeObj?.value || "";
          }
          break;
        }

        case "submittal_number":
          variables.submittalValues.submittal_number = event.newValue;
          changedData.submittal_number = event.newValue;
          break;

        default:
          variables.submittalValues[event.column.colId as any] = event.newValue;
          changedData[event.column.colId as any] = event.newValue;
          break;
      }

      event.node.setData({ ...existingRowData, ...changedData });

      try {
        const updateResponse: any = await updateSubmittalById({ variables });
        if (updateResponse.errors) {
          message.error({ content: updateResponse.errors[0].message });
          event.node.setData(existingRowData);
        } else {
          message.success("Updated successfully");
        }
      } catch (ex) {
        message.error({ content: "An error occured" });
        event.node.setData(existingRowData);
      }
    },
    [
      onCustomSpecSectionCellEdit,
      onSDBFloatChange,
      projectParticipants.designReviewers,
      projectParticipants.submitterUsers,
      projectParticipants.responsibleContractors,
      projectParticipants.gcReviewers,
      updateSubmittalById
    ]
  );

  const onViewSpecSection = (data: any) => {
    setSpecFileModalData({
      file_key: data.spec_section_file_key,
      pageid: data.snippet_coordinates?.pageid,
      spec_no: data.spec_no,
      spec_name: data.spec_name
    });
  };

  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [showNewLogDrawer, setshowNewLogDrawer] = useState<boolean>(false);
  const [showImportLogDrawer, setshowImportLogDrawer] =
    useState<boolean>(false);

  const existingSubmittalsCountRef = useRef<any>();
  const [displayRowCount, setDisplayRowCount] = useState(10);
  const [stats, setStats] = useState<any>("");
  const { submittalListFilter, setSubmittalListFilter }: any =
    useContext(AppContext);

  const updateStats = useCallback(() => {
    if (!gridRef.current?.api) return;
    const displayedRowCount = gridRef.current?.api?.getDisplayedRowCount();
    if (displayedRowCount === undefined) return;

    if (submittalListFilter && submittalListFilter.key) {
      const titleText = submittalLogFilterTitles[submittalListFilter.key];
      const statsContents = (
        <>
          <span>{`${displayedRowCount} Submittals`} </span>
          <span className="text-xs font-thin">({titleText})</span>
        </>
      );
      setStats(statsContents);
      setDisplayRowCount(displayedRowCount || 0);
      return;
    }
    const length = submittals?.length || 0;
    if (gridRef.current?.api?.isAnyFilterPresent()) {
      setTimeout(() => {
        setStats(`${displayedRowCount} of ${length} Submittals`);
        setDisplayRowCount(displayedRowCount || 0);
      }, 500);

      return;
    }

    setStats(`${length} Submittals`);
    setDisplayRowCount(length);
  }, [submittals?.length, submittalListFilter]);

  const resetGridFilters = useCallback(() => {
    try {
      const currentFilters: any = gridRef.current?.api?.getFilterModel();
      const currentFilterCols = Object.keys(currentFilters);
      currentFilterCols.forEach((col: string) => {
        gridRef.current!.api.destroyFilter(col);
      });

      setTimeout(() => {
        setSubmittalListFilter(null);
      });
    } catch (ex) {
      //
    }
  }, [setSubmittalListFilter]);

  const exportLogAsCSV = () => {
    if (gridRef.current && projectDetails)
      exportSubmittalLogAsCSV({
        ref: gridRef.current,
        hiddenColumns,
        isIntegrationMode,
        milestonesColumns,
        projectDetails
      });
  };

  const onSubmittalsEdit = (panel: "details" | "duration") => {
    if (panel === "details") {
      setShowSubmittalBulkEditDetails(true);
    }
    if (panel === "duration") {
      setShowSubmittalBulkEditDurations(true);
    }
  };

  const resetFilters = () => {
    gridRef.current!.api.setFilterModel(null);
    // clearFilterRef.current = true;
    setTimeout(() => {
      // To destroy all filters applied on chip
      // clearFilterRef.current = false;
      onSaveColumnState();
    }, 2000);
  };

  const onFiltersApplied = (event: any) => {
    if (event.columns?.length === 0) {
      setTimeout(() => {
        updateStats();
      }, 500);
      return;
    }
    const filtersApplied = event.api.getFilterModel();
    if (filtersApplied) {
      const items: FilterItem[] = new Array<FilterItem>();
      Object.keys(filtersApplied).forEach((key: any) => {
        if (
          filtersApplied[key]?.values &&
          filtersApplied[key].values.length > 0
        ) {
          const field = columnDefs!.filter((x: any) => x.colId === key)[0];
          const { values, range } = filtersApplied[key];

          items.push({
            field: key,
            header: field ? field.headerName : key,
            value: values,
            range
          });
        }
      });

      setFilters(items);

      if (isFirstDataRendered === true) {
        if (!submittalListFilter) {
          onSaveColumnState();
        }
      }
    }

    setTimeout(() => {
      updateStats();
    }, 500);
  };

  const initiatedFilter = useCallback(() => {
    const loggedInUserInfo = getUser();
    if (submittalListFilter && submittalListFilter.key) {
      applySubmittalLogFilters({
        gridRef,
        loggedInUserInfo,
        submittalListFilter,
        SubmittalStatusToStrMap
      });
    }

    if (savedColumns && submittalListFilter === null) {
      setFilterStateFromData(savedColumns.submittals);
    }
    gridRef.current?.api.onFilterChanged();

    if (gridRef.current && savedColumns) {
      milestonesColumns.updateAllMileStoneInitialVisibility(
        gridRef.current?.columnApi,
        maxNumberOfMilestone
      );
    }
  }, [
    maxNumberOfMilestone,
    milestonesColumns,
    savedColumns,
    setFilterStateFromData,
    submittalListFilter
  ]);

  const [isFilterInitiated, setIsFilterInitiated] = useState(false);
  useEffect(() => {
    if (isGridReady && submittals?.length)
      setTimeout(() => {
        updateStats();
      }, 500);
    if (!isFilterInitiated && isGridReady && columnDefs && submittals) {
      setIsFilterInitiated(true);
      initiatedFilter();
      setTimeout(() => {
        // NOTE: To load initial data once in onFilterApplied method
        setIsFirstDataRendered(true);
      }, 2000);
    }
  }, [
    isGridReady,
    columnDefs,
    initiatedFilter,
    isFilterInitiated,
    submittals,
    updateStats
  ]);

  const onSearch = (text: string) => {
    gridRef.current?.api?.setQuickFilter(text);
  };
  const headerComponentViewModel = {
    gridRef,
    items: filters,
    customDateFilter,
    setCustomDateFilter,
    setItems: setFilters,
    onCreateLogClick: () => {
      setshowNewLogDrawer(true);
    },
    onImportLogClick: () => {
      if (!submittals) return;
      existingSubmittalsCountRef.current = submittals;
      setshowImportLogDrawer(true);
    },
    onSearch,
    stats,
    showFiterChips: !submittalListFilter,
    onAllSubmittalsClick: resetGridFilters,
    selectedRow: selectedRows,
    onExportCSV: exportLogAsCSV,
    onSubmittalEditClick: onSubmittalsEdit,
    isIntegrationMode,
    displayRowCount,
    isIntegrationConfigured,
    resetFilters,
    getColumnStateFromGrid,
    setFilterStateFromData,
    afterApplyFilterCallBack: () => {
      if (milestonesColumns && gridRef.current)
        milestonesColumns.updateAllMileStoneInitialVisibility(
          gridRef.current?.columnApi,
          maxNumberOfMilestone
        );
    },
    disabled: !isFilterInitiated,
    isGridDataRendered: !isFilterInitiated,
    onSubmittalDeleted: () => {}
  } as FilterProps;

  if (!showSubmittalList)
    return (
      <div className="h-[80vh] flex items-center justify-center">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <div className="submittal-list-page">
        <SubmittalListFilterComponent {...headerComponentViewModel} />
      </div>
      <div className="submittal-listing-grid grow ag-theme-alpine px-2 relative">
        <GridStatusMessage
          {...{
            isIntegrationMode,
            isIntegrationConfigured,
            submittalListLoading,
            submittalListData,
            submittalListLoadedOnce,
            projectId
          }}
        />
        <ErrorBoundary>
          <AgGridReact<any>
            tooltipMouseTrack
            ref={gridRef}
            rowData={submittals}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            sideBar={sideBar}
            headerHeight={36}
            rowSelection="multiple"
            groupSelectsChildren
            suppressRowClickSelection
            suppressAggFuncInHeader
            suppressDragLeaveHidesColumns
            maintainColumnOrder
            readOnlyEdit
            animateRows={false}
            debounceVerticalScrollbar
            onSelectionChanged={onSelectionChanged}
            getRowId={getRowId}
            onCellEditRequest={cellEditRequest}
            tooltipShowDelay={700}
            tooltipHideDelay={2000}
            onFilterChanged={onFiltersApplied}
            onSortChanged={onFiltersApplied}
            onGridReady={() => {
              setGridReady(true);
              console.log("onGridReady ");
            }}
            rowHeight={62}
            suppressLoadingOverlay
            context={{
              typesMap,
              statusesMap,
              tokenContents,
              projectDetails,
              projectParticipants,
              isIntegrationMode,
              onEditCell,
              onViewSpecSection,
              isCurrentUserGC,
              isDesignTabEnabled,
              submittalHeaderMap,
              priorities
            }}
            suppressContextMenu
            enableCellTextSelection
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            suppressNoRowsOverlay
            onColumnVisible={onColumnVisible}
            onDragStopped={onDragStopped}
            suppressClickEdit
            cacheQuickFilter
          />
        </ErrorBoundary>
      </div>

      {showNewLogDrawer && (
        <SubmittalLogCreateComponent
          onCancelClick={() => {
            setshowNewLogDrawer(false);
          }}
          onSubmittalCreated={(id: string, title: string) => {
            setshowNewLogDrawer(false);
            OpenNotification({
              projectId,
              id,
              title,
              placement: "top"
            });
          }}
          gqlClient={gqlClientForProject}
          showCreateSubModel={showNewLogDrawer}
          setDrawerOpen={setshowNewLogDrawer}
        />
      )}
      {showImportLogDrawer && (
        <ImportSubmittalLogs
          submittalHeaderMap={submittalHeaderMap}
          tokenRetrievalState={tokenRetrievalState}
          gqlClientForProject={gqlClientForProject}
          existingSubmittalsCount={existingSubmittalsCountRef.current}
          showImportLogDrawer={showImportLogDrawer}
          setDrawerOpen={setshowImportLogDrawer}
          onCancel={() => {
            setshowImportLogDrawer(false);
          }}
        />
      )}

      {showSubmittalBulkEditDetails && (
        <BulkEditSubmittalDetails
          onCancelClick={() => {
            setShowSubmittalBulkEditDetails(false);
          }}
          onUpdateDone={() => {
            setShowSubmittalBulkEditDetails(false);
            gridRef.current!.api.deselectAll();
          }}
          selectedRows={selectedRows}
          gqlClient={gqlClientForProject}
          setShowSubmittalEdit={setShowSubmittalBulkEditDetails}
          showSubmittalEdit={showSubmittalBulkEditDetails}
          projectId={projectId}
        />
      )}

      {showSubmittalBulkEditDurations && (
        <ErrorBoundary
          onError={() => {
            setShowSubmittalBulkEditDurations(false);
          }}
        >
          <BulkEditSubmittalDurations
            setShowEdit={setShowSubmittalBulkEditDurations}
            showEdit={showSubmittalBulkEditDurations}
            selectedRows={selectedRows}
            submittals={submittals}
          />
        </ErrorBoundary>
      )}

      {specFileModalData?.file_key && (
        <FileViewerModal
          fileKey={specFileModalData?.file_key}
          pageNumber={specFileModalData?.pageid || 0}
          showModal={!!specFileModalData?.file_key}
          hideModal={() => {
            setSpecFileModalData(null);
          }}
          headerComponent={SpecFileModalHeader(specFileModalData)}
        />
      )}
    </>
  );
}

export default withRouter(SubmittalLogPage);
