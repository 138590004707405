import { useHistory, useParams } from "react-router";
import { Tabs } from "antd";
import { useState } from "react";
import MaterialSubscriptionSettings from "./material-subscription-settings";
import "../../project-setting-menu/module-settings/module-settings.scss";

function ModuleSubscriptionSettings() {
  const history = useHistory();
  const { tab2Id } = useParams() as any;
  const [tabIndex, setTabIndex] = useState<string>(tab2Id || "materials");
  const tabItems = [
    {
      label: "Materials",
      key: "materials",
      children: <MaterialSubscriptionSettings />
    }
  ];
  return (
    <div className="w-full py-1 h-full">
      <Tabs
        onChange={(activeKey) => {
          history.push(`/settings/modules/${activeKey.toString()}`);
          setTabIndex(activeKey);
        }}
        activeKey={tabIndex}
        items={tabItems}
        destroyInactiveTabPane
      />
    </div>
  );
}
export default ModuleSubscriptionSettings;
