/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Checkbox, Tooltip, message } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import { useState, useRef, useContext, useEffect } from "react";
import Search from "antd/lib/input/Search";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LinkOutlined
} from "@ant-design/icons";
import modal from "antd/lib/modal";
import { useMutation } from "@apollo/client";
import {
  MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL,
  MUTATION_INSERT_LINKING_SUBMITTAL_MATERIAL
} from "services/graphQL/mutations";
import { Link, useParams } from "react-router-dom";
import ErrorBoundary from "components/error-boundary";
import SubmittalIcon from "components/svg-icons/submittal-icon-2";
import { DateFilter } from "utils/dateutils";
import { ErrorMessages, InfoMessages } from "../../constants";
import ListingWindow from "./listingWindow";
import { MaterialType, SubmittalLinkedType, SubmittalType } from "./models";

interface IMaterialSubmittalLinkingProps {
  submittalList: Array<SubmittalType>;
  material: MaterialType;
  selectedId?: string;
  onSelect: (submittal: SubmittalType) => void;
  canEdit: boolean;
}
function MaterialSubmittalLinking({
  submittalList,
  material,
  selectedId,
  onSelect,
  canEdit
}: IMaterialSubmittalLinkingProps) {
  const submittalLinking = useRef<HTMLDivElement>(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const buttonRef = useRef<HTMLElement>(null);
  const [openSubmittalList, setOpenSubmittalList] = useState(false);
  const { gqlClientForProject } = useContext(ProjectContext);
  const { projectId } = useParams<{ projectId: string }>();
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [filteredSubList, setFilteredSubList] = useState<Array<SubmittalType>>(
    []
  );
  const [isSuggested, setSuggested] = useState(true);

  useEffect(() => {
    if (material?.spec_section_no) {
      if (isSuggested) {
        const specNumber = material.spec_section_no?.replace(/ /g, "");
        const suggestedList = submittalList.filter(
          (submittal: any) =>
            submittal.spec_no?.replace(/ /g, "") === specNumber
        );
        setFilteredSubList(suggestedList);
      } else {
        setFilteredSubList(submittalList);
      }
    } else if (isSuggested) {
      setFilteredSubList([]);
    } else {
      setFilteredSubList(submittalList);
    }
  }, [material.spec_section_no, submittalList, isSuggested, material]);

  useEffect(() => {
    setSelectedIds(
      material.submittal_material_links.map(
        (submittal: SubmittalLinkedType) => {
          return submittal.submittal.id;
        }
      )
    );
    const element = submittalLinking.current;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    element && element?.scrollTo(0, element.scrollHeight);
  }, [material.submittal_material_links]);

  const [deleteLinkingSubmittalMaterialMutaion] = useMutation(
    MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL,
    {
      client: gqlClientForProject
    }
  );
  const [linkingSubmittalMaterialMutation] = useMutation(
    MUTATION_INSERT_LINKING_SUBMITTAL_MATERIAL,
    {
      client: gqlClientForProject
    }
  );

  const onLinkingSubmittalMaterialMutation = (selectedSubmittalId: string) => {
    linkingSubmittalMaterialMutation({
      variables: {
        objects: [
          {
            submittal_id: selectedSubmittalId,
            material_id: material.id
          }
        ]
      }
    }).then((res) => {
      if (res.data) {
        console.log(res.data);
      }
      if (res.errors) {
        if (res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)) {
          message.error(ErrorMessages.linkingErrorMsg);
          return;
        }
        message.error(res.errors[0].message);
      }
    });
  };

  const askConfirmationIfMaterialTrackingDisabled = (
    selectedSubmittalId: string
  ) => {
    modal.confirm({
      title: "Do you want to link?",
      icon: <ExclamationCircleOutlined />,
      content: InfoMessages.confirmMsgForMaterialTrackingOff,
      className: "skip-listing-window",
      onOk() {
        onLinkingSubmittalMaterialMutation(selectedSubmittalId);
      },
      okText: "Yes",
      cancelText: "No"
    });
  };

  const onAllLinkUnlinkedSuggestedItems = (checked: boolean) => {
    const submittalIds = filteredSubList
      .filter((s) =>
        checked
          ? !selectedIds.some((id) => id === s.id)
          : selectedIds.some((id) => id === s.id)
      )
      .map((m) => m.id as string);

    const linkVariables = {
      variables: {
        objects: submittalIds.map((id) => ({
          submittal_id: id,
          material_id: material.id
        }))
      }
    };

    const unlinkVariables = {
      variables: {
        where: {
          submittal_id: {
            _in: selectedIds
          },
          material_id: { _eq: material.id }
        }
      }
    };

    modal.confirm({
      title: checked
        ? "Do you want to link all suggested items?"
        : "Do you want to unlink all suggested items?",
      icon: checked ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />,
      content: "",
      className: "skip-listing-window",
      async onOk() {
        const remainigIds = checked
          ? [...selectedIds, ...submittalIds]
          : selectedIds.filter((id) => submittalIds.some((sId) => sId === id));

        setSelectedIds(remainigIds);

        const mutation = checked
          ? linkingSubmittalMaterialMutation(linkVariables)
          : deleteLinkingSubmittalMaterialMutaion(unlinkVariables);

        const mutationRes = await mutation;

        if (mutationRes.data) {
          console.log(mutationRes.data);
        }
        if (mutationRes.errors) {
          if (
            mutationRes.errors[0]?.message.includes(
              ErrorMessages.uniquessViolation
            )
          ) {
            message.error(
              `${
                checked
                  ? ErrorMessages.linkingErrorMsg
                  : ErrorMessages.unLinkingErrorMsg
              }`
            );
            return;
          }
          message.error(mutationRes.errors[0].message);
        }
      },
      okText: "Yes",
      cancelText: "No"
    });
  };

  const onDeleteLinkingSubmittalMaterialMutaion = (
    selectedSubmittalId: string
  ) => {
    modal.confirm({
      title: "Do you want to unlink?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      className: "skip-listing-window",
      onOk() {
        setSelectedIds(
          selectedIds.filter((id: string) => {
            return id !== selectedSubmittalId;
          })
        );
        deleteLinkingSubmittalMaterialMutaion({
          variables: {
            where: {
              material_id: { _eq: material.id },
              submittal_id: { _eq: selectedSubmittalId }
            }
          }
        }).then((res) => {
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
        });
      },
      okText: "Yes",
      cancelText: "No"
    });
  };

  const onChange = (selectedSubmittalId: string) => {
    const selectedMaterial = material?.submittal_material_links.find(
      (submittal: SubmittalLinkedType) => {
        return submittal.submittal.id === selectedSubmittalId;
      }
    );
    if (!selectedMaterial) {
      onLinkingSubmittalMaterialMutation(selectedSubmittalId);
      setSelectedIds([...selectedIds, selectedSubmittalId]);
    } else {
      onDeleteLinkingSubmittalMaterialMutaion(selectedSubmittalId);
    }
  };

  const canLinkMaterial = (
    submittal: SubmittalType
  ): {
    state: boolean;
    message: string;
  } => {
    if (!material.actual_material_release_date) {
      return {
        state: true,
        message: ""
      };
    }
    if (submittal?.status !== 10) {
      return {
        state: false,
        message:
          "This action cannot take place as the submittal is not closed but the material is already released."
      };
    }
    const dateCompare =
      DateFilter.comparator(
        submittal?.submittal_date_block?.actual_submittal_distributed_date ||
          "",
        material.actual_material_release_date
      ) > 0;
    return {
      state: !dateCompare,
      message: dateCompare
        ? ""
        : "This action cannot take place as the material is released before the submittal is closed"
    };
  };

  return (
    <ErrorBoundary>
      <div className="activity-linking" ref={submittalLinking}>
        <div className="activity-linking--nav">
          <h4>
            Associated Submittals
            <span className="">
              {material.submittal_material_links.length > 0
                ? ` (${material.submittal_material_links.length})`
                : ""}
            </span>
          </h4>
        </div>
        <div className="h-full pb-12">
          <div className="h-full overflow-auto overflow-x-hidden">
            {material.submittal_material_links.map(
              (submittal: SubmittalLinkedType) => (
                <div
                  key={submittal.submittal.id}
                  className={`cursor-pointer activity-linking--item flex justify-between${
                    submittal.submittal.id === selectedId ? " selected" : ""
                  }`}
                  onClick={() => {
                    onSelect(submittal.submittal);
                  }}
                >
                  <SubmittalIcon
                    size={20}
                    fill="#a8a8a8"
                    className="grow-none"
                  />
                  <Link
                    className="grow-none"
                    to={`/project/${projectId}/submittals/${submittal.submittal.id}`}
                  >
                    <i>{submittal.submittal.submittal_id}</i>
                  </Link>
                  <Tooltip
                    title={submittal.submittal?.title}
                    placement="topLeft"
                    className="flex align-center grow truncate"
                  >
                    <i>{`${submittal?.submittal?.spec_no ?? ""}${
                      submittal.submittal.spec_name?.length ? "-" : ""
                    }${submittal.submittal.spec_name || ""}  `}</i>
                    &nbsp;&nbsp;
                    {submittal.submittal?.title}
                  </Tooltip>
                  <Button
                    size="small"
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(submittal.submittal.id);
                    }}
                    className="linking--unlink-button grow-none skip-listing-window"
                    disabled={!canEdit}
                  >
                    Unlink <LinkOutlined />
                  </Button>
                </div>
              )
            )}
            <div
              className="flex justify-end mt-2"
              style={{ marginRight: "6px" }}
            >
              <Button
                ref={buttonRef}
                onClick={() => setOpenSubmittalList(true)}
                disabled={!canEdit}
              >
                + Associate Submittal
              </Button>
              {openSubmittalList && (
                <ListingWindow
                  title="List of Submittals"
                  onClose={() => setOpenSubmittalList(false)}
                  suggectionPanel={{
                    isSuggested,
                    setSuggested,
                    areAllSuggestionsLinked: filteredSubList.every((s) =>
                      selectedIds.some((id) => id === s.id)
                    ),
                    onLinkUnlinkAll: onAllLinkUnlinkedSuggestedItems
                  }}
                  isFromSubmittals
                >
                  <div className="material-linking--options">
                    {isSuggested && (
                      <div className="material-linking--suggestedLblDiv">
                        <span className="material-linking--suggestInfoLbl ml-1">
                          Suggested submittals have the same spec section as the
                          selected material.
                        </span>
                      </div>
                    )}
                    <Search
                      className="linking-search"
                      placeholder="Search submittal name here"
                      value={searchKeyword}
                      onChange={(e: any) =>
                        setSearchKeyword(e.currentTarget.value)
                      }
                    />
                    <div className="h-full">
                      <div className="overflow-auto h-[calc(100vh-370px)]">
                        {filteredSubList.length === 0 && (
                          <div className="flex justify-center items-center h-full">
                            <span className="text-[#3b3b3b]">
                              {isSuggested ? (
                                "No suggestions available. Uncheck suggested submittals to view the list of submittals."
                              ) : (
                                <span className="text-[#3b3b3b]">
                                  No submittals have been added to this project.
                                  <Link to={`/project/${projectId}/submittals`}>
                                    {" "}
                                    Click here
                                  </Link>{" "}
                                  to add submittals.`
                                </span>
                              )}
                            </span>
                          </div>
                        )}
                        {filteredSubList.length > 0 &&
                          filteredSubList
                            ?.filter((submittal: SubmittalType) => {
                              return `${submittal.submittal_id} ${submittal.spec_no} ${submittal.spec_name} ${submittal?.title}`
                                .toLocaleLowerCase()
                                .includes(searchKeyword.toLocaleLowerCase());
                            })
                            ?.map((submittal: SubmittalType) => {
                              const canLinkObj = canLinkMaterial(submittal);
                              return (
                                <div
                                  className={
                                    submittal?.spec_no ===
                                    material?.spec_section_no
                                      ? "material-linking--suggestedOption"
                                      : "material-linking--option"
                                  }
                                  key={submittal.id}
                                >
                                  <Tooltip
                                    placement="topLeft"
                                    title={canLinkObj.message}
                                  >
                                    <Checkbox
                                      disabled={!canLinkObj.state}
                                      checked={selectedIds.includes(
                                        submittal.id
                                      )}
                                      onChange={() => {
                                        if (submittal.material_tracking) {
                                          onChange(submittal.id);
                                        } else {
                                          askConfirmationIfMaterialTrackingDisabled(
                                            submittal.id
                                          );
                                        }
                                      }}
                                    >
                                      <span>
                                        <i>{submittal.submittal_id}</i>{" "}
                                        <i>{`${submittal?.spec_no ?? ""}${
                                          submittal.spec_name?.length ? "-" : ""
                                        }${submittal.spec_name || ""}  `}</i>
                                        &nbsp;&nbsp;
                                        {submittal?.title}
                                      </span>
                                    </Checkbox>
                                  </Tooltip>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                </ListingWindow>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default MaterialSubmittalLinking;
