import { WarningFilled } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";

export type InputEditDataType = {
  value: any;
  id: string;
  type?: string;
  lable?: string;
  warningMessage?: string;
};

type RenamePopupProps = {
  inputData: InputEditDataType;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onOk: (renamedText: string, id: string) => Promise<void>;
  title?: string;
  okName?: string;
};
function RenamePopup({
  inputData,
  isModalOpen,
  setIsModalOpen,
  onOk,
  title,
  okName
}: RenamePopupProps) {
  const [loading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [updatingString, setUpdatingString] = useState("");
  const [form] = Form.useForm();

  const handleOk = async () => {
    setLoading(true);
    await onOk(updatingString, inputData.id);
    setLoading(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onInputChange = (e: any) => {
    const { value } = e.target;
    setUpdatingString(value);
    form.setFieldValue("duration", value);
    form.validateFields();

    if (!value || value.trim() === "") {
      setValid(false);
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (inputData.value == value) {
      setValid(false);
      return;
    }
    if (!isValid) setValid(true);
  };

  useEffect(() => {
    setLoading(false);
    setUpdatingString(inputData.value);
    // value can be zero but it should not be undefined or null
    // eslint-disable-next-line eqeqeq
    if (inputData && inputData.value != undefined)
      onInputChange({ target: { value: inputData.value.toString() } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData.value]);

  return (
    <Modal
      key={inputData.id}
      title={title || "Renamed"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          type="primary"
          disabled={!isValid || showWarningMsg}
          loading={loading}
          onClick={handleOk}
        >
          {okName || "Rename"}
        </Button>
      ]}
      destroyOnClose
    >
      {inputData.warningMessage && (
        <div className="flex pb-4 text-xs items-center text-orange-500">
          <WarningFilled className="pr-2" /> {inputData.warningMessage}
        </div>
      )}
      <div className="flex w-full space-x-1" key={inputData.id}>
        <Form form={form} className="w-full" layout="vertical">
          <Form.Item
            label={`${inputData.lable || "New Name"} (in days)`}
            name="duration"
            initialValue={updatingString}
            rules={[
              {
                validator: async (_, value) => {
                  const regex = /^[0-9\b]+$/;
                  if (!regex.test(value)) {
                    setShowWarningMsg(true);
                    return Promise.reject(
                      new Error("Please enter duration in numbers only.")
                    );
                  }
                  setShowWarningMsg(false);
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input
              type={inputData.type || "text"}
              className={
                isValid
                  ? ""
                  : "border border-solid border-red-700 focus:border-red-700"
              }
              value={updatingString}
              onChange={onInputChange}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
export default RenamePopup;
