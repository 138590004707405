import { forwardRef } from "react";
import { ScheduleImpactSummaryType } from "./model";
import { ScheduleImpactList } from "./ScheduleImpactList";

type Props = {
  scheduleImpactSummary: ScheduleImpactSummaryType;
};

const RiskCompareSchedule = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  ({ scheduleImpactSummary }: Props, ref: any) => {
    return <ScheduleImpactList scheduleImpactSummary={scheduleImpactSummary} />;
  }
);
export default RiskCompareSchedule;
