import { FilterNamesEnum } from "pages/project-dashboard/gc-project-dashboard2/models";
import { ESubmittalStatus, MaterialNotReleaseStatus } from "../constants";

export function applySubmittalLogFilters(params: any) {
  const {
    gridRef,
    loggedInUserInfo,
    submittalListFilter,
    SubmittalStatusToStrMap
  } = params;

  switch (submittalListFilter.key) {
    case "gc-due-in-days":
      gridRef.current?.api?.getFilterInstance(
        "planned_submittal_distributed_date",
        (instance: any) => {
          instance?.setSelectedFilter("Due in 5 days");

          gridRef.current?.api?.getFilterInstance(
            "state",
            (draftinstance: any) => {
              draftinstance?.setModel({ values: ["Draft", "Open"] });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "gc-overdue":
      gridRef.current?.api?.getFilterInstance(
        "planned_submittal_distributed_date",
        (instance: any) => {
          instance?.setSelectedFilter("Overdue");

          gridRef.current?.api?.getFilterInstance(
            "state",
            (draftinstance: any) => {
              draftinstance?.setModel({ values: ["Draft", "Open"] });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "gc-draft":
      gridRef.current?.api?.getFilterInstance("state", (stateInstance: any) => {
        stateInstance?.setModel({ values: ["Draft"] });

        gridRef.current?.api?.getFilterInstance(
          "submittal_created_by",
          (createdByInstance: any) => {
            createdByInstance?.setModel({
              values: [
                `${loggedInUserInfo["first-name"]} ${loggedInUserInfo["last-name"]}`
              ]
            });
            gridRef.current!.api.onFilterChanged();
          }
        );
      });
      break;
    case "gc-to-review":
      gridRef.current?.api?.getFilterInstance(
        "assignee",
        (assigneeInstance: any) => {
          assigneeInstance?.setModel({
            values: [
              `${loggedInUserInfo["first-name"]} ${loggedInUserInfo["last-name"]}`
            ]
          });
          gridRef.current?.api?.getFilterInstance(
            "status",
            (workflowInstance: any) => {
              workflowInstance?.setModel({
                values: [SubmittalStatusToStrMap[ESubmittalStatus.GC_REVIEW]]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "gc-to-distribute":
      gridRef.current?.api?.getFilterInstance(
        "assignee",
        (assigneeInstance: any) => {
          assigneeInstance?.setModel({
            values: [
              `${loggedInUserInfo["first-name"]} ${loggedInUserInfo["last-name"]}`
            ]
          });
          gridRef.current?.api?.getFilterInstance(
            "status",
            (workflowInstance: any) => {
              workflowInstance?.setModel({
                values: [
                  SubmittalStatusToStrMap[
                    ESubmittalStatus.READY_FOR_DISTRIBUTION
                  ]
                ]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "due-in-days":
      gridRef.current?.api?.getFilterInstance(
        "planned_trade_partner_submitted_date",
        (instance: any) => {
          instance?.setSelectedFilter("Due in 5 days");
          gridRef.current?.api?.getFilterInstance(
            "state",
            (draftinstance: any) => {
              draftinstance?.setModel({ values: ["Draft", "Open"] });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "overdue":
      gridRef.current?.api?.getFilterInstance(
        "planned_trade_partner_submitted_date",
        (instance: any) => {
          instance?.setSelectedFilter("Overdue");
          gridRef.current?.api?.getFilterInstance(
            "state",
            (draftinstance: any) => {
              draftinstance?.setModel({ values: ["Draft", "Open"] });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "draft":
      gridRef.current?.api?.getFilterInstance("state", (instance: any) => {
        instance?.setModel({ values: ["Draft"] });
        gridRef.current!.api.onFilterChanged();
      });
      break;
    case "to-be-submitted":
      gridRef.current?.api?.getFilterInstance(
        "assignee",
        (assigneeInstance: any) => {
          assigneeInstance?.setModel({
            values: [
              `${loggedInUserInfo["first-name"]} ${loggedInUserInfo["last-name"]}`
            ]
          });

          gridRef.current?.api?.getFilterInstance(
            "status",
            (workflowInstance: any) => {
              workflowInstance?.setModel({
                values: [SubmittalStatusToStrMap[ESubmittalStatus.SUBMIT]]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "arch-due-in-days":
      gridRef.current?.api?.getFilterInstance(
        "planned_ext_review_completed_date",
        (instance: any) => {
          instance?.setSelectedFilter("Due in 5 days");
          gridRef.current?.api?.getFilterInstance(
            "state",
            (draftinstance: any) => {
              draftinstance?.setModel({ values: ["Draft", "Open"] });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "arch-overdue":
      gridRef.current?.api?.getFilterInstance(
        "planned_ext_review_completed_date",
        (instance: any) => {
          instance?.setSelectedFilter("Overdue");
          gridRef.current?.api?.getFilterInstance(
            "state",
            (draftinstance: any) => {
              draftinstance?.setModel({ values: ["Draft", "Open"] });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "arch-to-review":
      gridRef.current?.api?.getFilterInstance(
        "assignee",
        (assigneeInstance: any) => {
          assigneeInstance?.setModel({
            values: [
              `${loggedInUserInfo["first-name"]} ${loggedInUserInfo["last-name"]}`
            ]
          });
          gridRef.current?.api?.getFilterInstance(
            "workflow_status",
            (workflowInstance: any) => {
              workflowInstance?.setModel({
                values: [
                  SubmittalStatusToStrMap[ESubmittalStatus.EXTERNAL_REVIEW]
                ]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case FilterNamesEnum.DueIn2Week:
      gridRef.current?.api?.getFilterInstance(
        "next_planned_deadline",
        (nextPlannedDeadlineInstance: any) => {
          nextPlannedDeadlineInstance?.setSelectedFilter(
            "Overdue or due in 2 weeks"
          );
          gridRef.current?.api?.getFilterInstance(
            "status",
            (statusInstance: any) => {
              statusInstance?.setModel({
                values: statusInstance.valueModel.allValues.filter(
                  (value: string) =>
                    value !== SubmittalStatusToStrMap[ESubmittalStatus.DONE]
                )
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );

      break;
    case FilterNamesEnum.HighDueIn2Week:
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (dueDateInstance: any) => {
          dueDateInstance?.setSelectedFilter("Overdue or due in 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (riskInstance: any) => {
              riskInstance?.setModel({
                values: ["HIGH"]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case FilterNamesEnum.LowDueIn2Week:
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (dueDataInstance: any) => {
          dueDataInstance?.setSelectedFilter("Overdue or due in 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (riskInstance: any) => {
              riskInstance?.setModel({
                values: ["LOW", "MEDIUM"]
              });
              gridRef.current?.api?.getFilterInstance(
                "status",
                (statusInstance: any) => {
                  statusInstance?.setModel({
                    values: statusInstance.valueModel.allValues.filter(
                      (value: string) =>
                        value !== SubmittalStatusToStrMap[ESubmittalStatus.DONE]
                    )
                  });
                  gridRef.current!.api.onFilterChanged();
                }
              );
            }
          );
        }
      );
      break;
    case FilterNamesEnum.HighDueLater:
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (dueDateInstance: any) => {
          dueDateInstance?.setSelectedFilter("due after 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (riskInstance: any) => {
              riskInstance?.setModel({
                values: ["HIGH"]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );

      break;
    case FilterNamesEnum.LowDueLater:
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (dueDateInstance: any) => {
          dueDateInstance?.setSelectedFilter("due after 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (riskInstance: any) => {
              riskInstance?.setModel({
                values: ["LOW", "MEDIUM"]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "alert_new_submittals":
      gridRef.current?.api?.getFilterInstance("created_at", (instance: any) => {
        instance?.setSelectedFilter("after date");
        gridRef.current!.api.onFilterChanged();
      });
      break;
    case FilterNamesEnum.NoPlan:
      gridRef.current?.api?.getFilterInstance(
        "next_planned_deadline",
        (nextPlannedDeadlineInstance: any) => {
          nextPlannedDeadlineInstance?.setSelectedFilter("no date present");

          gridRef.current?.api?.getFilterInstance(
            "status",
            (statusInstance: any) => {
              statusInstance?.setModel({
                values: statusInstance.valueModel.allValues.filter(
                  (value: string) =>
                    value !== SubmittalStatusToStrMap[ESubmittalStatus.DONE]
                )
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    default:
      console.log("no filters matched");
  }
}

export function applyMaterialLogFilters(params: any) {
  const { gridRef, materialListFilter } = params;
  if (!gridRef.current) return;

  switch (materialListFilter) {
    case "gc-due-in-days":
      gridRef.current?.api?.getFilterInstance(
        "planned_ROJ",
        (instance: any) => {
          instance?.setSelectedFilter("Due in 5 days");

          gridRef.current?.api?.getFilterInstance(
            "actual_ROJ",
            (actualROJinstance: any) => {
              actualROJinstance?.setSelectedFilter("Actual Date NA");
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "gc-overdue":
      gridRef.current?.api?.getFilterInstance(
        "planned_ROJ",
        (instance: any) => {
          instance?.setSelectedFilter("Overdue");
          gridRef.current?.api?.getFilterInstance(
            "actual_ROJ",
            (actualROJinstance: any) => {
              actualROJinstance?.setSelectedFilter("Actual Date NA");
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "due-in-days":
      gridRef.current?.api?.getFilterInstance(
        "planned_ROJ",
        (instance: any) => {
          instance?.setSelectedFilter("Due in 5 days");
          gridRef.current?.api?.getFilterInstance(
            "actual_ROJ",
            (actualROJinstance: any) => {
              actualROJinstance?.setSelectedFilter("Actual Date NA");
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case "overdue":
      gridRef.current?.api?.getFilterInstance(
        "planned_ROJ",
        (instance: any) => {
          instance?.setSelectedFilter("Overdue");
          gridRef.current?.api?.getFilterInstance(
            "actual_ROJ",
            (actualROJinstance: any) => {
              actualROJinstance?.setSelectedFilter("Actual Date NA");
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case FilterNamesEnum.DueIn2Week:
      gridRef.current?.api?.getFilterInstance(
        "next_planned_deadline",
        (nextPlannedDeadlineInstance: any) => {
          nextPlannedDeadlineInstance?.setSelectedFilter(
            "Overdue or due in 2 weeks"
          );
          gridRef.current!.api.onFilterChanged();
        }
      );

      break;
    case FilterNamesEnum.HighDueIn2Week:
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (dueDateInstance: any) => {
          dueDateInstance?.setSelectedFilter("Overdue or due in 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (riskLevelInstance: any) => {
              riskLevelInstance?.setModel({
                values: ["HIGH"]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );

      break;
    case FilterNamesEnum.LowDueIn2Week:
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (overdueInstance: any) => {
          overdueInstance?.setSelectedFilter("Overdue or due in 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (riskLevelInstance: any) => {
              riskLevelInstance?.setModel({
                values: ["LOW", "MEDIUM"]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    case FilterNamesEnum.HighDueLater: {
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (dueDateInstance: any) => {
          dueDateInstance?.setSelectedFilter("due after 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (statusInstance: any) => {
              statusInstance?.setModel({
                values: ["HIGH"]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    }
    case FilterNamesEnum.LowDueLater:
      gridRef.current?.api?.getFilterInstance(
        "due_date",
        (dueDateInstance: any) => {
          dueDateInstance?.setSelectedFilter("due after 2 weeks");
          gridRef.current?.api?.getFilterInstance(
            "risk_level",
            (riskInstance: any) => {
              riskInstance?.setModel({
                values: ["LOW", "MEDIUM"]
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );

      break;
    case FilterNamesEnum.NoPlan:
      gridRef.current?.api?.getFilterInstance(
        "next_planned_deadline",
        (nextPlannedDeadlineInstance: any) => {
          nextPlannedDeadlineInstance?.setSelectedFilter("no date present");

          gridRef.current?.api?.getFilterInstance(
            "status",
            (statusInstance: any) => {
              statusInstance?.setModel({
                values: statusInstance.valueModel.allValues.filter(
                  (value: string) => value === MaterialNotReleaseStatus
                )
              });
              gridRef.current!.api.onFilterChanged();
            }
          );
        }
      );
      break;
    default:
      console.log("no filters matched");
  }
}
