import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space, message } from "antd";
import DefaultTag from "components/widgets/default-tag";
import WorkflowTemplateEditor from "components/workflow-template-editor";
import { TSaveWorkflowModelInput } from "components/workflow-template-editor/model";
import { useCIQMutation, useCIQQuerySubscription } from "hooks/ciq-gql-hooks";
import MaterialTemplateList from "pages/project-setting-menu/module-settings/material-settings/material-templates/material-template-list";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GET_MDB_SUBSCRIPTION_TEMPLATES } from "services/graphQL/ciq-queries";
import {
  MUTATION_COPY_SUBSCRIPTION_TEMPLATE,
  MUTATION_SET_DEFAULT_SUBSCRIPTION_TEMPLATE,
  MUTATION_UPDATE_SUBSCRIPTION_MDB_TEMPLATE,
  MUTATION_UPDATE_SUBSCRIPTION_TEMPLATE_API
} from "services/graphQL/mutations";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { ErrorMessages, SuccessMessages } from "../../../../../constants";

function MaterialTemplateSubscriptionSettings() {
  const [detailsViewData, setdetailsViewData] = useState<any>(null);
  const [savingDefaultTemplate, setSavingDefaultTemplate] = useState(false);
  const [materialTemplates, setmaterialTemplates] = useState<any>();

  const { subscriptionRole } = useContext(SubscriptionContext);

  const { data: MDBTemplates } = useCIQQuerySubscription<{
    subscription_workflow_templates: Array<any>;
  }>(GET_MDB_SUBSCRIPTION_TEMPLATES, {});

  const [saveProjectTemplateMutation] = useCIQMutation(
    MUTATION_UPDATE_SUBSCRIPTION_TEMPLATE_API
  );

  const [setDefaultMDBTemplate] = useCIQMutation(
    MUTATION_SET_DEFAULT_SUBSCRIPTION_TEMPLATE
  );

  const clonedMDBTemplatesSoruce = useMemo(() => {
    if (!MDBTemplates || !MDBTemplates.subscription_workflow_templates)
      return null;
    return structuredClone(MDBTemplates.subscription_workflow_templates);
  }, [MDBTemplates]);

  useEffect(() => {
    if (!MDBTemplates || !MDBTemplates.subscription_workflow_templates) return;
    setmaterialTemplates(clonedMDBTemplatesSoruce);
  }, [MDBTemplates, clonedMDBTemplatesSoruce]);

  const [copyMDBTemplateMutation] = useCIQMutation(
    MUTATION_COPY_SUBSCRIPTION_TEMPLATE,
    {}
  );

  const [disableMDBTemplate] = useCIQMutation(
    MUTATION_UPDATE_SUBSCRIPTION_MDB_TEMPLATE
  );

  const canEditMaterialDBTemplate = useMemo(
    () => subscriptionRole > RoleSubscriptionEnum.subscription_viewer,
    [subscriptionRole]
  );

  const templateObjectForDetailsView = useMemo(() => {
    if (!detailsViewData || !MDBTemplates?.subscription_workflow_templates)
      return null;
    return MDBTemplates.subscription_workflow_templates.find(
      (template: any) => {
        return template.id === detailsViewData.data.id;
      }
    );
  }, [MDBTemplates, detailsViewData]);

  const onSaveTemplate = async (data: TSaveWorkflowModelInput) => {
    const res = await saveProjectTemplateMutation({
      variables: data
    });
    if (res.data) {
      message.success("Updated template successfully.");
    }
    if (res.errors && res.errors.length > 0) {
      message.error(res.errors[0].message);
    }
  };

  const onChangeDefaultTemplate = useCallback(
    async (cellData: any) => {
      if (!MDBTemplates || !MDBTemplates?.subscription_workflow_templates)
        return;
      setSavingDefaultTemplate(true);

      const updatedTemplates = MDBTemplates.subscription_workflow_templates.map(
        (template: any) => {
          if (cellData.id === template.id) {
            return {
              ...template,
              default: true
            };
          }
          return {
            ...template,
            default: false
          };
        }
      );

      setmaterialTemplates([...updatedTemplates]);

      const payload: any = {
        variables: {
          materialTemplateId: cellData.id
        }
      };

      const setDefaultTemplateResponse = await setDefaultMDBTemplate(payload);

      if (setDefaultTemplateResponse.success) {
        message.success(SuccessMessages.MDBDefaultTemplateSet);
      } else {
        setmaterialTemplates(clonedMDBTemplatesSoruce);
      }
      setSavingDefaultTemplate(false);
    },
    [MDBTemplates, clonedMDBTemplatesSoruce, setDefaultMDBTemplate]
  );

  const onWorkflowCopy = async (payload: any) => {
    const copyTemplateResponse = await copyMDBTemplateMutation(payload);
    if (copyTemplateResponse.success) {
      message.success(SuccessMessages.MDBTemplateCopiedSuccessfully);
    }
  };

  const onToggleTemplateDisabled = async (data: any) => {
    setSavingDefaultTemplate(true);

    const clonedTemplatesToRevert = structuredClone(materialTemplates);
    const updatedToggleMaterialList = materialTemplates.map((template: any) => {
      if (template.id === data.id) {
        return {
          ...template,
          disabled: !data.disabled
        };
      }
      return template;
    });

    setmaterialTemplates(updatedToggleMaterialList);
    const toggleDisableResp = await disableMDBTemplate({
      variables: {
        pk_columns: { id: data.id },
        _set: { disabled: !data.disabled }
      }
    });

    if (!toggleDisableResp.success) {
      setmaterialTemplates(clonedTemplatesToRevert);
      message.error(ErrorMessages.disableTemplateFailed);
      return;
    }
    setSavingDefaultTemplate(false);
    message.success(
      !data.disabled
        ? SuccessMessages.MDBTemplateDisabledSuccessfully
        : SuccessMessages.MDBTemplateEnabledSuccessfully
    );
  };

  return (
    <div className="py-[2px] px-4">
      {!detailsViewData && (
        <MaterialTemplateList
          setdetailsViewData={setdetailsViewData}
          workflowTemplates={materialTemplates}
          canEditMaterialDBTemplate={canEditMaterialDBTemplate}
          onChangeDefaultTemplate={onChangeDefaultTemplate}
          savingDefaultTemplate={savingDefaultTemplate}
          onWorkflowCopy={onWorkflowCopy}
          onToggleTemplateDisabled={onToggleTemplateDisabled}
        />
      )}
      {templateObjectForDetailsView && (
        <div>
          <div className="flex items-center space-x-3 py-1 mt-0.5">
            <Space
              className="flex items-center cursor-pointer"
              onClick={() => {
                setdetailsViewData(null);
              }}
            >
              <ArrowLeftOutlined />
              <span className="text-base font-medium uppercase">
                {templateObjectForDetailsView.name}
              </span>
            </Space>
            {templateObjectForDetailsView.default && <DefaultTag />}
          </div>
          <div>
            Create the material date block templates you wish to use across
            projects. It is important to note that new templates added or any
            modifications made to existing templates here, will not impact
            existing projects.
          </div>
          <div className="py-3">
            <WorkflowTemplateEditor
              milestones={
                templateObjectForDetailsView.subscription_template_milestones
              }
              workflowTemplate={templateObjectForDetailsView}
              otherTemplateNames={MDBTemplates?.subscription_workflow_templates
                .filter((x: any) => x.id !== templateObjectForDetailsView.id)
                .map((x: any) => x?.name?.toLowerCase())}
              disable={
                !canEditMaterialDBTemplate ||
                templateObjectForDetailsView.disabled
              }
              openInEditMode={detailsViewData.openInEditMode}
              onSave={onSaveTemplate}
              isTemplateUsed={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default MaterialTemplateSubscriptionSettings;
