import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Divider, Form, Input, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  IntegrationNameCellRenderer,
  integrationTypeCellRenderer
} from "components/cell-renders";
import { GridLoadingIndicator } from "components/widgets";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { getOAuthUrl } from "services/integration-service";

import {
  IntegrationType,
  getCIQAUthorizerDetails
} from "pages/subscription-settings/integrations-tab-new/utils";
import { useIntegrationAuthProcess } from "hooks/integration-hooks";
import ProjectIntegrationListMessage from "components/widgets/project-integration-list-message";
import { useHistory } from "react-router";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  ErrorMessages,
  IntegrationAuthProcessStatuses,
  IntegrationSystemNameMap
} from "../../../constants";
import { ProjectIntegrationsTabSharedContext } from "./project-integrations-tab-context";

const { Option } = Select;

function ProjectIntegrationsList() {
  const history = useHistory();

  const { subscriptionRole } = useContext(SubscriptionContext);

  const { tokenRetrievalState }: TProjectContext = useContext(ProjectContext);

  const { dispatch, sharedState } = useContext(
    ProjectIntegrationsTabSharedContext
  );

  const gridRef = useRef<AgGridReact>(null);
  const [form] = useForm();

  const [addingIntegration, setAddingIntegration] = useState(false);

  const canAddIntegration =
    subscriptionRole === RoleSubscriptionEnum.subscription_admin;

  const { initAuthProcess } = useIntegrationAuthProcess("project");

  const [openAddIntegrationPanel, setOpenAddIntegrationPanel] = useState(false);

  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      autoHeight: true,
      resizable: true,
      headerClass: "grid-header-style",
      flex: 1,
      minWidth: 100
    };
  }, []);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "INTEGRATION NAME",
        headerTooltip: "INTEGRATION NAME",
        menuTabs: [],
        cellRenderer: IntegrationNameCellRenderer
      },
      {
        headerName: "INTEGRATION TYPE",
        field: "integration.system",
        menuTabs: [],
        filter: false,
        sortable: false,
        cellRenderer: integrationTypeCellRenderer
      },
      {
        headerName: "ACCOUNT NAME",
        menuTabs: [],
        filter: false,
        sortable: false,
        valueGetter: ({ data }: any) => {
          return (
            data.subscriptionProperties.PROCORE_COMPANY_NAME ||
            data.subscriptionProperties.AUTODESK_ACCOUNT_NAME
          );
        }
      },
      {
        headerName: "PROJECT NAME",
        menuTabs: [],
        filter: false,
        sortable: false,
        valueGetter: ({ data }: any) => {
          return (
            data.properties.PROCORE_PROJECT_NAME ||
            data.properties.AUTODESK_PROJECT_NAME
          );
        }
      },
      {
        headerName: "ADDED BY",
        headerTooltip: "ADDED BY",
        menuTabs: [],
        sortable: false,
        valueGetter: (params: any) => {
          const authorizerDetails = getCIQAUthorizerDetails(
            params.data.properties
          );
          const emailStr = authorizerDetails.email
            ? `(${authorizerDetails.email})`
            : "";
          return `${authorizerDetails.name} ${emailStr}`;
        }
      }
    ];
  }, []);

  const availableIntegrationOptions = useMemo(() => {
    if (
      !sharedState?.availableIntegrations ||
      !sharedState?.subscriptionIntegrationConfigs?.length
    )
      return [];
    return sharedState?.availableIntegrations.map(
      (availableIntegration: any) => {
        const foundSubscriptionIntgConfig =
          sharedState.subscriptionIntegrationConfigs.find(
            (sIntgConfig: any) => {
              return (
                sIntgConfig.integration.system === availableIntegration.system
              );
            }
          );
        return {
          value: availableIntegration.id,
          label: IntegrationSystemNameMap[availableIntegration.system],
          subscriptionIntgConfig: foundSubscriptionIntgConfig || null
        };
      }
    );
  }, [
    sharedState?.availableIntegrations,
    sharedState.subscriptionIntegrationConfigs
  ]);

  const selectedIntegrationType = Form.useWatch("integrationType", form);

  useEffect(() => {
    if (selectedIntegrationType === IntegrationType.PROCORE) {
      const foundProcoreConfig = sharedState.systemIntegrationConfigs.find(
        (config: any) => {
          return config.system === IntegrationType[IntegrationType.PROCORE];
        }
      );

      form.setFieldsValue({
        root_url:
          foundProcoreConfig.integration_config_properties.PROCORE_ROOT_URL,
        login_url:
          foundProcoreConfig.integration_config_properties.PROCORE_LOGIN_URL
      });
    }
  }, [form, selectedIntegrationType, sharedState.systemIntegrationConfigs]);

  const onManageIntegrationClick = async (data: any) => {
    dispatch({
      type: "SET_SHARED_STATE",
      payload: {
        activeView: "details",
        system: data.integration.system,
        additionalData: data
      }
    });
  };

  const onFormSubmit = async (values: any) => {
    setAddingIntegration(true);

    if (values.integrationType === IntegrationType.AUTODESK) {
      const OAuthURLResponse = await getOAuthUrl(
        IntegrationType[values.integrationType],
        {},
        tokenRetrievalState?.token
      );

      setAddingIntegration(false);

      if (OAuthURLResponse.success) {
        const authResponse: any = await initAuthProcess(OAuthURLResponse.data);
        // console.log("authResponse ", authResponse);
        if (
          authResponse?.oauth_status?.status ===
          IntegrationAuthProcessStatuses.PROCESSED
        ) {
          setOpenAddIntegrationPanel(false);
          dispatch({
            type: "SET_SHARED_STATE",
            payload: {
              activeView: "configure-initial",
              system: authResponse.source_type.toUpperCase(),
              additionalData: {
                newIntegrationName: form.getFieldValue("integrationName")
              }
            }
          });
        } else {
          const error = authResponse.output?.error || "Authorization failed";
          message.error(error);
        }
      }
    }

    if (values.integrationType === IntegrationType.PROCORE) {
      dispatch({
        type: "SET_SHARED_STATE",
        payload: {
          activeView: "configure-initial",
          system: IntegrationType[IntegrationType.PROCORE],
          additionalData: {
            newIntegrationName: form.getFieldValue("integrationName")
          }
        }
      });
    }
  };

  return (
    <div>
      <div>
        <div className="px-3 py-2 flex items-center justify-between">
          <div className="text-[#3B3B3B] font-semibold text-sm">
            {sharedState?.projectIntegrationConfigs
              ? `${sharedState?.projectIntegrationConfigs.length} `
              : ""}
            INTEGRATIONS
          </div>
          <div>
            {sharedState?.subscriptionIntegrationConfigs?.length &&
            sharedState?.projectIntegrationConfigs &&
            !sharedState?.projectIntegrationConfigs?.length ? (
              <Button
                onClick={() => {
                  setOpenAddIntegrationPanel(true);
                }}
                disabled={!canAddIntegration || addingIntegration}
                title={
                  !canAddIntegration ? ErrorMessages.PermissionNotGranted : ""
                }
              >
                ADD INTEGRATION
              </Button>
            ) : null}
          </div>
        </div>
        <div className="h-[500px] ag-theme-alpine relative">
          {sharedState?.projectIntegrationConfigs &&
            !sharedState?.projectIntegrationConfigs.length && (
              <ProjectIntegrationListMessage
                onConfigureClick={() => {
                  setOpenAddIntegrationPanel(true);
                }}
              />
            )}
          <AgGridReact<any>
            ref={gridRef}
            onFirstDataRendered={() => {
              gridRef.current!.api.sizeColumnsToFit();
            }}
            rowData={sharedState?.projectIntegrationConfigs}
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows // Optional - set to 'true' to have rows animate when sorted
            loadingOverlayComponent={GridLoadingIndicator}
            suppressDragLeaveHidesColumns
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            suppressContextMenu
            suppressRowDrag
            suppressMovableColumns
            context={{
              onManageIntegrationClick
            }}
            suppressNoRowsOverlay
          />
        </div>
      </div>

      <Modal
        className="custom-drawer"
        title="ADD INTEGRATION"
        width={420}
        style={{
          right: 0,
          bottom: 0,
          top: 40,
          padding: 0,
          position: "absolute"
        }}
        bodyStyle={{
          height: "calc(100vh - 92px)",
          padding: "10px 0px 10px 0px"
        }}
        footer={null}
        open={openAddIntegrationPanel}
        onCancel={() => {
          setOpenAddIntegrationPanel(false);
        }}
        destroyOnClose
        maskClosable={false}
        keyboard={false}
      >
        <div className="px-3 h-full">
          <Form
            form={form}
            layout="vertical"
            className="h-full"
            onFinish={onFormSubmit}
            disabled={addingIntegration}
          >
            <div className="h-full flex flex-col">
              <div className="grow">
                <div>
                  <Form.Item
                    name="integrationName"
                    label="Integration Name"
                    required
                    rules={[
                      { required: true, message: "Enter Integration Name" }
                    ]}
                  >
                    <Input placeholder="Enter Integration Name" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="integrationType"
                    label="Integration Type"
                    required
                    rules={[
                      { required: true, message: "Select integration type" }
                    ]}
                  >
                    <Select
                      placeholder="Select Integration Type"
                      notFoundContent={
                        <div>
                          Go to{" "}
                          <Button
                            size="small"
                            type="text"
                            className="font-medium workflow-document-upload"
                            onClick={() => {
                              history.push("/settings/integrations");
                            }}
                          >
                            Subscription settings
                          </Button>{" "}
                          to add integrations
                        </div>
                      }
                    >
                      {availableIntegrationOptions?.map((option: any) => {
                        return (
                          <Option
                            key={option.value}
                            value={option.value}
                            label={option.label}
                            disabled={!option.subscriptionIntgConfig}
                          >
                            {option.subscriptionIntgConfig ? (
                              option.label
                            ) : (
                              <div>
                                <div className="text-gray-400">
                                  {option.label}
                                </div>
                                <div className="text-gray-600">
                                  Go to{" "}
                                  <Button
                                    size="small"
                                    type="text"
                                    className="font-medium workflow-document-upload"
                                    onClick={() => {
                                      history.push("/settings/integrations");
                                    }}
                                  >
                                    Subscription Settings
                                  </Button>{" "}
                                  to add this integration.
                                </div>
                              </div>
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {selectedIntegrationType === IntegrationType.PROCORE && (
                    <div className="pb-2">
                      <div className="mt-2 mb-1 text-xs">
                        <div className="font-semibold mb-1">
                          Contact your Procore Company Admin to follow the below
                          steps before proceeding here:
                        </div>
                        <ol className="m-0 pl-4 space-y-0.5">
                          <li>Go to Procore App Management.</li>
                          <li>Select ConstructivIQ App.</li>
                          <li>Go to Permissions.</li>
                          <li>
                            Add the project which you wish to integrate under{" "}
                            <br />
                            &apos;Permitted Projects&apos;.
                          </li>
                        </ol>
                      </div>
                    </div>
                  )}
                  {selectedIntegrationType === IntegrationType.AUTODESK && (
                    <div className="pb-2">
                      <div className="mt-2 mb-1 text-xs">
                        <div>
                          The next step is to login to Autodesk. To login, use
                          the credentials of an Autodesk Construction Cloud
                          Project Admin who is also a Submittal Manager in the
                          Project you wish to connect.
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Divider className="mx-0 mt-1 mb-4" />
                <div className="flex justify-end space-x-4">
                  <Button
                    htmlType="reset"
                    className="px-5"
                    onClick={() => {
                      setOpenAddIntegrationPanel(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="px-5"
                    disabled={addingIntegration}
                    loading={addingIntegration}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default ProjectIntegrationsList;
