/* eslint-disable react/destructuring-assignment */
import "./material-schedule-cell-renderer.scss";
import {
  cellWithActualDateDifference,
  cellWithActualPlannedDates,
  cellWithDataDifference,
  cellWithPlannedDate
} from "components/schedule-impact-cells/data-difference-cell";
import { DateUtils } from "utils/dateutils";

function MaterialScheduleCellRenderer(params: any) {
  const { isDeleted } = params;
  const renderMaterialUI = () => {
    if (params.colDef.field === "material_PO_date") {
      const poNewValue = params.data?.MDB?.new?.material_PO_date
        ? DateUtils.format(params.data?.MDB?.new?.material_PO_date)
        : "";
      const poOldValue = params.data?.MDB?.old?.material_PO_date
        ? DateUtils.format(params.data?.MDB?.old?.material_PO_date)
        : "";

      if (isDeleted && !params.data?.MDB?.old?.actual_material_release_date) {
        return cellWithPlannedDate(poOldValue);
      }
      if (params.data?.MDB?.old?.actual_material_release_date) {
        const actualValue = DateUtils.format(
          params.data.MDB.old?.actual_material_release_date
        );

        if (poNewValue === poOldValue) {
          return cellWithActualPlannedDates(poOldValue, actualValue);
        }

        return cellWithActualDateDifference("", poOldValue, actualValue);
      }

      if (poNewValue === poOldValue) {
        return cellWithPlannedDate(poNewValue);
      }
      return cellWithDataDifference(poNewValue, poOldValue);
    }
    if (params.colDef.field === "fabrication_start_date") {
      const fstNewValue = params.data?.MDB?.new?.fabrication_start_date
        ? DateUtils.format(params.data?.MDB?.new?.fabrication_start_date)
        : "";
      const fstOldValue = params.data?.MDB?.old?.fabrication_start_date
        ? DateUtils.format(params.data?.MDB?.old?.fabrication_start_date)
        : "";

      if (isDeleted && !params.data.MDB.old?.actual_fabrication_start_date) {
        return cellWithPlannedDate(fstOldValue);
      }

      if (params.data.MDB.old?.actual_fabrication_start_date) {
        const actualValue = DateUtils.format(
          params.data.MDB.old?.actual_fabrication_start_date
        );

        if (fstNewValue === fstOldValue) {
          return cellWithActualPlannedDates(fstOldValue, actualValue);
        }

        return cellWithActualDateDifference("", fstOldValue, actualValue);
      }

      if (fstNewValue === fstOldValue) {
        return cellWithPlannedDate(fstNewValue);
      }
      return cellWithDataDifference(fstNewValue, fstOldValue);
    }
    if (params.colDef.field === "shipment_date") {
      const shipmentNewValue = params.data?.MDB?.new?.shipment_date
        ? DateUtils.format(params.data?.MDB?.new?.shipment_date)
        : "";
      const shipmentOldValue = params.data?.MDB?.old?.shipment_date
        ? DateUtils.format(params.data?.MDB?.old?.shipment_date)
        : "";

      if (isDeleted && !params.data.MDB.old?.actual_shipment_date) {
        return cellWithPlannedDate(shipmentOldValue);
      }

      if (params.data.MDB.old?.actual_shipment_date) {
        const actualValue = DateUtils.format(
          params.data.MDB.old?.actual_shipment_date
        );

        if (shipmentNewValue === shipmentOldValue) {
          return cellWithActualPlannedDates(shipmentOldValue, actualValue);
        }

        return cellWithActualDateDifference("", shipmentOldValue, actualValue);
      }

      if (shipmentNewValue === shipmentOldValue) {
        return cellWithPlannedDate(shipmentNewValue);
      }
      return cellWithDataDifference(shipmentNewValue, shipmentOldValue);
    }
    if (params.colDef.field === "ROJ_date") {
      const rojNewValue = params.data?.MDB?.new.ROJ_date
        ? DateUtils.format(params.data?.MDB?.new.ROJ_date)
        : "";
      const rojOldValue = params.data?.MDB?.old?.ROJ_date
        ? DateUtils.format(params.data?.MDB?.old?.ROJ_date)
        : "";

      if (isDeleted && !params.data.MDB.old?.actual_ROJ) {
        return cellWithPlannedDate(rojOldValue);
      }

      if (params.data.MDB.old?.actual_ROJ) {
        const actualValue = DateUtils.format(params.data.MDB.old?.actual_ROJ);

        if (rojNewValue === rojOldValue) {
          return cellWithActualPlannedDates(rojOldValue, actualValue);
        }

        return cellWithActualDateDifference("", rojOldValue, actualValue);
      }

      if (rojNewValue === rojOldValue) {
        return cellWithPlannedDate(rojNewValue);
      }
      return cellWithDataDifference(rojNewValue, rojOldValue);
    }

    return "";
  };

  return <div className="flex w-full py-1">{renderMaterialUI()}</div>;
}

export default MaterialScheduleCellRenderer;
