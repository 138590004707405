import { useQuery } from "@apollo/client";
import CiqAgGridUtil from "admin-app/components/ag-grid-util";
import {
  LoginFeatureLinkCellRenderer,
  ProjectFeatureLinkCellRenderer
} from "admin-app/components/cellRenderer";
import { AUDIT_SUB_PROJECT_FEATURE } from "admin-app/services/queries";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Select } from "antd";
import SearchInput from "components/search-input";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

type TAuditSubProjItem = {
  id: string;
  name: string;
  user_email: string;
  login_feature_count: number | undefined;
  submittal_feature_count: number | undefined;
  submittal_db_feature_count: number | undefined;
  material_feature_count: number | undefined;
  material_db_feature_count: number | undefined;
};

// type RangeValue = [moment.Moment | null, moment.Moment | null] | null;

function AuditSubProjectFeatureEvents({
  subscriptionPK,
  subId
}: {
  subId: string;
  subscriptionPK: any;
}) {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [auditUsersData, setAuditUsersData] = useState<
    Array<TAuditSubProjItem>
  >([]);
  // const { RangePicker } = DatePicker;

  // const [dateRangeValue, setDateRangeValue] = useState<RangeValue>(null);

  const history = useHistory() as any;

  const { projectId, orgId } = useParams() as any;

  console.log("OrgId:", orgId);

  const { data: auditData, loading } = useQuery(AUDIT_SUB_PROJECT_FEATURE, {
    fetchPolicy: "no-cache",
    variables: { projectId },
    skip: !projectId
  });
  useEffect(() => {
    if (projectId && auditData) {
      const finalArr: Array<TAuditSubProjItem> = [];
      const projUserFeatures: Array<any> =
        auditData.audit_project_feature_events_list_func || [];
      const projusers: Array<any> = auditData.user || [];

      projusers.forEach((projUserData) => {
        const userFeature: any = projUserFeatures.find(
          (prjfeature) => prjfeature.email === projUserData.email
        );
        const loginCount =
          projUserData?.user_events_aggregate?.aggregate?.count || 0;

        if (userFeature) {
          finalArr.push({
            id: projUserData.id,
            name: `${projUserData.first_name} ${projUserData.last_name}`,
            user_email: projUserData.email,
            login_feature_count: loginCount,
            submittal_feature_count: userFeature.feature_events?.SUBMITTAL || 0,
            submittal_db_feature_count:
              userFeature.feature_events?.SUBMITTAL_DATE_BLOCK || 0,
            material_feature_count: userFeature.feature_events?.MATERIAL || 0,
            material_db_feature_count:
              userFeature.feature_events?.MATERIAL_DATE_BLOCK || 0
          });
        } else {
          finalArr.push({
            id: projUserData.id,
            name: `${projUserData.first_name} ${projUserData.last_name}`,
            user_email: projUserData.email,
            login_feature_count: loginCount,
            submittal_feature_count: 0,
            submittal_db_feature_count: 0,
            material_feature_count: 0,
            material_db_feature_count: 0
          });
        }
      });

      setAuditUsersData(finalArr);
    }
  }, [auditData, projectId]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      editable: false,
      filter: true,
      resizable: true,
      menuTabs: ["filterMenuTab"]
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo<ColDef[]>(
    () => [
      {
        colId: "name",
        field: "name",
        headerName: "Name"
      },
      {
        colId: "user_email",
        field: "user_email",
        headerName: "Email"
      },
      {
        colId: "login_feature_count",
        field: "login_feature_count",
        headerName: "Login",
        cellRenderer: LoginFeatureLinkCellRenderer
      },
      {
        colId: "submittal_feature_count",
        field: "submittal_feature_count",
        headerName: "Submittal",
        cellRenderer: ProjectFeatureLinkCellRenderer
      },
      {
        colId: "submittal_db_feature_count",
        field: "submittal_db_feature_count",
        headerName: "Submittal DateBlock",
        cellRenderer: ProjectFeatureLinkCellRenderer
      },
      {
        colId: "material_feature_count",
        field: "material_feature_count",
        headerName: "Material",
        cellRenderer: ProjectFeatureLinkCellRenderer
      },
      {
        colId: "material_db_feature_count",
        field: "material_db_feature_count",
        headerName: "Material DateBlock",
        cellRenderer: ProjectFeatureLinkCellRenderer
      }
    ],
    []
  );

  useEffect(() => {
    if (loading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [loading]);

  const gridContext = {
    projectId,
    organization:
      subscriptionPK?.subscription_by_pk?.organization_subscriptions[0]
        .organization.name,
    subscription: subscriptionPK?.subscription_by_pk?.name,
    subId,
    orgId
  };

  // const disabledDate: DatePickerProps["disabledDate"] = (current) => {
  //   if (current) {
  //     return Math.abs(moment().diff(current, "days")) >= 7;
  //   }
  //   return false;
  // };

  return (
    <div className="h-[calc(100vh-140px)] px-1">
      <div className="flex w-full justify-between items-center">
        <div className="flex space-x-3 items-center">
          <div className="flex space-x-1 items-center">
            <div>Project: </div>
            <Select
              placeholder="Select Project"
              className="w-60"
              value={projectId}
              onChange={(value) => {
                history.push(
                  `/organizations/${orgId}/subscriptions/${subId}/project/${value}`
                );
              }}
              options={
                subscriptionPK &&
                subscriptionPK.subscription_by_pk &&
                subscriptionPK.subscription_by_pk.projects_owned
                  ? subscriptionPK.subscription_by_pk.projects_owned.map(
                      (p: any) => ({
                        label: p.name,
                        value: p.id
                      })
                    )
                  : []
              }
            />
          </div>
          {/* <div className="flex space-x-1 items-center">
            <div>Select Date Range: </div>
            <RangePicker
              disabledDate={disabledDate}
              onChange={(values, formatString) => {
                // setDateRangeValue(values);
                console.log("values....:", values);
                console.log("formatString....:", formatString);

                // history.push(`/subscriptions/:subId/${projectId}/:fromdate?/:todate?`);
              }}
            />
          </div> */}
        </div>
        <SearchInput
          placeholder="Search"
          onChange={(text) => {
            gridRef.current?.api?.setQuickFilter(text);
          }}
        />
      </div>
      <CiqAgGridUtil
        gridRef={gridRef}
        rowData={auditUsersData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        context={gridContext}
      />
    </div>
  );
}
export default AuditSubProjectFeatureEvents;
