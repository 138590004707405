import { useCallback, useContext, useEffect, useState } from "react";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  QUERY_COMPANY_LIST,
  QUERY_PROJECT_INTEGRATION_USERS,
  QUERY_PROJECT_USERS,
  QUERY_PROJECT_VENDORS
} from "services/graphQL/queries";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { useParams } from "react-router";
import { UserProjectRoles, EUserTypes, EUserRoleName } from "../constants";
import { useCIQQuery } from "./ciq-gql-hooks";

export type ProjectParticipants = {
  dataLoaded: boolean;
  gcReviewers: any;
  designReviewers: any;
  tradePartners: any;
  responsibleContractors: any;
  drCompanies: any;
  submitterUsers: any;
  materialTradePartners: any;
  allProjectUsersMap: any;
};
type TAuditChangeLogs = Array<TAuditChangeLog>;
export const useProjectParticipants = (skip?: boolean) => {
  const { projectId } = useParams() as any;
  const { gqlClientForProject, eventLogs }: TProjectContext =
    useContext(ProjectContext);

  const { data: projectUsersResponse, refetch: refetchProjectUsers } =
    useCIQQuery(QUERY_PROJECT_USERS, {
      client: gqlClientForProject,
      variables: {
        where: {}
      },
      skip: skip || !gqlClientForProject
    });

  const { data: projectCompaniesResponse, refetch: refetchProjectCompanies } =
    useCIQQuery(QUERY_PROJECT_VENDORS, {
      client: gqlClientForProject,
      variables: {
        where: {
          status: { _neq: 4 }
        }
      },
      skip: skip || !gqlClientForProject
    });

  const {
    data: subscriptionCompaniesResponse,
    refetch: refetchSubscriptionCompanies
  } = useCIQQuery(QUERY_COMPANY_LIST, {
    client: gqlClientForProject,
    skip: skip || !gqlClientForProject
  });

  // TODO: Incrementally update by identifying changed IDs.
  const handleUpdates = useCallback(
    (logs: TAuditChangeLogs) => {
      const dataSources = [
        "project_vendors",
        "project_users"
        // "user"
      ];
      const projectChangeLog = logs.find((event: TAuditChangeLog) =>
        dataSources.includes(event.data_source)
      );
      if (projectChangeLog && projectChangeLog.project_id === projectId) {
        refetchProjectUsers();
        refetchSubscriptionCompanies();
        refetchProjectCompanies();
      }
    },
    [
      projectId,
      refetchProjectCompanies,
      refetchProjectUsers,
      refetchSubscriptionCompanies
    ]
  );

  useEffect(() => {
    if (!eventLogs || !eventLogs.length) return;
    handleUpdates(eventLogs);
  }, [eventLogs, handleUpdates]);

  const [allParticipants, setAllParticipants] = useState<ProjectParticipants>({
    dataLoaded: false,
    gcReviewers: [],
    designReviewers: [],
    tradePartners: [],
    responsibleContractors: [],
    drCompanies: [],
    submitterUsers: [],
    materialTradePartners: [],
    allProjectUsersMap: null
  });

  useEffect(() => {
    const gcReviewers: any = [];
    let designReviewers: any = [];
    const tradePartners: any = [];
    let responsibleContractors: any = [];
    let drCompanies: any = [];
    const drPOCUsers: any = [];
    let submitterUsers: any = [];
    const submitterPOCUsers: any = [];
    let materialTradePartners = [];

    // console.log({
    //   projectUsersResponse,
    //   projectCompaniesResponse,
    //   subscriptionCompaniesResponse
    // });

    const projectUsers = projectUsersResponse?.project_users;
    const projectCompanies = projectCompaniesResponse?.project_vendors;
    const subscriptionCompanies =
      subscriptionCompaniesResponse?.subscription_vendors;

    if (!projectUsers || !projectCompanies || !subscriptionCompanies) return;

    projectUsers.forEach((item: any) => {
      if (
        item?.project_role?.key ===
        EUserRoleName[EUserRoleName.gc_project_admin]
      ) {
        gcReviewers.push({
          id: item.user_id,
          first_name: item.user.first_name,
          last_name: item.user.last_name,
          email: item.user.email || "",
          company: {
            name: item?.subscription.organization_subscriptions?.[0]
              ?.organization?.name,
            inviteStatus: null
          },
          type: "actual",
          status_id: item?.status_id
        });
      }

      if (
        UserProjectRoles[EUserTypes.ARCHITECT].includes(
          item?.project_role?.key
        ) ||
        UserProjectRoles[EUserTypes.ENGINEER].includes(item?.project_role?.key)
      ) {
        const { user } = item;

        designReviewers.push({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email || "",
          company: {
            name: item?.subscription_vendor?.name,
            inviteStatus:
              item.subscription_vendor?.project_vendors[0]
                ?.project_vendors_company_status?.status
          },
          type: "actual",
          status_id: item?.status_id
        });
      }

      if (
        item.external ||
        UserProjectRoles[EUserTypes.SUBCONTRACTOR].includes(
          item?.project_role?.key
        )
      ) {
        tradePartners.push(item);
      }
    });

    materialTradePartners = projectCompanies.filter((company: any) => {
      return (
        company.type_id === EUserTypes.SUBCONTRACTOR ||
        company.type_id === EUserTypes.OWNER
      );
    });

    responsibleContractors = projectCompanies.filter((company: any) => {
      return company.type_id === EUserTypes.SUBCONTRACTOR;
    });

    // loop materialTradePartners instead of responsibleContractors because it has more companies hence more users
    materialTradePartners.forEach((company: any) => {
      if (company.status === 1) return; // if company status is Not Invited(1) then dont try to add its users
      const usersOfCompany = projectUsers.filter((user: any) => {
        return (
          user.subscription_vendor &&
          user.subscription_vendor?.id === company.vendor_id
        );
      });
      const formattedUser = usersOfCompany.map((item: any) => {
        const { user } = item;
        return {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email || "",
          company: {
            name: company?.subscription_vendor?.name,
            inviteStatus: company?.project_vendors_company_status?.status,
            vendor_id: company?.vendor_id
          },
          type: "actual",
          status_id: item?.status_id
        };
      });
      submitterUsers = [...submitterUsers, ...formattedUser];
    });

    materialTradePartners.forEach((company: any) => {
      // if company status = Not Invited(1) add its POC users
      if (company.status === 1) {
        const foundCompanyFromSubscription = subscriptionCompanies.find(
          (comp: any) => {
            return comp.id === company.vendor_id;
          }
        );
        if (foundCompanyFromSubscription) {
          foundCompanyFromSubscription.trade_partner_pocs.forEach(
            (pocUser: any) => {
              submitterPOCUsers.push({
                id: `${pocUser.first_name} ${pocUser.last_name}`,
                first_name: pocUser.first_name,
                last_name: pocUser.last_name,
                company: {
                  name: company?.subscription_vendor?.name,
                  inviteStatus: company.project_vendors_company_status?.status,
                  vendor_id: company?.vendor_id
                },
                type: "poc"
              });
            }
          );
        }
      }
    });

    submitterUsers = [...submitterUsers, ...submitterPOCUsers];

    drCompanies = projectCompanies.filter((company: any) => {
      return (
        company.type_id === EUserTypes.ARCHITECT ||
        company.type_id === EUserTypes.ENGINEER
      );
    });

    drCompanies.forEach((company: any) => {
      if (company.status === 1) {
        const foundCompanyFromSubscription = subscriptionCompanies.find(
          (comp: any) => {
            return comp.id === company.vendor_id;
          }
        );

        if (foundCompanyFromSubscription) {
          foundCompanyFromSubscription.trade_partner_pocs.forEach(
            (pocUser: any) => {
              drPOCUsers.push({
                id: `${pocUser.first_name} ${pocUser.last_name}`,
                first_name: pocUser.first_name,
                last_name: pocUser.last_name,
                email: pocUser.email || "",
                company: {
                  name: company?.subscription_vendor?.name,
                  inviteStatus: company.project_vendors_company_status?.status,
                  vendor_id: company?.vendor_id
                },
                type: "poc"
              });
            }
          );
        }
      }
    });

    designReviewers = [...designReviewers, ...drPOCUsers];

    const tmpAllUsers: any = {
      dataLoaded:
        !!projectUsers && !!projectCompanies && !!subscriptionCompanies,
      gcReviewers: gcReviewers.sort((a: any, b: any) =>
        a.first_name.localeCompare(b.first_name)
      ),
      designReviewers: designReviewers.sort((a: any, b: any) =>
        a.first_name.localeCompare(b.first_name)
      ),
      responsibleContractors: responsibleContractors.sort((a: any, b: any) =>
        a.subscription_vendor?.name?.localeCompare(b.subscription_vendor?.name)
      ),
      materialTradePartners: materialTradePartners.sort((a: any, b: any) =>
        a.subscription_vendor?.name?.localeCompare(b.subscription_vendor?.name)
      ),
      drCompanies: drCompanies.sort((a: any, b: any) =>
        a.subscription_vendor?.name?.localeCompare(b.subscription_vendor?.name)
      ),
      submitterUsers: submitterUsers.sort((a: any, b: any) =>
        a.first_name.localeCompare(b.first_name)
      ),
      tradePartners
    };

    const allProjectusers = [
      ...gcReviewers,
      ...designReviewers,
      ...submitterUsers
    ];

    // console.log("allProjectusers ", allProjectusers);

    const allProjectUsersMap: any = {};
    for (let p = 0; p < allProjectusers.length; p += 1) {
      const id = allProjectusers[p].id || allProjectusers[p].user_id;
      allProjectUsersMap[id] = allProjectusers[p];
    }
    tmpAllUsers.allProjectUsersMap = allProjectUsersMap;
    setAllParticipants(tmpAllUsers);
  }, [
    projectCompaniesResponse,
    subscriptionCompaniesResponse,
    projectUsersResponse
  ]);

  const getSubmittalUserById = (id: string, collection: string) => {
    return allParticipants[collection as keyof ProjectParticipants].find(
      (user: any) => {
        return (user.id || user.user_id) === id;
      }
    );
  };

  const getSubmittalCompanyById = (id: string, collection: string) => {
    return allParticipants[collection as keyof ProjectParticipants].find(
      (comp: any) => {
        return comp.vendor_id === id;
      }
    );
  };

  return {
    projectParticipants: allParticipants,
    getSubmittalUserById,
    getSubmittalCompanyById
  };
};

export const useIntegrationProjectParticipants = (skip?: boolean) => {
  const { projectId } = useParams() as any;
  const { gqlClientForProject, eventLogs }: TProjectContext =
    useContext(ProjectContext);

  const [allParticipants, setAllParticipants] = useState<ProjectParticipants>({
    dataLoaded: false,
    gcReviewers: [],
    designReviewers: [],
    tradePartners: [],
    responsibleContractors: [],
    drCompanies: [],
    submitterUsers: [],
    materialTradePartners: [],
    allProjectUsersMap: null
  });

  const { data: integrationUsersData, refetch: refetchIntegrationUsersData } =
    useCIQQuery(QUERY_PROJECT_INTEGRATION_USERS, {
      client: gqlClientForProject,
      skip: skip || !gqlClientForProject
    });

  const handleUpdates = useCallback(
    (logs: TAuditChangeLogs) => {
      const projectChangeLog = logs.find(
        (event: TAuditChangeLog) => event.data_source === "import_log"
      );
      if (projectChangeLog && projectChangeLog.project_id === projectId) {
        refetchIntegrationUsersData();
      }
    },
    [projectId, refetchIntegrationUsersData]
  );

  useEffect(() => {
    if (!eventLogs || !eventLogs.length) return;
    handleUpdates(eventLogs);
  }, [eventLogs, handleUpdates]);

  useEffect(() => {
    // console.log("integrationUsersData ", integrationUsersData);

    const allUsers: any = [];
    const responsibleContractors: any = [];
    const responsibleContractorIds: any = [];

    // subscription_vendor?.name

    integrationUsersData?.project_integration_users.forEach((user: any) => {
      if (
        user.project_integration_vendor?.id &&
        !responsibleContractorIds.includes(user.project_integration_vendor?.id)
      ) {
        responsibleContractors.push({
          vendor_id: user.project_integration_vendor?.id,
          subscription_vendor: {
            name: user.project_integration_vendor?.name
          },
          project_vendors_company_status: {
            status: ""
          }
        });
        responsibleContractorIds.push(user.project_integration_vendor?.id);
      }

      let statusId;

      if (user?.user) {
        const projArr = user?.user.project_users;
        if (projArr && projArr?.length > 0) {
          statusId = projArr[0]?.status_id;
        }
      }

      allUsers.push({
        id: user.id,
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        company: {
          name: user.project_integration_vendor?.name,
          inviteStatus: null,
          vendor_id: user.project_integration_vendor?.id
        },
        type: "actual",
        status_id: statusId
      });
    });

    const sortedAllUsers = allUsers.sort((a: any, b: any) =>
      a.first_name.localeCompare(b.first_name)
    );

    const tmpAllUsers: any = {
      dataLoaded: !!integrationUsersData,
      gcReviewers: sortedAllUsers,
      designReviewers: sortedAllUsers,
      submitterUsers: sortedAllUsers,
      responsibleContractors: responsibleContractors.sort((a: any, b: any) =>
        a.subscription_vendor?.name?.localeCompare(b.subscription_vendor?.name)
      )
    };

    const allProjectUsersMap: any = {};
    for (let p = 0; p < sortedAllUsers.length; p += 1) {
      const id = sortedAllUsers[p].id || sortedAllUsers[p].user_id;
      allProjectUsersMap[id] = sortedAllUsers[p];
    }
    tmpAllUsers.allProjectUsersMap = allProjectUsersMap;

    setAllParticipants(tmpAllUsers);
  }, [integrationUsersData]);

  return {
    data: allParticipants
  };
};

export const getSubmittalUserById = (id: string, collection: any[]) => {
  return collection.find((user: any) => {
    return (user.id || user.user_id) === id;
  });
};

export const getSubmittalCompanyById = (id: string, collection: any[]) => {
  return collection.find((comp: any) => {
    return comp.vendor_id === id;
  });
};
