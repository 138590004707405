import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { DateUtils } from "utils/dateutils";
import { QUERY_AUDIT_CHANGE_EVENT } from "./gql-queries";

export type TAuditChangeLog = {
  created_at: string;
  change_type: string;
  data_source: string;
  info: {
    refreshList: Array<any>;
    submittal_ids?: Array<string>;
    material_ids?: Array<string>;
    computation?: boolean;
    event_type?: string;
  };
  object_id: null | string;
  project_id: null | string;
  subscription_id: null | string;
};

const POLLING_INTERVAL = 1000;

function useChangeEventPolling({
  gqlClientForProject
}: {
  gqlClientForProject: any;
}) {
  const lastFetchDate = useRef(DateUtils.dateTimeObj().utc().toISOString());
  const [auditData, setAuditData] = useState<Array<TAuditChangeLog>>([]);

  const { startPolling, stopPolling, refetch } = useQuery<{
    audit_change_events: Array<TAuditChangeLog>;
  }>(QUERY_AUDIT_CHANGE_EVENT, {
    fetchPolicy: "network-only",
    skip: !gqlClientForProject,
    client: gqlClientForProject,
    pollInterval: POLLING_INTERVAL,
    variables: {
      where: {
        created_at: {
          _gt: lastFetchDate.current
        }
      }
    },
    onCompleted(data) {
      console.log(data);
      setAuditData(data.audit_change_events);
      if (data.audit_change_events?.length > 0) {
        lastFetchDate.current = data.audit_change_events[0]?.created_at;
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    startPolling(POLLING_INTERVAL); // Start Polling

    // Store methods as Global for debugging
    (window as any).stopAuditLogPolling = stopPolling;
    (window as any).startAuditLogPolling = (pollInterval = POLLING_INTERVAL) =>
      startPolling(pollInterval);

    return () => stopPolling();
  }, [startPolling, stopPolling]);

  return { data: auditData, refetch };
}

export default useChangeEventPolling;
