import "./index.scss";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { getDashBoardData } from "services/auth";
import { useHistory, useParams } from "react-router";
import { AppContext } from "context/AppProvider";
import ActionItems from "./action-items";
import RiskUpdateComponent from "./risk-update";
import AlertDashboard from "./alert/alert-dashboard";
import PlanningDataQualityReport from "./data-quality-report";
import { FilterNamesEnum, GcDashBoardType, NavigationPageEnum } from "./models";

function GCProjectDashboard2() {
  const history = useHistory();
  const { projectId } = useParams() as any;
  const { tokenRetrievalState, projectDetails } = useContext(ProjectContext);
  const { setSubmittalListFilter, setMaterialListFilter } =
    useContext(AppContext);
  const [dashboardData, setDashboardData] = useState<GcDashBoardType>();

  const goToFiltered = (
    page: NavigationPageEnum,
    filter: FilterNamesEnum,
    value = undefined
  ) => {
    switch (page) {
      case NavigationPageEnum.MaterialLog:
        setMaterialListFilter(filter.toString());
        history.push("./materials");
        break;
      case NavigationPageEnum.SubmittalLog:
        setSubmittalListFilter({ key: filter.toString(), value });
        history.push("./submittals");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getDashBoardFuc = async () => {
      const response = await getDashBoardData(tokenRetrievalState.token, {});
      console.log(response);
      if (response.success) {
        const { data } = response;
        setDashboardData(data.success);
      } else {
        console.log(response.error);
      }
    };

    if (tokenRetrievalState && tokenRetrievalState.token) {
      getDashBoardFuc();
    }
  }, [tokenRetrievalState]);

  return (
    <div className="flex flex-col h-full gc-project-dashboard">
      <div className="w-full py-2 px-6 uppercase font-medium">
        Project Dashboard
      </div>
      <div className="w-full bg-white flex px-5 py-2">
        <div className="h-full grow pr-5">
          <ActionItems
            projectId={projectId}
            dashboardData={dashboardData}
            projectDetails={projectDetails}
            goToFiltered={goToFiltered}
          />
          <RiskUpdateComponent
            dashboardData={dashboardData}
            goToFiltered={goToFiltered}
          />
        </div>
        <div className="h-full w-[500px]">
          <div className="pt-11">
            <AlertDashboard
              projectId={projectId}
              alerts={dashboardData?.alerts}
              goToFiltered={goToFiltered}
            />
          </div>
          <div className="pt-14">
            <PlanningDataQualityReport
              dashboardData={dashboardData}
              goToFiltered={goToFiltered}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default GCProjectDashboard2;
