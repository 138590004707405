/* eslint-disable react/destructuring-assignment */
import { Tooltip } from "antd";
import LinkIcon from "components/svg-icons/link-icon";

function GoverningActivityCellRenderer(params: any) {
  const { isDeleted } = params;
  const newValue: any =
    params.data.new_governing_task?.length > 0
      ? params.data.new_governing_task[0]
      : [];

  const oldValue: any =
    params.data.old_governing_task?.length > 0
      ? params.data.old_governing_task[0]
      : [];

  const newSufix = newValue?.linked_to_end_date ? "( End )" : "";
  const oldSufix = oldValue?.linked_to_end_date ? "( End )" : "";

  const newActivityId = newValue?.activity_id || "";
  const oldActivityId = oldValue?.activity_id || "";

  const newActivityName = newValue?.name || "";
  const oldActivityName = oldValue?.name || "";

  return (
    <div className="flex w-full py-1">
      {isDeleted && (
        <div className="flex items-center h-9 w-full space-x-2">
          <LinkIcon />
          <Tooltip
            destroyTooltipOnHide
            mouseLeaveDelay={0}
            title={oldActivityName}
            showArrow
          >
            <div className="flex h-full">{oldActivityId}</div>
          </Tooltip>
        </div>
      )}
      {newActivityId !== oldActivityId && !isDeleted && (
        <div className="flex-col bg-gray-200 h-10 w-full px-1">
          <div className="flex items-center h-5 w-full space-x-2">
            <span className="flex text-[9px] font-normal h-full items-center">
              New:{" "}
            </span>
            <span className="flex space-x-1 text-[12px] font-semibold h-full items-center">
              <LinkIcon />{" "}
              <div className="min-w-4">
                <Tooltip
                  destroyTooltipOnHide
                  mouseLeaveDelay={0}
                  title={newActivityName}
                  showArrow
                >
                  {newActivityId}
                  {newSufix}
                </Tooltip>
              </div>
            </span>
          </div>
          <div className="flex h-5 items-center font-normal w-full space-x-2">
            <div className="flex text-[9px] font-normal h-full items-center">
              Was:{" "}
            </div>
            <div className="flex space-x-1 text-[11px] font-normal h-full items-center">
              <LinkIcon />{" "}
              <div>
                <Tooltip
                  destroyTooltipOnHide
                  mouseLeaveDelay={0}
                  title={oldActivityName}
                  showArrow
                >
                  {oldActivityId} {oldSufix}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}

      {newActivityId === oldActivityId && !isDeleted && (
        <div className="flex items-center h-9 w-full space-x-2">
          <LinkIcon />
          <Tooltip
            destroyTooltipOnHide
            mouseLeaveDelay={0}
            title={newActivityName}
            showArrow
          >
            <div className="flex h-full">{newActivityId}</div>
          </Tooltip>
        </div>
      )}

      {/* <div className="flex items-center h-full w-full space-x-2">
        <span className="flex h-full items-center">
          {params.data.SDB && <LinkArrowIcon />}
          {params.data.MDB && <LinkIcon />}
        </span>
        <span className="flex text-xm font-normal h-full items-center">
          {params.data.governing_task.start_date}
        </span>
      </div> */}
      {/* {governingActivityEnum ===
        GoverningActivityEnum.Deleted_WasNotGoverningActivity && (
        <div className="flex items-center h-full w-full space-x-2">
          <span className="flex h-full items-center">
            <LinkOutlined />
          </span>
          <span className="flex text-xm font-normal h-full items-center">
            02-03-2023
          </span>
        </div>
      )}

      {governingActivityEnum ===
        GoverningActivityEnum.Deleted_WasSoleLinked && (
        <div className="flex-col bg-gray-300 h-10 w-full px-1">
          <div className="flex items-center h-5 w-full space-x-1">
            <span className="flex text-[9px] font-normal h-full items-center">
              Now:{" "}
            </span>
            <span className="flex space-x-2">
              <CalendarFilled className="p-0.5 border-solid border border-gray-600" />
              <LinkOutlined className="p-0.5 border-solid border border-gray-600" />
            </span>
          </div>
          <div className="flex h-5 items-center font-normal w-full space-x-1">
            <span className="flex text-[9px] font-normal h-full items-center">
              Was:{" "}
            </span>
            <span className="flex h-full items-center">
              <LinkOutlined />
            </span>
            <span className="flex text-xm font-normal h-full items-center">
              02-03-2023
            </span>
            <span className="flex text-xm font-normal h-full items-center">
              (this)
            </span>
          </div>
        </div>
      )}

      {governingActivityEnum ===
        GoverningActivityEnum.Changed_IsGoverningActivity && (
        <div className="flex items-center h-full w-full space-x-2">
          <span className="flex h-full items-center">
            <LinkOutlined />
          </span>
          <span className="flex text-xm font-normal h-full items-center">
            02-03-2023
          </span>
        </div>
      )}

      {governingActivityEnum ===
        GoverningActivityEnum.Changed_IsNotGoverningActivity && (
        <div className="flex items-center h-full w-full space-x-2">
          <span className="flex h-full items-center">
            <LinkOutlined />
          </span>
          <span className="flex text-xm font-normal h-full items-center">
            02-03-2023
          </span>
        </div>
      )}

      {governingActivityEnum ===
        GoverningActivityEnum.Changed_WasNotButNowIs_GoverningActivity && (
        <div className="flex-col bg-gray-300 h-10 w-full px-1">
          <div className="flex items-center h-5 w-full space-x-2">
            <span className="flex text-[9px] font-normal h-full items-center">
              New:{" "}
            </span>
            <span className="flex space-x-1 text-[12px] font-semibold h-full items-center">
              <LinkOutlined /> <span>02-03-2023 (this)</span>
            </span>
          </div>
          <div className="flex h-5 items-center font-normal w-full space-x-2">
            <span className="flex text-[9px] font-normal h-full items-center">
              Was:{" "}
            </span>
            <span className="flex space-x-1 text-[11px] font-normal h-full items-center">
              <LinkOutlined /> <span>21-02-2023</span>
            </span>
          </div>
        </div>
      )}

      {governingActivityEnum ===
        GoverningActivityEnum.Changed_WasAndNowIs_GoverningActivity && (
        <div className="flex items-center h-full w-full space-x-2">
          <span className="flex h-full items-center">
            <LinkOutlined />
          </span>
          <span className="flex text-xm font-normal h-full items-center">
            02-03-2023 (this)
          </span>
        </div>
      )} */}
    </div>
  );
}

export default GoverningActivityCellRenderer;
