import { createContext, useCallback, useMemo, useState } from "react";

export interface AppProviderProps {
  children: React.ReactNode;
}

export const AppContext = createContext<any>({
  submittalListFilter: null,
  setSubmittalListFilter: () => {},
  materialListFilter: null,
  setMaterialListFilter: () => {},
  scheduleListFilter: null
});

function AppProvider({ children }: AppProviderProps) {
  const [appState, setAppState] = useState<any>({
    submittalListFilter: null,
    materialListFilter: null,
    scheduleListFilter: null,
    inIframe: false
  });

  const setSubmittalListFilter = useCallback(
    (filter: { key: string; value: string }) => {
      setAppState({
        ...appState,
        submittalListFilter: filter
      });
    },
    [appState]
  );

  const setMaterialListFilter = useCallback(
    (filter: string) => {
      setAppState({
        ...appState,
        materialListFilter: filter
      });
    },
    [appState]
  );

  const contextValue = useMemo(() => {
    const inIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    return {
      submittalListFilter: appState.submittalListFilter,
      setSubmittalListFilter,
      materialListFilter: appState.materialListFilter,
      setMaterialListFilter,
      scheduleListFilter: appState.scheduleListFilter,

      inIframe: inIframe()
    };
  }, [
    appState.materialListFilter,
    appState.submittalListFilter,
    appState.scheduleListFilter,
    setMaterialListFilter,
    setSubmittalListFilter
  ]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export default AppProvider;
