import { ScheduleImpactStates } from "constants/index";
import { ScheduleImpactSummaryType } from "./model";
import "./sch-impact-summary-table.scss";

function ScheduleImpactSummary(props: {
  scheduleImpactSummary: ScheduleImpactSummaryType;
}) {
  const { scheduleImpactSummary } = props;

  return (
    <div className=" bg-white p-2 mb-2">
      <div className="font-medium text-[14px] text-[#3B3B3B] mb-0.5 pl-0.5">
        SUMMARY
      </div>
      <table className="sch-summary-table text-left w-full ">
        <thead>
          <tr>
            <th>Type of Change</th>
            <th>Total No of Activities</th>
            <th>Activities tied to Procurement</th>
            <th>Impacted Submittals</th>
            <th>Impacted Materials</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="cell-with-padding">
              {ScheduleImpactStates.ACTIVITIES_UPDATED}
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.updated.activities.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.updated.impacting_activities.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.updated.submittals.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.updated.materials.length}
              </span>
            </td>
          </tr>
          <tr>
            <td className="cell-with-padding">
              {ScheduleImpactStates.ACTIVITIES_DELETED}
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.deleted.activities.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.deleted.impacting_activities.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.deleted.submittals.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.deleted.materials.length}
              </span>
            </td>
          </tr>
          <tr>
            <td className="cell-with-padding">
              {ScheduleImpactStates.ACTIVITIES_ADDED}
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.added.activities.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.added.impacting_activities.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.added.submittals.length}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary.added.materials.length}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ScheduleImpactSummary;
