import { split, HttpLink, ApolloClient, InMemoryCache } from "@apollo/client";
// import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { Client, createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import { message as antMessage } from "antd";
import { onError } from "@apollo/client/link/error";
import { GRAPHQL_API_URL, SUBSCRIPTION_GRAPHQL_URL } from "config/config";
import { GATEWAY_GRAPHQL_URL } from "./endpoints";

import { logout } from "./auth";

export const getGraphQLClient = ({ token }: { token: any }) => {
  const accessToken = token;

  const subscriptionClient: Client = createClient({
    url: SUBSCRIPTION_GRAPHQL_URL,
    connectionParams: () => {
      return {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : ""
        }
      };
    }
  });

  subscriptionClient.on("closed", (eventClosedParams: any) => {
    if (eventClosedParams?.reason?.includes("JWTExpired")) {
      antMessage.error("Session expired, redirecting to login");
      setTimeout(() => {
        logout();
      }, 1000);
    }
  });

  const wsLink = new GraphQLWsLink(subscriptionClient);

  const queryLink = new HttpLink({
    uri: GRAPHQL_API_URL
  });

  const mutationLink = new HttpLink({
    uri: GATEWAY_GRAPHQL_URL
  });

  const httpLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "query"
      );
    },
    queryLink,
    mutationLink
  );

  // The split function takes three parameters:
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : ""
      }
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions?.code === "invalid-jwt") {
          antMessage.error("Session expired, redirecting to login");
          setTimeout(() => {
            logout();
          }, 1000);
        }
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // errMsgs += networkError;
    }
    // antMessage.error(errMsgs);
  });

  const GQLClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(splitLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        // fetchPolicy: "no-cache",
        errorPolicy: "all"
      },
      watchQuery: {
        // fetchPolicy: "no-cache",
        errorPolicy: "all"
      },
      mutate: {
        errorPolicy: "all"
      }
    }
  });

  return { GQLClient, wsLink };
};
