import {
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from "react";
import RenamePopup, {
  InputEditDataType
} from "popups/rename-model/rename-popup";
import { useMutation } from "@apollo/client";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { message } from "antd";
import {
  DATEBLOCK_ACTIONS,
  GET_MDB_TEMPLATES
} from "services/graphQL/ciq-queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { ProjectPermissionEnum } from "../../constants";
import DateBlockCard from "./date-block-card";
import {
  MUTATION_UPDATE_DATEBLOCK_PK,
  QUERY_AGG_SUBMITTAL_STATUS_LINKED_BY_MATERIAL_ID
} from "./gql-graphgl";
import {
  DateBlockInfoType,
  GoverningTaskType,
  TDateBlockType,
  TEnableActualReleaseDate,
  TNewDateBlock,
  TProjectTemplateMilestones,
  TProjectWorkflowTemplates
} from "./models";
import {
  transformMaterialDateBlock,
  transformSubmittalDateBlock
} from "./data-transformations";
import RiskStatementView from "./risk-statement";
import "./style.scss";

type TProps = {
  dateblock: TDateBlockType;
  governingTask?: GoverningTaskType;
  disableDB: boolean;
  enableActualReleaseDate?: TEnableActualReleaseDate;
  isGoverningTaskOrMaterialExist?: boolean;
  componentLocation?: "MaterialDetail" | "SubmittalDetail" | "links";
  isSubmittal: boolean;
  additionalMaterialOffset?: boolean;
  projectTemplateMilestones?: Array<TProjectTemplateMilestones>;
};

function DateBlockUI(props: TProps) {
  const {
    dateblock,
    governingTask,
    disableDB,
    enableActualReleaseDate,
    isGoverningTaskOrMaterialExist,
    componentLocation,
    isSubmittal,
    additionalMaterialOffset = true,
    projectTemplateMilestones = []
  } = props;

  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameDataInput, setRenameDataInput] = useState<InputEditDataType>({
    value: "",
    id: ""
  });

  const { data: dateBlockInfoFeatureData, refetch: refecDateblockActionInfo } =
    useCIQQuery<{
      project_feature_configurations: Array<DateBlockInfoType>;
    }>(DATEBLOCK_ACTIONS, {
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      variables: { where: { feature_id: { _eq: 1 } } }
    });

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs.length && previousEventLogs.current !== eventLogs) {
      if (
        eventLogs.some(
          (e) => e.data_source === "project_feature_configurations"
        )
      ) {
        refecDateblockActionInfo();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [refecDateblockActionInfo, eventLogs]);

  const [updateDateBlockMutationPK] = useMutation(
    MUTATION_UPDATE_DATEBLOCK_PK,
    {
      client: gqlClientForProject
    }
  );

  const showMutationResult = async (
    mutationFunction: Function,
    variables: any
  ) => {
    try {
      const res = await mutationFunction({
        variables
      });

      if (res.data) {
        message.success("Updated successfully.");
      }
      if (res.errors) {
        message.error(res.errors[0].message);
      }
    } catch (ex: any) {
      message.error(ex.message);
    }
  };

  const editDateBlock = async (set: any = {}) => {
    const variables: any = { id: dateblock.id, set };
    await showMutationResult(updateDateBlockMutationPK, variables);
  };

  const editOffsetDateBlock = async (newOffset: string, id: string) => {
    const set = {} as any;
    set[id] = newOffset;
    if (!isSubmittal) set.template_value_overidden = true;
    return editDateBlock(set);
  };

  const isSubmittalWorkflowStarted = !!dateblock.mileStones[0]?.actual;

  return (
    <div className="flex items-center relative pt-16">
      {dateblock.mileStones.map((block, index) => (
        <DateBlockCard
          key={block.key}
          dateblock={dateblock}
          block={block}
          previousBlock={index === 0 ? null : dateblock.mileStones[index - 1]}
          nextBlock={
            index === dateblock.mileStones.length - 1
              ? null
              : dateblock.mileStones[index + 1]
          }
          isFirstCard={index === 0}
          isLastCard={index === dateblock.mileStones.length - 1}
          isSubmittal={isSubmittal}
          setIsRenameModalOpen={setIsRenameModalOpen}
          setRenameDataInput={setRenameDataInput}
          editDateBlock={editDateBlock}
          governingTask={governingTask}
          disableDB={disableDB}
          enableActualReleaseDate={enableActualReleaseDate}
          isSubmittalWorkflowStarted={isSubmittalWorkflowStarted}
          isGoverningTaskOrMaterialExist={isGoverningTaskOrMaterialExist}
          componentLocation={componentLocation}
          additionalMaterialOffset={additionalMaterialOffset}
          projectTemplateMilestones={projectTemplateMilestones}
          dateBlockInfoFeatureData={dateBlockInfoFeatureData}
        />
      ))}
      {isRenameModalOpen && (
        <RenamePopup
          isModalOpen={isRenameModalOpen}
          setIsModalOpen={setIsRenameModalOpen}
          inputData={renameDataInput}
          onOk={editOffsetDateBlock}
          title="Edit duration"
          okName="Save"
        />
      )}
    </div>
  );
}

function DateBlockDynamicUIPanel(props: {
  submittal:
    | {
        date_block_submittals: Array<TNewDateBlock>;
        material_tracking: boolean;
        submittal_material_links?: Array<any>;
        submittal_schedule_links?: Array<any>;
      }
    | undefined;
  material:
    | {
        id: any;
        date_block_materials: Array<TNewDateBlock>;
        material_schedule_links?: Array<any>;
        submittal_material_links?: Array<any>;
      }
    | undefined;
  submittalTitle: any;
  materialTitle: any;
  noMaterialDateBlockDiv: any;
  noSubmittalDateBlockDiv: any;
  governingTaskOfMaterial?: GoverningTaskType;
  governingTaskOfSubmittal?: GoverningTaskType;
  disableDB?: boolean;
  componentLocation?: "MaterialDetail" | "SubmittalDetail" | "links";
}) {
  const {
    submittal,
    material,
    submittalTitle,
    materialTitle,
    noMaterialDateBlockDiv,
    noSubmittalDateBlockDiv,
    governingTaskOfMaterial,
    governingTaskOfSubmittal,
    disableDB,
    componentLocation
  } = props;
  const { gqlClientForProject, tokenContents, projectDetails, eventLogs } =
    useContext(ProjectContext);

  const { data: MDBTemplates, refetch: refetchMDB } = useCIQQuery<{
    project_workflow_templates: Array<TProjectWorkflowTemplates>;
  }>(GET_MDB_TEMPLATES, {
    client: gqlClientForProject
  });

  const materialDateBlock: any = useMemo(() => {
    return projectDetails &&
      material &&
      material.date_block_materials?.length > 0
      ? transformMaterialDateBlock(material.date_block_materials[0])
      : undefined;
  }, [material, projectDetails]);

  const { data: linkedSubmittalStatus, refetch: refetchLinkedSubmittalStatus } =
    useCIQQuery<{
      submittals_aggregate: { nodes: Array<{ status: number }> };
    }>(QUERY_AGG_SUBMITTAL_STATUS_LINKED_BY_MATERIAL_ID, {
      client: gqlClientForProject,
      skip: !gqlClientForProject || !materialDateBlock,
      variables: {
        materialId: materialDateBlock?.material_id
      }
    });

  const handlelinkedSubmittalStatus = useCallback(
    (logs: TAuditChangeLog[]) => {
      if (
        material &&
        material.id &&
        logs.some(
          (x) =>
            x.info.material_ids?.length &&
            x.info.material_ids.includes(material.id)
        )
      ) {
        refetchLinkedSubmittalStatus();
      } else if (
        material &&
        material.submittal_material_links &&
        material.submittal_material_links.length
      ) {
        const linkedSubmittalIds = material.submittal_material_links.map(
          (s) => s.submittal_id
        );

        if (
          logs.some(
            (x) =>
              x.info.submittal_ids?.length &&
              x.info.submittal_ids.some((l) => linkedSubmittalIds.includes(l))
          )
        ) {
          refetchLinkedSubmittalStatus();
        }
      }
    },
    [material, refetchLinkedSubmittalStatus]
  );

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      handlelinkedSubmittalStatus(eventLogs);
      if (
        eventLogs.some((x) => x.data_source === "project_workflow_templates")
      ) {
        refetchMDB(); // Refetching Material Template data
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, refetchMDB, handlelinkedSubmittalStatus]);

  const activeTemplateDetails = useMemo(() => {
    if (!MDBTemplates) return undefined;
    let activeTemplateId =
      material?.date_block_materials[0].workflow_template_id;

    if (!activeTemplateId) {
      const defaultTemplate = MDBTemplates.project_workflow_templates.find(
        (template: any) => {
          return template.default;
        }
      );
      activeTemplateId = defaultTemplate!.id;
    }

    return MDBTemplates.project_workflow_templates.find((template: any) => {
      return activeTemplateId === template.id;
    });
  }, [MDBTemplates, material?.date_block_materials]);

  const submittalDateBlock = useMemo(() => {
    return projectDetails &&
      submittal &&
      submittal.date_block_submittals?.length > 0
      ? transformSubmittalDateBlock(submittal.date_block_submittals[0])
      : undefined;
  }, [submittal, projectDetails]);

  const isPermissionNotGrantSubmittal =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.EditDateBlockOffsetSubmittal,
      tokenContents?.role!
    );

  const isPermissionNotGrantMaterial =
    disableDB ||
    isPermissionNotGrantted(
      ProjectPermissionEnum.EditDateBlockOffsetMaterial,
      tokenContents?.role!
    );

  const [enableActualReleaseDate, setEnableActualReleaseDate] =
    useState<TEnableActualReleaseDate>({
      enable: true,
      message: "",
      isAnySubmittalStarted: false
    });

  useEffect(() => {
    if (linkedSubmittalStatus?.submittals_aggregate) {
      const submittalsList = linkedSubmittalStatus?.submittals_aggregate.nodes;
      const isAnySubmittalNotClosed = submittalsList.some(
        (e) => e.status !== 10
      );
      const isAnySubmittalStarted = submittalsList.some((e) => e.status !== 1);

      setEnableActualReleaseDate({
        enable: !isAnySubmittalNotClosed,
        message: isAnySubmittalNotClosed
          ? "Associated submittals are not closed."
          : "",
        isAnySubmittalStarted
      });
    }
  }, [linkedSubmittalStatus]);

  const isGoverningTaskOrMaterialExistForSubmittal = useMemo(() => {
    const isdrivingMatExists = submittal?.submittal_material_links?.find(
      (mat: any) => mat?.driving_material
    );

    const isdrivingTaskExists = submittal?.submittal_schedule_links?.find(
      (tsk: any) => tsk?.driving_task
    );

    return !!(isdrivingMatExists || isdrivingTaskExists);
  }, [
    submittal?.submittal_material_links,
    submittal?.submittal_schedule_links
  ]);

  const isGoverningTaskExistForMaterial = useMemo(() => {
    const isdrivingMatExists = material?.material_schedule_links?.find(
      (tsk: any) => tsk?.driving_task
    );

    return !!isdrivingMatExists;
  }, [material?.material_schedule_links]);

  return (
    <div className="flex w-full h-full date-block-component">
      {submittalDateBlock ? (
        <div className="pr-20">
          <div className="flex w-full h-10">{submittalTitle}</div>
          {componentLocation && (
            <div className="flex h-6">
              {componentLocation === "SubmittalDetail" && (
                <RiskStatementView
                  dateBlocks={submittal?.date_block_submittals}
                />
              )}
            </div>
          )}
          <div>
            <DateBlockUI
              key="submittalDateBlock"
              dateblock={submittalDateBlock}
              governingTask={governingTaskOfSubmittal}
              disableDB={isPermissionNotGrantSubmittal}
              isGoverningTaskOrMaterialExist={
                isGoverningTaskOrMaterialExistForSubmittal
              }
              componentLocation={componentLocation}
              isSubmittal
              additionalMaterialOffset={
                activeTemplateDetails?.additional_offset
              }
            />
          </div>
        </div>
      ) : (
        <div>{noSubmittalDateBlockDiv}</div>
      )}
      {materialDateBlock && submittalDateBlock && (
        <div className="pr-3">
          <div className="h-[7.25rem] w-1" />
          {componentLocation && <div className="flex h-6" />}
          <div className="flex h-0">
            <div className="relative w-0 h-0">
              <div className="absolute w-20 -left-20 border-0 border-t border-solid shrink-0 grow" />
              <div className="absolute w-0 left-0 h-28 -top-10 border-0 border-r border-solid shrink-0 grow" />
              <div className="absolute w-20 border-0 border-t border-solid shrink-0 grow" />
            </div>
          </div>
        </div>
      )}
      {materialDateBlock ? (
        <div className="flex">
          <div className="pr-2">
            <div className="flex w-full h-10">{materialTitle}</div>
            {componentLocation && (
              <div className="flex h-6">
                {componentLocation === "MaterialDetail" && (
                  <RiskStatementView
                    dateBlocks={material?.date_block_materials}
                  />
                )}
              </div>
            )}
            <div className="flex">
              <DateBlockUI
                key="materialDateBlock"
                dateblock={materialDateBlock}
                governingTask={governingTaskOfMaterial}
                disableDB={isPermissionNotGrantMaterial}
                enableActualReleaseDate={enableActualReleaseDate}
                isGoverningTaskOrMaterialExist={isGoverningTaskExistForMaterial}
                componentLocation={componentLocation}
                isSubmittal={false}
                additionalMaterialOffset={
                  activeTemplateDetails?.additional_offset
                }
                projectTemplateMilestones={
                  activeTemplateDetails?.project_template_milestones
                }
              />
              <div className="w-40" />
            </div>
          </div>
        </div>
      ) : (
        <div>{noMaterialDateBlockDiv}</div>
      )}
    </div>
  );
}
export default DateBlockDynamicUIPanel;
