import { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import { DateFilter, DateUtils } from "utils/dateutils";
import { cellWithDataDifference } from "components/schedule-impact-cells/data-difference-cell";
import SubmittalScheduleImpact from "./submittal-schedule-impact";
import MaterialScheduleImpact from "./material-schedule-impact";
import {
  headerHeight,
  rowHeight,
  titleHeight,
  padding,
  maxRowCount
} from "./utils";
import {
  ScheduleComparisonResultType,
  ScheduleImpactResultType
} from "../compare-schedule-2/model";

type Props = {
  scheduleComparison: ScheduleComparisonResultType;
  scheduleImpact: ScheduleImpactResultType;
  gridRef: any;
};

function GridCompareSchedulePage(props: Props) {
  const { scheduleComparison, scheduleImpact, gridRef } = props;
  const [taskData, setTaskData] = useState<Array<any>>([]);

  const getStructureMaterialLinkedObject = (mdb: any, materialId: string) => {
    return {
      MDB: { new: mdb.new, old: mdb.old },
      action: mdb.action || "No action needed",
      material_id: materialId,
      new_governing_task: mdb.new_governing_task ? mdb.new_governing_task : [],
      old_governing_task: mdb.old_governing_task ? mdb.old_governing_task : [],
      linked_submittals: mdb.linked_submittals ? mdb.linked_submittals : []
    };
  };

  const getStructureSubmittalLinkedObject = (sdb: any, submittalId: string) => {
    let newGoverning = sdb?.new_governing_task;
    if (sdb?.new_governing_material && sdb?.new_governing_material.length > 0) {
      const mat = sdb.new_governing_material[0];
      const governing = {
        activity_id: mat.implicit ? "N/A" : mat.material_sequence_id,
        id: mat.id,
        name: mat.implicit
          ? `Material for Submittal ${sdb.old.submittal_sequence_id}`
          : mat.name,
        start_date: mat.start_date
      };
      newGoverning = [governing];
    }

    let oldGoverning = sdb?.old_governing_task || "";
    if (sdb?.old_governing_material && sdb?.old_governing_material.length > 0) {
      const mat = sdb?.old_governing_material[0];
      const governing = {
        activity_id: mat.implicit ? "N/A" : mat.material_sequence_id,
        id: mat.material_id,
        name: mat.implicit
          ? `Material for Submittal ${sdb.old.submittal_sequence_id}`
          : mat.name,
        start_date: mat.start_date,
        title: mat.implicit
          ? `Material for Submittal ${sdb.old.submittal_sequence_id}`
          : mat.name
      };
      oldGoverning = [governing];
    }

    return {
      SDB: { new: sdb?.new, old: sdb?.old },
      action: sdb?.action || "No action needed",
      new_governing_task: newGoverning,
      old_governing_task: oldGoverning,
      submittal_id: submittalId
    };
  };

  useEffect(() => {
    const resultArr: any[] = [];
    const allSubmittalDateBlock = scheduleImpact?.all_submittal_date_block;
    const allMaterialDateBlock = scheduleImpact?.all_material_date_block;
    const scheduleImpacts = scheduleImpact.schedule_impact?.sort(
      (a: any, b: any) => a.activity_id - b.activity_id
    );

    scheduleImpacts?.forEach((impact: any) => {
      const task = {
        activity_id: impact.activity_id,
        status: impact.deleted ? "deleted" : "updated",
        delta: "",
        task_id: impact.task_id,
        linked_submittals: [] as Array<any>,
        linked_materials: [] as Array<any>,
        new_start: undefined,
        new_end_date: undefined,
        old_start: undefined,
        old_end_date: undefined,
        text: "",
        allSubmittalDateBlock: []
      };
      if (impact.deleted) {
        const activity = scheduleComparison?.deleted[task.activity_id];
        task.old_start = activity?.start_date;
        task.old_end_date = activity?.old_end_date;
        task.text = activity?.text;
        if (activity) {
          task.linked_submittals = impact?.linked_submittals
            ? impact?.linked_submittals.map((id: any) =>
                getStructureSubmittalLinkedObject(allSubmittalDateBlock[id], id)
              )
            : [];
          if (Object.keys(allMaterialDateBlock).length > 0)
            task.linked_materials = impact?.linked_materials
              ? impact?.linked_materials.map((linkedMaterial: any) => {
                  return getStructureMaterialLinkedObject(
                    linkedMaterial.MDB,
                    linkedMaterial?.material_id
                  );
                })
              : [];
        }

        task.allSubmittalDateBlock = allSubmittalDateBlock;
      } else {
        const activity = scheduleComparison?.updated[task.activity_id];
        task.new_start = activity?.new_start;
        task.old_start = activity?.old_start;
        task.new_end_date = activity?.new_end_date;
        task.old_end_date = activity?.old_end_date;
        task.text = activity?.text;

        if (task?.old_start || task?.new_start) {
          const oldStartDate = DateUtils.dateTimeObj(new Date(task.old_start!));
          const newStartDate = DateUtils.dateTimeObj(new Date(task.new_start!));
          if (oldStartDate.diff(newStartDate) < 0) {
            task.delta = `+ ${activity?.delta}`;
          } else {
            task.delta = `- ${activity?.delta}`;
          }
        }
        if (activity) {
          task.linked_submittals = [...impact.linked_submittals];
          task.linked_materials = [...impact.linked_materials];
        }

        task.allSubmittalDateBlock = allSubmittalDateBlock;
      }
      task.linked_materials.forEach((mat: any) => {
        mat.linked_submittals?.forEach((id: any) => {
          if (!task.linked_submittals.some((s: any) => s.submittal_id === id)) {
            const sdb = allSubmittalDateBlock[id];
            const otherSubmittal = getStructureSubmittalLinkedObject(sdb, id);
            task.linked_submittals.push(otherSubmittal);
          }
        });
      });

      task.linked_submittals = task.linked_submittals.filter(
        (submittal) => allSubmittalDateBlock[submittal.submittal_id]
      );

      task.linked_materials = task.linked_materials.filter(
        (material) => allMaterialDateBlock[material.material_id]
      );

      if (
        task.linked_submittals?.length > 0 ||
        task.linked_materials?.length > 0
      ) {
        resultArr.push(task);
      }
    });
    setTaskData(resultArr);
  }, [scheduleComparison, scheduleImpact]);

  const detailCellRenderer = (params: any) => {
    return (
      <>
        {params.data.linked_submittals?.length === 0 &&
          params.data.linked_materials?.length === 0 && (
            <div className="flex h-full w-full items-center px-4">
              No materials or submittals are affected due to this change in
              schedule.
            </div>
          )}
        {params.data.linked_submittals?.length > 0 &&
          params.data.linked_materials?.length === 0 && (
            <div className="w-full pb-2 px-5">
              <SubmittalScheduleImpact
                linkedSubmittals={params.data.linked_submittals}
                isDeleted={params.data.status === "deleted"}
              />
            </div>
          )}
        {params.data.linked_submittals?.length === 0 &&
          params.data.linked_materials?.length > 0 && (
            <div className="w-full pb-2 px-5">
              <MaterialScheduleImpact
                linkedMaterials={params.data.linked_materials}
                allSubmittalDateBlock={params.data.allSubmittalDateBlock}
                isDeleted={params.data.status === "deleted"}
              />
            </div>
          )}

        {params.data.linked_submittals?.length > 0 &&
          params.data.linked_materials?.length > 0 && (
            <div>
              <div
                className="w-full pb-2 px-5"
                style={{ marginBottom: 1.5 * padding }}
              >
                <SubmittalScheduleImpact
                  linkedSubmittals={params.data.linked_submittals}
                  isDeleted={params.data.status === "deleted"}
                />
              </div>
              <div className="w-full p-2 px-5">
                <MaterialScheduleImpact
                  linkedMaterials={params.data.linked_materials}
                  allSubmittalDateBlock={params.data.allSubmittalDateBlock}
                  isDeleted={params.data.status === "deleted"}
                />
              </div>
            </div>
          )}
      </>
    );
  };

  const columnDefs: any = useMemo(() => {
    return [
      {
        headerName: "Activity ID",
        headerTooltip: "ACTIVITY ID",
        tooltipField: "activity_id",
        colId: "activity_id",
        field: "activity_id",
        width: 200,
        sort: "asc",
        cellRenderer: "agGroupCellRenderer"
      },
      {
        headerName: "Activity Name",
        headerTooltip: "ACTIVITY NAME",
        tooltipField: "text",
        colId: "text",
        field: "text"
      },
      {
        headerName: "Start Date",
        headerTooltip: "START DATE",
        field: "old_start",
        width: 80,
        cellRenderer: ({ data }: any) => {
          if (data.old_start && data.new_start)
            return cellWithDataDifference(
              DateUtils.format(data.new_start),
              DateUtils.format(data.old_start)
            );
          if (data.old_start) return DateUtils.format(data.old_start);
          return "";
        },
        comparator: DateFilter.comparator
      },
      {
        headerName: "End Date",
        headerTooltip: "END DATE",
        field: "new_end_date",
        width: 80,
        cellRenderer: ({ data }: any) => {
          if (data.old_end_date && data.new_end_date)
            return cellWithDataDifference(
              DateUtils.format(data.new_end_date),
              DateUtils.format(data.old_end_date)
            );
          if (data.old_end_date) return DateUtils.format(data.old_end_date);
          return "";
        },
        comparator: DateFilter.comparator
      },
      {
        headerName: "Delta (Working Days)",
        headerTooltip: "DELTA (WORKING DAYS)",
        colId: "delta",
        field: "delta",
        width: 100,
        valueGetter: ({ data }: any) => {
          if (data?.status === "deleted") {
            return "This task has been removed.";
          }
          return data?.delta;
        },
        tooltipValueGetter: ({ data }: any) => {
          if (data?.status === "deleted") {
            return "This task has been removed.";
          }
          return data?.delta;
        }
      }
    ];
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      autoHeight: true,
      menuTabs: []
    };
  }, []);

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.forEachNode((node: any) => {
      node.setExpanded(node?.rowIndex === 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailRowHeight = (params: any) => {
    const isDetailRow = params.node.detail;
    if (!isDetailRow) {
      return undefined;
    }

    const submittalCount = params.data?.linked_submittals?.length ?? 0;
    const minSubmittalCount = Math.min(submittalCount, maxRowCount);
    const materialCount = params.data?.linked_materials?.length ?? 0;
    const minMaterialCount = Math.min(materialCount, maxRowCount);

    const heightOfSubmittal: number = minSubmittalCount
      ? minSubmittalCount * rowHeight + titleHeight + headerHeight
      : 0;
    const heightOfMaterial: number = minMaterialCount
      ? minMaterialCount * rowHeight + titleHeight + headerHeight
      : 0;

    const additionalPadding =
      minSubmittalCount > 0 && minMaterialCount > 0 ? padding : 0;

    return (
      heightOfSubmittal + heightOfMaterial + 2 * padding + additionalPadding
    );
  };

  const isRowMaster = (params: any) => {
    return (
      params.linked_submittals?.length > 0 || params.linked_materials?.length
    );
  };

  return (
    <div className="w-full h-full">
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine submittal-schedule-page"
      >
        <AgGridReact
          ref={gridRef}
          rowData={taskData!}
          onGridReady={() => {
            // setGridReady(true);
            gridRef.current?.api.sizeColumnsToFit();
          }}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail
          detailCellRenderer={detailCellRenderer}
          onFirstDataRendered={onFirstDataRendered}
          getRowHeight={detailRowHeight}
          isRowMaster={isRowMaster}
          suppressDragLeaveHidesColumns
          suppressMovableColumns
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
        />
      </div>
    </div>
  );
}

export default GridCompareSchedulePage;
