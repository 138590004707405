/* eslint-disable no-nested-ternary */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Switch, message } from "antd";
import { GridLoadingIndicator } from "components/widgets";
import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useReducer
} from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useHistory, useLocation, useParams } from "react-router";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_SCHEDULE_VERSIONS } from "services/graphQL/subscriptions";
import {
  getNewScheduleImpact,
  getScheduleFilesComparison
} from "services/schedule-versions-services";
import { DateUtils } from "utils/dateutils";
import SearchInput from "components/search-input";
import { AgGridReact } from "ag-grid-react";
import { ScheduleTaskType } from "pages/submittal-schedule-linking/models";
import SearchInputField from "pages/submittal-schedule-linking/search-input";
import { Link } from "react-router-dom";
import {
  IActivityCompare,
  ScheduleComparisonResultType,
  ScheduleImpactResultType,
  ScheduleImpactSummaryType
} from "./model";
import RiskCompareSchedule from "./risk-compare-schedule";
import GridCompareSchedulePage from "../compare-schedule";

import {
  ScheduleChangeStateReducer,
  ScheduleChangeDefaultState,
  ScheduleChangeSharedContext
} from "../../../../context/ScheduleChangeContext";
import { ErrorMessages, EWorkflowStatusDataBlock } from "../../../../constants";

function CompareSchedule() {
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState("");
  const [scheduleList, setScheduleList] = useState([] as Array<any>);
  const [activeVersion, setActiveVersion] = useState("");
  const [selectedVersion, setSelectedVersion] = useState<string>();
  const [loadingCompareData, setLoadingCompareData] = useState(false);
  const [loadedCompareVersion, setLoadedCompareVersion] = useState("");

  const [isRiskViewEnable, enableRiskView] = useState(true);
  const childVizRef = useRef<any>();
  const gridRef = useRef<AgGridReact<ScheduleTaskType>>(null);

  const [sharedState, dispatch] = useReducer(
    ScheduleChangeStateReducer,
    ScheduleChangeDefaultState
  );

  const scheduleChangeProviderValue: any = useMemo(
    () => ({
      sharedState,
      dispatch
    }),
    [sharedState]
  );

  const [scheduleComparisonResult, setScheduleComparisonResult] =
    useState<ScheduleComparisonResultType>({
      updated: {},
      added: {},
      deleted: {}
    });

  const [scheduleImpactResult, setScheduleImpactResult] =
    useState<ScheduleImpactResultType>({
      all_submittal_date_block: {},
      all_material_date_block: {},
      schedule_impact: []
    });

  const [scheduleImpactSummary, setscheduleImpactSummary] =
    useState<ScheduleImpactSummaryType>({
      updated: {
        submittals: [],
        materials: [],
        activities: [],
        impacting_activities: []
      },
      deleted: {
        submittals: [],
        materials: [],
        activities: [],
        impacting_activities: []
      },
      added: {
        submittals: [],
        materials: [],
        activities: [],
        impacting_activities: []
      }
    });

  const [isMakingActiveLoading, setMakingActiveLoading] = useState(false);
  const [isMakeScheduleActiveModalOpen, setMakeScheduleActiveModalOpen] =
    useState(false);
  const [skipSubscribe, setSkipSubscribe] = useState(true);
  const { tokenRetrievalState, gqlClientForProject } =
    useContext(ProjectContext);
  const { search } = useLocation();
  const history = useHistory();

  const { data: scheduleListData, loading } = useSubscription(
    SUBSCRIPTION_GET_SCHEDULE_VERSIONS,
    {
      client: gqlClientForProject,
      skip: skipSubscribe
    }
  );

  useEffect(() => {
    setTimeout(() => {
      // CIQ-292
      setSkipSubscribe(false);
    }, 10);
  }, []);

  const selectedVersionId = new URLSearchParams(search).get(
    "selectedVersionId"
  );

  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (scheduleListData) {
      setScheduleList(scheduleListData.schedule_import_details);
      const activeVersionTemp = scheduleListData.schedule_import_details.find(
        (e: any) => {
          return e.is_active;
        }
      );
      if (activeVersionTemp) {
        setActiveVersion(activeVersionTemp.import_log.id);
        // If already selected version then no need to set again
        if (selectedVersionId) {
          setSelectedVersion(selectedVersionId);
        } else {
          setSelectedVersion((prev) => prev || activeVersionTemp.import_log.id);
        }
      }
    }
  }, [scheduleListData, selectedVersionId]);

  const getFormatedSummary = (
    summaryRes: any,
    scheduleComparison: any,
    dateBlocks: { all_material_date_block: any; all_submittal_date_block: any }
  ) => {
    const summary: any = {
      updated: {
        submittals: [],
        materials: [],
        activities: Object.keys(scheduleComparison.updated),
        impacting_activities: []
      },
      deleted: {
        submittals: [],
        materials: [],
        activities: Object.keys(scheduleComparison.deleted),
        impacting_activities: []
      },
      added: {
        submittals: [],
        materials: [],
        activities: Object.keys(scheduleComparison.added),
        impacting_activities: []
      }
    };

    const isInProgessMaterial = (task: any) =>
      task.materials.some(
        (materialId: any) =>
          EWorkflowStatusDataBlock.WF_COMPLETED !==
          dateBlocks.all_material_date_block[materialId].wfData.overallStatus
      );

    const isInProgessSubmittal = (task: any) =>
      task.submittals.some(
        (submittalId: string) =>
          EWorkflowStatusDataBlock.WF_COMPLETED !==
          dateBlocks.all_submittal_date_block[submittalId].wfData.overallStatus
      );

    Object.keys(summaryRes.updated).forEach((taskId) => {
      if (summary.updated.activities.includes(taskId)) {
        const dd = summaryRes?.updated[taskId];
        summary.updated.submittals = summary.updated.submittals.concat(
          dd.submittals
        );
        summary.updated.materials = summary.updated.materials.concat(
          dd.materials
        );
        const task = summaryRes.updated[taskId];
        if (isInProgessMaterial(task) || isInProgessSubmittal(task))
          summary.updated.impacting_activities.push(taskId);
      }
    });

    Object.keys(summaryRes.deleted).forEach((taskId) => {
      if (summary.deleted.activities.includes(taskId)) {
        const dd = summaryRes.deleted[taskId];
        summary.deleted.submittals = summary.deleted.submittals.concat(
          dd.submittals
        );
        summary.deleted.materials = summary.deleted.materials.concat(
          dd.materials
        );

        const task = summaryRes.deleted[taskId];
        if (isInProgessMaterial(task) || isInProgessSubmittal(task))
          summary.deleted.impacting_activities.push(taskId);
      }
    });
    Object.keys(summaryRes.added).forEach((taskId) => {
      if (summary.added.activities.includes(taskId)) {
        const dd = summaryRes.added[taskId];
        summary.added.submittals = summary.added.submittals.concat(
          dd.submittals
        );
        summary.added.materials = summary.added.materials.concat(dd.materials);
        const task = summaryRes.added[taskId];

        if (isInProgessMaterial(task) || isInProgessSubmittal(task))
          summary.added.impacting_activities.push(taskId);
      }
    });

    summary.updated.submittals = [...new Set(summary.updated.submittals)];
    summary.updated.materials = [...new Set(summary.updated.materials)];

    summary.deleted.submittals = [...new Set(summary.deleted.submittals)];
    summary.deleted.materials = [...new Set(summary.deleted.materials)];

    summary.added.submittals = [...new Set(summary.added.submittals)];
    summary.added.materials = [...new Set(summary.added.materials)];

    summary.added.impacting_activities = [
      ...new Set(summary.added.impacting_activities)
    ];
    summary.deleted.impacting_activities = [
      ...new Set(summary.deleted.impacting_activities)
    ];
    summary.updated.impacting_activities = [
      ...new Set(summary.updated.impacting_activities)
    ];
    return summary;
  };

  const loadComparisonData = () => {
    if (!selectedVersion) return;
    setLoadingCompareData(true);
    setLoadedCompareVersion(selectedVersion);
    const scheduleComparisonResponse: any = getScheduleFilesComparison(
      activeVersion,
      selectedVersion,
      tokenRetrievalState?.token
    );
    const scheduleImpactResponse = getNewScheduleImpact(
      selectedVersion,
      tokenRetrievalState?.token,
      false
    );

    const toDispatch = {
      params: {
        importLogId: selectedVersion,
        sourceLogId: activeVersion,
        projectToken: tokenRetrievalState?.token
      }
    };

    dispatch(toDispatch);

    Promise.all([scheduleComparisonResponse, scheduleImpactResponse])
      .then((res: [any, any]) => {
        if (res[0].error || res[1].error) {
          message.error(ErrorMessages.SomethingWentWrong);
          return;
        }
        const scheduleComparison =
          res[0].data.success.schedule_comparison_result ||
          ({} as {
            updated: {
              [key: string]: IActivityCompare;
            };
          });

        setScheduleComparisonResult(scheduleComparison);
        const allOpenSubmittalDateBlock = {} as any;
        const allSubmittals = res[1].data.success.all_submittal_date_block;
        Object.keys(res[1].data.success.all_submittal_date_block).forEach(
          (key) => {
            if (allSubmittals[key].workflow_status !== "COMPLETED")
              allOpenSubmittalDateBlock[key] = allSubmittals[key];
          }
        );

        const scheduleImpactObj = {
          all_submittal_date_block: allOpenSubmittalDateBlock,
          all_material_date_block: res[1].data.success.all_material_date_block,
          schedule_impact: res[1].data.success.schedule_impact
        };

        const summaryRes = res[1].data.success.impact_summary as any;
        const summary = getFormatedSummary(
          summaryRes,
          scheduleComparison,
          res[1].data.success
        );
        setscheduleImpactSummary(summary);
        setScheduleImpactResult(scheduleImpactObj);
      })
      .finally(() => {
        setLoadingCompareData(false);
      });
  };

  const resetToInitialState = () => {
    setLoadedCompareVersion("");
    setScheduleComparisonResult({
      updated: {},
      added: {},
      deleted: {}
    });
  };

  const fullSelectedScheduleName = useMemo(() => {
    const selectedScheduleDetails = scheduleList.find((schedule: any) => {
      return schedule.import_log.id === selectedVersion;
    });
    return selectedScheduleDetails?.schedule_date
      ? `${DateUtils.format(selectedScheduleDetails?.schedule_date)}-${
          selectedScheduleDetails?.schedule_name
        }`
      : selectedScheduleDetails?.schedule_name;
  }, [scheduleList, selectedVersion]);

  const handleProceedTosetActive = async () => {
    if (!selectedVersion) return;
    setMakingActiveLoading(true);

    const scheduleImpactResponse: any = await getNewScheduleImpact(
      selectedVersion,
      tokenRetrievalState?.token,
      true
    );

    if (scheduleImpactResponse?.data?.success) {
      message.success(`${fullSelectedScheduleName} is now the active version`);
      resetToInitialState();
      setMakeScheduleActiveModalOpen(false);
    } else {
      message.error("Failed to make active version");
    }
    setMakingActiveLoading(false);
  };

  useEffect(() => {
    if (activeVersion && selectedVersionId && scheduleListData) {
      loadComparisonData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersionId, activeVersion, scheduleListData]);

  const onSearch = (value: string) => {
    childVizRef?.current?.onSearch(value);
    dispatch({ searchText: value });
  };

  const notFoundContent = (
    <div className="text-center">
      There are no schedules to compare with.
      <Link to="?tab=2"> Click here </Link>
      to upload more schedule versions.
    </div>
  );

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center">
          <div>
            Select another version:
            <Select
              className="w-[200px] ml-1"
              loading={loading}
              value={selectedVersion}
              onChange={(e: string) => {
                setSelectedVersion(e);
                if (selectedVersionId) {
                  history.push(`/project/${projectId}/schedule?tab=5`);
                }
              }}
              notFoundContent={notFoundContent}
            >
              {scheduleList.map((version: any) => {
                return (
                  <Option
                    key={version?.import_log?.id}
                    value={version?.import_log?.id}
                    placeholder="Select Schedule Version"
                    disabled={version?.is_active}
                  >
                    <div className="max-w-full relative overflow-hidden truncate">
                      {version?.is_active && (
                        <span className="text-[6px] font-semibold absolute -top-[7.5px] right-1">
                          ACTIVE
                        </span>
                      )}
                      {version?.schedule_date &&
                        `${DateUtils.format(version?.schedule_date)}-${
                          version?.schedule_name
                        }`}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>
          {activeVersion &&
          selectedVersion &&
          activeVersion !== selectedVersion ? (
            <div className="flex px-2 space-x-2 items-center">
              {loadedCompareVersion !== selectedVersion ? (
                <Button onClick={loadComparisonData} className="mx-2">
                  Compare
                </Button>
              ) : (
                !loadingCompareData && (
                  <Button
                    className="mx-2"
                    onClick={() => {
                      setMakeScheduleActiveModalOpen(true);
                    }}
                  >
                    Make this the active version
                  </Button>
                )
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        {loadedCompareVersion === selectedVersion && !loadingCompareData && (
          <div className="font-weight-bold flex space-x-2 items-center">
            {isRiskViewEnable ? (
              <SearchInput placeholder="Search" onChange={onSearch} />
            ) : (
              <SearchInputField
                gridRef={gridRef}
                searchText={searchValue}
                getSearchText={(str: string) => setSearchValue(str)}
              />
            )}
            <div className="pl-2">Grid View</div>
            <Switch
              className="mx-2"
              defaultChecked
              onChange={(value) => enableRiskView(value)}
              disabled={loading || loadingCompareData}
            />
            <div className="pr-2">Risk View</div>
          </div>
        )}
      </div>
      <div
        className="mt-2"
        style={{
          height: "calc(100vh - 170px)"
        }}
      >
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <GridLoadingIndicator />
          </div>
        ) : loadedCompareVersion !== selectedVersion ? (
          <div className="w-full h-full flex items-center justify-center">
            {scheduleList.length > 0
              ? " Please select another version to compare to"
              : notFoundContent}
          </div>
        ) : loadingCompareData ? (
          <div className="w-full h-full flex items-center justify-center">
            <GridLoadingIndicator />
          </div>
        ) : isRiskViewEnable ? (
          <ScheduleChangeSharedContext.Provider
            value={scheduleChangeProviderValue}
          >
            <RiskCompareSchedule
              scheduleImpactSummary={scheduleImpactSummary}
              ref={childVizRef}
            />
          </ScheduleChangeSharedContext.Provider>
        ) : (
          <GridCompareSchedulePage
            scheduleComparison={scheduleComparisonResult!}
            scheduleImpact={scheduleImpactResult}
            gridRef={gridRef}
          />
        )}
      </div>

      <Modal
        open={isMakeScheduleActiveModalOpen}
        onOk={handleProceedTosetActive}
        okText="Proceed"
        closable={false}
        onCancel={() => {
          setMakeScheduleActiveModalOpen(false);
        }}
        okButtonProps={{
          disabled: isMakingActiveLoading,
          loading: isMakingActiveLoading
        }}
        cancelButtonProps={{ disabled: isMakingActiveLoading }}
        width="420px"
        destroyOnClose
        maskClosable={!isMakingActiveLoading}
      >
        <div className="p-2">
          <div className="flex flex-row space-x-3">
            <div>
              <ExclamationCircleOutlined className="text-[#faad14] text-2xl" />
            </div>
            <div>
              By making{" "}
              <span className="font-semibold">{fullSelectedScheduleName} </span>
              the active version, you are accepting the changes to the submittal
              and material schedules as shown in this page. Do you want to
              proceed?
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CompareSchedule;
