import { Upload, Button } from "antd";

function UploadFileButton(props: any) {
  const { onFileSelect } = props;
  return (
    <Upload
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      listType="picture"
      maxCount={3}
      multiple
      onChange={onFileSelect}
    >
      <Button>+ Upload </Button>
    </Upload>
  );
}

export default UploadFileButton;
