import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { DATE_FORMAT_MMDDYYYY, DateFilter, DateUtils } from "utils/dateutils";
import { RiskLevelType } from "components/date-block/models";
import { TActivitiesV3, TLinkedMaterial, TLinkedSubmittal } from "./models";
import "./style.scss";
import { getRiskAssessmentForEntity } from "./utils";
import MaterialsGroupDiffV6 from "./MaterialGroupDiffV6";
import SubmittalGroupDiffV6 from "./SubmittalGroupDiffV6";
import { EntityType } from "../../../../constants/index";

function ImpactBoxHeader({ activity }: { activity: TActivitiesV3 }) {
  return (
    <div className="impactBoxHeader">
      <h4 className="font-bold m-0">
        <i className="mr-2">{activity.id}</i>
        {activity.text}
      </h4>
      {activity.deleted ? (
        <div className="text-right">
          Linked activity has been removed in the selected schedule. Please
          relink to a suitable activity, if required.
        </div>
      ) : (
        <div className="text-right">
          <span>
            <b>Old Activity Date:</b>{" "}
            {DateUtils.format(activity.old_start, DATE_FORMAT_MMDDYYYY)}
          </span>
          <span className="ml-4">
            <b>New Activity Date:</b>{" "}
            {DateUtils.format(activity.new_start, DATE_FORMAT_MMDDYYYY)}
          </span>
          <span className="ml-4">
            <b>Delta:</b>{" "}
            {DateFilter.comparator(activity.new_start, activity.old_start) < 0
              ? "-"
              : "+"}
            {activity.delta}d
          </span>
        </div>
      )}
    </div>
  );
}

function ScheduleImpactV3({
  activity,
  entityType
}: {
  activity: TActivitiesV3;
  entityType: EntityType;
}) {
  const { projectId } = useParams() as any;
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <ImpactBoxHeader activity={activity} />
      {entityType === EntityType.Submittal &&
        (activity.linked_submittals || []).map(
          (submittal: TLinkedSubmittal, i: number) => {
            const riskAssessment = getRiskAssessmentForEntity(submittal?.SDB);
            const key = `${i}-${submittal.submittal_id}`;
            const linkedToEndDate =
              submittal.old_governing_task?.find(
                (x) => x.activity_id === activity.id
              )?.linked_to_end_date || false;

            return (
              <div key={key}>
                <div className="impactBoxContainerContent">
                  <div className="flex justify-between ">
                    <div>
                      <b className="mr-1">Submittal:</b>
                      <Link
                        to={`/project/${projectId}/submittals/${submittal.submittal_id}`}
                      >
                        <i className="mr-2">
                          {submittal.SDB?.old?.submittal_sequence_id} -{" "}
                          {submittal.SDB?.old?.spec_no}
                        </i>
                        {submittal.SDB.old.title}
                      </Link>
                    </div>
                    <div className="text-right font-semibold">
                      {linkedToEndDate ? "Linked with End Date" : ""}
                    </div>
                  </div>

                  {riskAssessment ? (
                    <div>
                      <b className="mr-1">Risk assessment:</b>
                      <span
                        className={
                          riskAssessment.level === RiskLevelType.High
                            ? "text-[#F3523A]"
                            : ""
                        }
                      >
                        {riskAssessment.text}
                      </span>
                    </div>
                  ) : null}
                </div>

                <div className="impactBoxContainerContent" ref={containerRef}>
                  <SubmittalGroupDiffV6
                    submittal={submittal}
                    activityComparison={activity}
                    projectId={projectId}
                  />
                </div>
              </div>
            );
          }
        )}

      {entityType === EntityType.Material &&
        (activity.linked_materials || []).map(
          (material: TLinkedMaterial, i: number) => {
            const riskAssessment = getRiskAssessmentForEntity(material?.MDB);
            const key = `${i}-${material.material_id}`;
            const linkedToEndDate =
              material.old_governing_task?.find(
                (x) => x.activity_id === activity.id
              )?.linked_to_end_date || false;

            const isImplicit = material.MDB.old?.implicit;

            return (
              <div key={key}>
                <div className="impactBoxContainerContent">
                  <div className="flex justify-between ">
                    <div>
                      <b className="mr-1">Material:</b>
                      <Link
                        to={`/project/${projectId}/materials/${material.material_id}`}
                      >
                        {isImplicit ? (
                          `Material for Submittal ${material?.name}`
                        ) : (
                          <span>
                            <i className="mr-2">
                              {material.MDB?.old?.material_sequence_id}
                            </i>
                            {material.MDB.old.name}
                          </span>
                        )}
                      </Link>
                    </div>
                    <div className="text-right font-semibold">
                      {linkedToEndDate ? "Linked with End Date" : ""}
                    </div>
                  </div>

                  {riskAssessment ? (
                    <div>
                      <b className="mr-1">Risk assessment:</b>
                      <span
                        className={
                          riskAssessment.level === RiskLevelType.High
                            ? "text-[#F3523A]"
                            : ""
                        }
                      >
                        {riskAssessment.text}
                      </span>
                    </div>
                  ) : null}
                </div>

                <div className="impactBoxContainerContent" ref={containerRef}>
                  <MaterialsGroupDiffV6
                    material={material}
                    activityComparison={activity}
                    projectId={projectId}
                  />
                </div>
              </div>
            );
          }
        )}
    </div>
  );
}
export default ScheduleImpactV3;
