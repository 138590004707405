import { useQuery } from "@apollo/client";

import { ProjectContext, ProjectSettingType } from "context/ProjectProvider";
import {
  ProjectParticipants,
  useIntegrationProjectParticipants,
  useProjectParticipants
} from "hooks/project-participants";
import { useGetProjectINTGState } from "hooks/submittal";
import { useSubmittalListPerf } from "pages/submittals-list/hooks/submittal";
import { updateSubmittalList } from "pages/submittals-list/helpers";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  QUERY_SAVED_COLUMN_HEADERS,
  QUERY_SUBMITTAL_TYPES
} from "services/graphQL/queries";
import { isProjectInIntegrationMode, matchUserProjectRole } from "utils/utils";
import { useParams } from "react-router";

import { getLoggedinUserId } from "services/auth";
import { useLazySubmittalListPerf } from "pages/submittals-list/hooks/lazy-submittal-query";
import { TAuditChangeLog } from "change-events/change-event-polling";
import debounce from "lodash.debounce";
import {
  EUserTypes,
  SubmittalStatusToStrMap,
  MAX_NUMBER_OF_FETCH_ITEM
} from "../../../constants";

const statusesMap = {} as any;
Object.keys(SubmittalStatusToStrMap).forEach((keyStatusId: any) => {
  statusesMap[keyStatusId] = {
    name: SubmittalStatusToStrMap[keyStatusId]
  };
});

export type TSubmittalLogData = {
  projectId: string;
  showSubmittalList: boolean;
  submittals: Array<any> | null;
  submittalHeaderMap: any;
  isIntegrationMode: boolean;
  projectDetails: ProjectSettingType | undefined;
  tokenContents:
    | {
        userType: string;
        role: string;
        claims: any;
      }
    | undefined;
  maxNumberOfMilestone: {
    isLastMileStone: boolean;
    numberOfMaxMileStones: number;
  };
  projectParticipants: ProjectParticipants;
  gqlClientForProject: any;
  typesMap: any;
  isCurrentUserGC: boolean;
  statusesMap: any;
  savedColumnsData:
    | {
        project_users: Array<any>;
      }
    | undefined;
};

const initialState = {
  projectId: "",
  showSubmittalList: false,
  submittals: null,
  submittalHeaderMap: undefined,
  isIntegrationMode: false,
  projectDetails: undefined,
  tokenContents: undefined,
  maxNumberOfMilestone: {
    isLastMileStone: false,
    numberOfMaxMileStones: 5
  },
  projectParticipants: {
    dataLoaded: false,
    gcReviewers: undefined,
    designReviewers: undefined,
    tradePartners: undefined,
    responsibleContractors: undefined,
    drCompanies: undefined,
    submitterUsers: undefined,
    materialTradePartners: undefined,
    allProjectUsersMap: undefined
  },
  gqlClientForProject: undefined,
  typesMap: {},
  isCurrentUserGC: false,
  statusesMap,
  savedColumnsData: undefined
} as TSubmittalLogData;

export const useSubmittalLogsData = () => {
  const [state, setState] = useState(initialState);
  const { projectId } = useParams() as any;
  // const [errors, setErrors] = useState<Array<any>>();

  const {
    gqlClientForProject,
    tokenRetrievalState,
    tokenContents,
    columnHeaders: { submittalHeaderMap },
    projectDetails,
    eventLogs
  } = useContext(ProjectContext);
  const [skipSubscribe, setSkipSubscribe] = useState(true);

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  useEffect(() => {
    setState((s) => ({ ...s, tokenContents }));
  }, [tokenContents]);

  const {
    data: submittalListData,
    error: errorSubmittalList,
    loading: submittalListLoading,
    loadedOnce: submittalListLoadedOnce,
    refetch: refetchSubmittalList
  } = useSubmittalListPerf({
    gqlClientForProject,
    projectDetails,
    isIntegrationMode,
    skipSubscribe,
    tokenContents
  });

  const { getLazySubmittal } = useLazySubmittalListPerf({
    isIntegrationMode,
    projectDetails
  });

  const { projectParticipants: WFProjectParticipants } =
    useProjectParticipants(isIntegrationMode);

  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode
  );

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  useEffect(() => {
    if (!projectParticipants.dataLoaded || !submittalListData) return;

    const submittalList = updateSubmittalList(
      submittalListData,
      projectParticipants
    );
    setState((s) => ({ ...s, submittals: submittalList }));
  }, [projectParticipants, submittalListData]);

  const getAndUpdateSubmittalList = useCallback(
    async (submittalIds: Array<string>) => {
      if (!state.submittals) return;
      if (submittalIds.length >= MAX_NUMBER_OF_FETCH_ITEM) {
        refetchSubmittalList();
        return;
      }

      const variables = { where: { id: { _in: submittalIds } } };

      const res = await getLazySubmittal(variables);
      if (res.success && res.data.data) {
        const list =
          res.data.data.submittal_list_wf_mode_func ||
          res.data.data.submittal_list_intg_mode_func;

        const submittalList = updateSubmittalList(list, projectParticipants);
        const items = state.submittals ? [...state.submittals] : [];
        submittalList.forEach((item: any) => {
          const foundIndex = items.findIndex((x) => x.id === item.id);
          if (foundIndex === -1) items.push(item);
          items[foundIndex] = item;
        });
        setState((s) => ({ ...s, submittals: items }));
      }
    },
    [
      getLazySubmittal,
      projectParticipants,
      refetchSubmittalList,
      state.submittals
    ]
  );

  const deleteSubmittalsFromList = useCallback(
    (ids: Array<string>) => {
      const items = state.submittals ? [...state.submittals] : [];
      ids.forEach((id) => {
        const index = items.findIndex((x) => x.id === id);
        items.splice(index, 1);
      });
      setState((s) => ({ ...s, submittals: items }));
    },
    [state.submittals]
  );

  const debounceFetchSubmittals = useMemo(
    () =>
      debounce(() => {
        refetchSubmittalList();
      }, 10000),
    [refetchSubmittalList]
  );
  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const isImportCSV = (log: TAuditChangeLog) =>
        log.change_type === "import" && log.data_source === "submittals";
      if (eventLogs.some(isImportCSV)) {
        refetchSubmittalList(); // ReFetch All Submittal Logs
        return;
      }

      if (
        isIntegrationMode &&
        eventLogs.some((log) => log.data_source === "import_log")
      ) {
        debounceFetchSubmittals(); // ReFetch All Submittal Logs
        return;
      }

      const conditionForSubmittalLog = (log: TAuditChangeLog) =>
        log.info.computation !== false &&
        log?.info?.submittal_ids &&
        log?.info?.submittal_ids?.length > 0;
      if (eventLogs.some(conditionForSubmittalLog)) {
        if (isIntegrationMode) {
          debounceFetchSubmittals(); // ReFetch All Submittal Logs
          return;
        }
        const auditLogs = eventLogs.filter(conditionForSubmittalLog);

        const deletedIds = auditLogs
          .filter((log) => ["delete", "bulk_delete"].includes(log.change_type))
          .map((s: TAuditChangeLog) => s.info.submittal_ids!) as any;
        if (deletedIds.length) {
          const deletedDArray = [...new Set([].concat([], ...deletedIds))];
          deleteSubmittalsFromList(deletedDArray);
        }

        const ids = auditLogs
          .filter((log) => !["delete", "bulk_delete"].includes(log.change_type)) // update create import
          .map((s) => s.info.submittal_ids!) as any;
        if (ids.length) {
          const singleDArray = [...new Set([].concat([], ...ids))];
          getAndUpdateSubmittalList(singleDArray);
        }
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    debounceFetchSubmittals,
    deleteSubmittalsFromList,
    eventLogs,
    getAndUpdateSubmittalList,
    isIntegrationMode,
    refetchSubmittalList
  ]);

  const { data: projectIntegrationQueryData, isIntegrationConfigured } =
    useGetProjectINTGState({
      client: gqlClientForProject,
      skip: !isIntegrationMode
    });

  const { data: submittalTypes, error: errorSubmittalTypes } = useQuery(
    QUERY_SUBMITTAL_TYPES
  );

  const showSubmittalList = useMemo(() => {
    if (projectDetails) {
      if (isIntegrationMode) {
        if (projectIntegrationQueryData) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }, [projectDetails, isIntegrationMode, projectIntegrationQueryData]);

  useEffect(() => {
    if (submittalTypes) {
      const tmpTypesMap: any = {};
      submittalTypes.configurations_value.forEach((type: any) => {
        tmpTypesMap[type.value] = type;
      });
      setState((s) => ({ ...s, typesMap: tmpTypesMap }));
    }
  }, [submittalTypes]);

  const { data: savedColumnsData } = useQuery<{ project_users: Array<any> }>(
    QUERY_SAVED_COLUMN_HEADERS,
    {
      fetchPolicy: "no-cache",
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      variables: {
        where: { user_id: { _eq: getLoggedinUserId() } }
      }
    }
  );

  const typesMap = useMemo(() => {
    const tmpTypesMap: any = {};
    if (submittalTypes) {
      submittalTypes.configurations_value.forEach((type: any) => {
        tmpTypesMap[type.value] = type;
      });
    }
    return tmpTypesMap;
  }, [submittalTypes]);

  useEffect(() => {
    // Note: to handle a glitch in useSubscription hook.. navigate to a submittal detail page from the submittal list and navigate back immediately before the details page loads using "All submittals" navbutton causing the submittal list to not load. mainly happening due to subscription to SUBSCRIPTION_SUBMITTAL_STATUSES.. timeout allows the previous subscription to get cleared.
    setTimeout(() => {
      setSkipSubscribe(false);
    });
  }, []);

  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  return {
    ...state,
    showSubmittalList,
    projectId,
    savedColumnsData,
    projectParticipants,
    submittalHeaderMap,
    tokenRetrievalState,
    submittalListLoading,
    submittalListData,
    submittalListLoadedOnce,
    errorSubmittalList,
    errorSubmittalTypes,
    isIntegrationConfigured,
    isCurrentUserGC,
    gqlClientForProject,
    isIntegrationMode,
    projectDetails,
    typesMap,
    getAndUpdateSubmittalList,
    deleteSubmittalsFromList
  };
};
