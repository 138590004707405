import { Card, Spin } from "antd";
import "./project-dashboard.scss";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useContext, useEffect, useState } from "react";
import { useSubscription } from "@apollo/client";
import {
  SUBSCRIPTION_SCHEDULE_ALERT,
  SUBSCRIPTION_LATEST_SCHED_IMPORTS,
  SUBSCRIPTION_SC_PROJECT_DASHBOARD
} from "services/graphQL/subscriptions";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useHistory } from "react-router-dom";
import { AppContext } from "context/AppProvider";
import { DateUtils } from "utils/dateutils";

ChartJS.register(ArcElement, Tooltip, Legend);

function SCProjectDashboard() {
  const history = useHistory();

  const { gqlClientForProject, projectDetails }: TProjectContext =
    useContext(ProjectContext);
  const { setSubmittalListFilter, setMaterialListFilter }: any =
    useContext(AppContext);

  const { data: dashboardData } = useSubscription(
    SUBSCRIPTION_SC_PROJECT_DASHBOARD,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const { data: latestSchedImportData } = useSubscription(
    SUBSCRIPTION_LATEST_SCHED_IMPORTS,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const { data: scheduleAlertData } = useSubscription(
    SUBSCRIPTION_SCHEDULE_ALERT,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const [dashboardMetrics, setDashboardMetrics] = useState<any>();

  const [submittalChart, setSubmittalChart] = useState<any>(null);
  const [materialChart, setMaterialChart] = useState<any>(null);
  const [isImpactedAlertFound, setImpactedAlert] = useState(false);

  useEffect(() => {
    if (!dashboardData) return;

    const metrics = dashboardData.project_sc_dashboard_func;

    const submittalMetrics: any = {};
    const materialMetrics: any = {};

    metrics.forEach((metric: any) => {
      if (metric.item_type === "submittal") {
        submittalMetrics[metric.status] = metric;
      }
      if (metric.item_type === "material") {
        materialMetrics[metric.status] = metric;
      }
    });

    setDashboardMetrics({
      submittalMetrics,
      materialMetrics
    });

    setSubmittalChart({
      labels: [
        `${submittalMetrics?.create?.count} - Pending SC Assignment`,
        `${submittalMetrics?.external_review?.count} - Pending Design Team Review`,
        `${submittalMetrics?.submit?.count} - Pending SC submission`,
        `${submittalMetrics?.ready_for_distribution?.count} - Pending GC final review`,
        `${submittalMetrics?.gc_review?.count} - Pending GC Review`,
        `${submittalMetrics?.done?.count} - Distributed`
      ],
      datasets: [
        {
          data: [
            submittalMetrics?.create?.count,
            submittalMetrics?.external_review?.count,
            submittalMetrics?.submit?.count,
            submittalMetrics?.ready_for_distribution?.count,
            submittalMetrics?.gc_review?.count,
            submittalMetrics?.done?.count
          ],
          backgroundColor: [
            "rgb(59, 130, 246)",
            "rgb(245, 158, 11)",
            "rgb(20, 184, 166)",
            "rgb(32, 58, 114)",
            "rgb(250, 204, 21)",
            "rgb(236, 72, 153)"
          ],
          hoverOffset: 4
        }
      ]
    });

    setMaterialChart({
      labels: [
        `${materialMetrics?.not_released?.count} - Materials Not Released`,
        `${materialMetrics?.shipped?.count} - In Transit`,
        `${materialMetrics?.released?.count} - Material Released`,
        `${materialMetrics?.delivered?.count} - Delivered`,
        `${materialMetrics?.fabrication_started?.count} - Fabrication Started`
      ],
      datasets: [
        {
          data: [
            materialMetrics?.not_released?.count,
            materialMetrics?.shipped?.count,
            materialMetrics?.released?.count,
            materialMetrics?.delivered?.count,
            materialMetrics?.fabrication_started?.count
          ],
          backgroundColor: [
            "rgb(245, 158, 11)",
            "rgb(20, 184, 166)",
            "rgb(59, 130, 246)",
            "rgb(99, 102, 241)",
            "rgb(250, 204, 21)"
          ],
          hoverOffset: 4
        }
      ]
    });
  }, [dashboardData]);

  const chartJsOptionsforSubmittals: any = {
    datasets: {
      doughnut: {
        cutout: "65%"
      }
    },
    plugins: {
      // legend: {
      //   position: "bottom",
      //   align: "start",
      //   labels: {
      //     usePointStyle: true,
      //     pointStyle: "circle",
      //     color: "rgb(59, 59, 59)",
      //     font: {
      //       size: 10,
      //       weight: 400
      //     }
      //   }
      // }
      htmlLegend: {
        containerID: "legend-container"
      },
      legend: {
        display: false
      }
    }
  };
  const chartJsOptionsforMaterials: any = {
    datasets: {
      doughnut: {
        cutout: "65%"
      }
    },
    plugins: {
      // legend: {
      //   position: "bottom",
      //   align: "start",
      //   labels: {
      //     usePointStyle: true,
      //     pointStyle: "circle",
      //     color: "rgb(59, 59, 59)",
      //     font: {
      //       size: 10,
      //       weight: 400
      //     }
      //   }
      // }
      htmlLegend: {
        containerID: "legend-container-materials"
      },
      legend: {
        display: false
      }
    }
  };
  const latestImportedSchedule =
    latestSchedImportData?.schedule_import_details[0] || null;

  const scheduleAlert = scheduleAlertData?.gantt_tasks_aggregate || null;
  const activefileArr = latestSchedImportData?.schedule_import_details?.filter(
    (item: any) => item?.is_active === true
  );

  useEffect(() => {
    const latestVersionCreatedDateStr =
      latestImportedSchedule?.created_at || "";
    const activeVersionActivatedDateStr =
      activefileArr?.length > 0 ? activefileArr[0]?.activated_at : "";

    if (
      latestVersionCreatedDateStr === "" ||
      activeVersionActivatedDateStr === ""
    ) {
      setImpactedAlert(false);
    }

    const latestDate = new Date(latestVersionCreatedDateStr);
    const activatedDate = new Date(activeVersionActivatedDateStr);

    setImpactedAlert(latestDate > activatedDate);
  }, [activefileArr, latestImportedSchedule]);

  const goToFilteredSubmittals = (filter: string) => {
    setSubmittalListFilter({ key: filter, value: null });
    history.push("./submittals");
  };

  const goToFilteredMaterials = (filter: string) => {
    setMaterialListFilter(filter);
    history.push("./materials");
  };

  const goToFilteredSchedule = () => {
    history.push("./schedule");
  };

  const getOrCreateLegendList = (chart: any, id: any) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");

      legendContainer?.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart: any, args: any, options: any) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: any) => {
        const li = document.createElement("li");

        li.onclick = () => {
          const { type } = chart.config;
          if (type === "pie" || type === "doughnut") {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = `${item.lineWidth}px`;
        // boxSpan.style.display = "inline-block";
        // boxSpan.style.height = "20px";
        // boxSpan.style.marginRight = "10px";
        // boxSpan.style.width = "20px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.fontSize = "10px";
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  };

  return (
    <div className="project-dashboard h-full flex flex-col">
      <div className="grow p-4 py-4 bg-white">
        {dashboardData ? (
          <div className="flex-col space-y-2">
            <div className="hello-user-text flex ">
              <div
                className="overflow-hidden truncate"
                title={projectDetails?.name}
              >
                {`${projectDetails?.name}`}
              </div>
            </div>
            <div className=" h-full grid grid-cols-2 gap-4">
              <div className=" bg-white flex flex-col justify-between">
                <div className="space-y-[18px]">
                  <button
                    type="button"
                    className={`summary-item ${
                      dashboardMetrics?.submittalMetrics?.overdue.count > 0
                        ? "overdue-text cursor-pointer"
                        : "cursor-default"
                    }`}
                    onClick={() => {
                      if (!dashboardMetrics?.submittalMetrics?.overdue.count)
                        return;
                      goToFilteredSubmittals("overdue");
                    }}
                  >
                    <div className="font-medium text-4xl p-2">
                      {dashboardMetrics?.submittalMetrics?.overdue.count}
                    </div>
                    <div className="flex items-center">Overdue Submittals</div>
                  </button>
                  <button
                    type="button"
                    className={`summary-item ${
                      dashboardMetrics?.materialMetrics?.overdue.count > 0
                        ? "overdue-text cursor-pointer"
                        : "cursor-default"
                    }`}
                    onClick={() => {
                      if (!dashboardMetrics?.materialMetrics?.overdue.count)
                        return;
                      goToFilteredMaterials("overdue");
                    }}
                  >
                    <div className="font-medium text-4xl p-2">
                      {dashboardMetrics?.materialMetrics?.overdue.count}
                    </div>
                    <div className="flex items-center">
                      Overdue Material Deliveries
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`summary-item ${
                      dashboardMetrics?.submittalMetrics?.upcoming?.count === 0
                        ? "cursor-default"
                        : "cursor-pointer"
                    }`}
                    onClick={() => {
                      if (!dashboardMetrics?.submittalMetrics?.upcoming.count)
                        return;
                      goToFilteredSubmittals("due-in-days");
                    }}
                  >
                    <div className="font-medium text-4xl p-2">
                      {dashboardMetrics?.submittalMetrics?.upcoming.count}
                    </div>
                    <div className="flex items-center">
                      Submittals due in 5 calendar days
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`summary-item ${
                      dashboardMetrics?.materialMetrics?.upcoming.count > 0
                        ? "overdue-text cursor-pointer"
                        : "cursor-default"
                    }`}
                    onClick={() => {
                      if (!dashboardMetrics?.materialMetrics?.upcoming.count)
                        return;
                      goToFilteredMaterials("due-in-days");
                    }}
                  >
                    <div className="font-medium text-4xl p-2">
                      {dashboardMetrics?.materialMetrics?.upcoming.count}
                    </div>
                    <div className="flex items-center">
                      Material Deliveries due in 5 calendar days
                    </div>
                  </button>
                </div>
              </div>
              <div className="bg-white grid grid-cols-2 gap-6">
                <div className="chart-card">
                  {submittalChart && (
                    <div className="doughnut-chart-container">
                      <div className="total-count ">
                        <div>Total Submittals</div>
                        <div className="font-bold">
                          {dashboardMetrics?.submittalMetrics?.all.count}
                        </div>
                      </div>
                      <div className="doughnut-parent">
                        <Doughnut
                          data={submittalChart}
                          options={chartJsOptionsforSubmittals}
                          plugins={[htmlLegendPlugin]}
                        />
                      </div>
                      <div id="legend-container" className="legend" />
                    </div>
                  )}
                </div>
                <div className="chart-card">
                  {materialChart && (
                    <div className="doughnut-chart-container">
                      <div className="total-count ">
                        <div>Total Materials</div>
                        <div className="font-bold">
                          {dashboardMetrics?.materialMetrics?.all.count}
                        </div>
                      </div>
                      <div className="doughnut-parent">
                        <Doughnut
                          data={materialChart}
                          options={chartJsOptionsforMaterials}
                          plugins={[htmlLegendPlugin]}
                        />
                      </div>
                      <div id="legend-container-materials" className="legend" />
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <Card
                  className="card-block"
                  title={
                    <span className="uppercase text-base text-[#3B3B3B] font-medium tracking-wider">
                      My action items
                    </span>
                  }
                >
                  <div className="space-y-2 h-[300px] overflow-auto">
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          !dashboardMetrics?.submittalMetrics?.to_be_submitted
                            .count
                        )
                          return;
                        goToFilteredSubmittals("to-be-submitted");
                      }}
                      className={`action-item ${
                        dashboardMetrics?.submittalMetrics?.to_be_submitted
                          .count > 0
                          ? "cursor-pointer"
                          : "cursor-default"
                      }`}
                    >
                      {
                        dashboardMetrics?.submittalMetrics?.to_be_submitted
                          .count
                      }{" "}
                      Submittals to be submitted
                    </button>
                  </div>
                </Card>
              </div>
              <div className="">
                <Card
                  className="card-block"
                  title={
                    <span className="uppercase text-base text-[#3B3B3B] font-medium tracking-wider">
                      Schedule Alerts
                    </span>
                  }
                >
                  <div className="space-y-2 h-[300px] overflow-auto text-sm text-[#3B3B3B] font-medium ">
                    {latestImportedSchedule && (
                      <div className="border border-solid border-gray-300 rounded-sm p-2 flex justify-between items-center">
                        <div>
                          New schedule version{" "}
                          <button
                            type="button"
                            style={{
                              backgroundColor: "transparent",
                              border: 0,
                              color: "#4F91F5"
                            }}
                            onClick={() => {
                              goToFilteredSchedule();
                            }}
                            className="underline cursor-pointer"
                          >
                            {DateUtils.format(
                              latestImportedSchedule?.schedule_date,
                              "YYYY-MM-DD"
                            )}
                            _{latestImportedSchedule?.schedule_name}
                          </button>
                          is available
                        </div>
                        <div className="flex px-2 rounded-xl bg-[#3B3B3B1A] w-[150px] h-5 font-light text-[10px] justify-center">
                          Alerted{" "}
                          {DateUtils.format(latestImportedSchedule?.created_at)}
                        </div>
                      </div>
                    )}
                    {scheduleAlert &&
                      scheduleAlert?.aggregate?.count > 0 &&
                      isImpactedAlertFound && (
                        <div className="border border-solid border-gray-300 rounded-sm p-2 flex justify-between">
                          <div>
                            {scheduleAlert?.aggregate?.count} activities related
                            to the submittals and/or materials have been
                            modified.
                          </div>
                          <div className="flex px-2 rounded-xl bg-[#3B3B3B1A] min-w-[150px] h-5 font-light text-[10px] justify-center">
                            Alerted{" "}
                            {DateUtils.format(
                              latestImportedSchedule?.created_at
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-[200px] flex items-center justify-center">
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
}

export default SCProjectDashboard;
