import { InboxOutlined } from "@ant-design/icons";
import { useSubscription } from "@apollo/client";
import { Button, message, Modal, Spin, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import axios from "axios";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { BASE_URL } from "services/endpoints";
import { SUBSCRIPTION_IMPORT_FILE_PROCESSING } from "services/graphQL/subscriptions";
import { downloadStringAsCSVFile, downloadFileInBrowser } from "utils/utils";

export default function ImportMaterialLogs({
  existingMaterialsCount,
  showImportLogDrawer,
  materialHeaderMap,
  onCancel
}: {
  existingMaterialsCount: number;
  showImportLogDrawer: boolean;
  materialHeaderMap: any;
  onCancel: () => void;
}) {
  const { tokenRetrievalState, gqlClientForProject }: TProjectContext =
    useContext(ProjectContext);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [fileUploadId, setFileUploadId] = useState("");
  const [unsupportedFileSelected, setUnsupportedFileSelected] = useState(false);

  const [uploadingState, setUploadingState] = useState<{
    uploading: boolean;
    processingStatus: string;
    processedResults: any;
  }>({
    uploading: false,
    processingStatus: "",
    processedResults: null
  });

  const { data: fileProcessingData } = useSubscription(
    SUBSCRIPTION_IMPORT_FILE_PROCESSING,
    {
      shouldResubscribe: true,
      client: gqlClientForProject,
      skip: !fileUploadId,
      variables: {
        where: { id: { _eq: fileUploadId } }
      }
    }
  );

  const props: UploadProps = {
    accept: "text/csv",
    style: { borderColor: unsupportedFileSelected === true ? "red" : "" },
    multiple: false,
    fileList,
    onChange: (event: any) => {
      console.log("event ", event);
    },
    onRemove: () => {
      setFileList([]);
    },
    onDrop: (e) => {
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file.type !== "text/csv") {
          setUnsupportedFileSelected(true);
          setFileList([]);
          message.error("Please select csv file only");
          return false;
        }
        setUnsupportedFileSelected(false);
        return false;
      }
      return false;
    },
    beforeUpload: (file: RcFile) => {
      if (file.type !== "text/csv") {
        setUnsupportedFileSelected(true);
        setFileList([]);
        message.error("Please select csv file only");
        return false;
      }
      setUnsupportedFileSelected(false);
      setFileList([file]);
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false
    }
  };

  const handleUpload = async () => {
    setUploadingState({
      uploading: true,
      processingStatus: "",
      processedResults: null
    });
    const formData = new FormData();
    formData.append("file", fileList[0] as RcFile);
    formData.append("async", "true");

    let uploadResponse: any = null;
    try {
      uploadResponse = await axios.post(
        `${BASE_URL}/material/import`,
        formData,
        {
          headers: {
            authorization: `Bearer ${tokenRetrievalState.token}`
          }
        }
      );
      // console.log("uploadResponse ", uploadResponse);
    } catch (ex: any) {
      console.log("upload error: ", ex);
      const networkErrMsg = ex.message;
      const serverErrMsg = ex.response?.data?.error;
      message.error(`Import failed: ${serverErrMsg || networkErrMsg}`);

      setUploadingState({
        uploading: false,
        processingStatus: "",
        processedResults: null
      });

      onCancel();

      return;
    }
    // console.log("uploadResponse ", uploadResponse);

    if (uploadResponse.data?.success) {
      setUploadingState({
        uploading: false,
        processingStatus: "PROCESSING",
        processedResults: null
      });
      setFileUploadId(uploadResponse.data?.success);

      setFileList([]);
    }
  };

  useEffect(() => {
    console.log("fileProcessingData ", fileProcessingData);
    // return;
    if (!fileProcessingData || !fileProcessingData.import_log.length) return;
    try {
      const statusComment = JSON.parse(
        fileProcessingData.import_log[0].status_comment
      );
      const processingStatus = fileProcessingData.import_log[0].status;
      setUploadingState({
        uploading: false,
        processingStatus,
        processedResults: statusComment
      });

      if (processingStatus === "PROCESSED" || processingStatus === "FAILED") {
        if (statusComment.File) {
          downloadFileInBrowser(statusComment.File, "Failed_Material_Log.csv");
        }
      }
    } catch (ex) {
      message.error("An error occured while importing the log");
    }
  }, [fileProcessingData]);

  const sampleCSVHeaders = useMemo(() => {
    const headers = [
      materialHeaderMap?.name || "",
      "Spec Section #",
      "Spec Section Name",
      "Material Description",
      "Quantity",
      "Material Tag",
      "Material Size",
      "Manufacturer",
      "Part Number",
      "Serial Number"
    ];

    return headers;
  }, [materialHeaderMap]);

  return (
    <Modal
      className="custom-drawer"
      title="Import Material Log"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showImportLogDrawer}
      onCancel={() => {
        onCancel();
      }}
      destroyOnClose
    >
      {" "}
      <div className="space-y-4 w-full px-3">
        <p className="m-0">
          Use{" "}
          <button
            type="button"
            className="underline bg-transparent text-[#1890ff] border-none p-0 hover:no-underline cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              downloadStringAsCSVFile(
                [...sampleCSVHeaders].join(","),
                "Sample_Material_Log.csv"
              );
            }}
          >
            this template
          </button>{" "}
          to import the material log.
        </p>
        <div className="w-full">
          <Dragger
            {...props}
            disabled={
              uploadingState.uploading || uploadingState.processingStatus !== ""
            }
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            {fileList.length > 0 ? (
              <p className="ant-upload-text">{fileList[0].name}</p>
            ) : (
              <div>
                <p className="ant-upload-text">Click or drag file to upload.</p>
                <p className="ant-upload-hint">
                  Only .csv files are supported.
                </p>
              </div>
            )}
          </Dragger>
        </div>
        <div className="flex justify-center">
          {uploadingState.processingStatus === "" && (
            <Button
              type="primary"
              disabled={!fileList.length || uploadingState.uploading}
              onClick={handleUpload}
              loading={uploadingState.uploading}
            >
              Import
            </Button>
          )}

          {uploadingState.processingStatus === "PROCESSING" && (
            <Spin
              className="mt-4 text-red-500"
              tip="File is being processed.Please do not close page while processing"
              size="default"
            />
          )}

          {(uploadingState.processingStatus === "PROCESSED" ||
            uploadingState.processingStatus === "FAILED") && (
            <div className="w-full grid grid-cols-2">
              <div className="w-20 font-semibold">Total</div>
              <div>{uploadingState.processedResults?.Total}</div>
              {existingMaterialsCount > 0 && (
                <>
                  <div className="w-20 font-semibold">Updated</div>
                  <div>{uploadingState.processedResults?.Updated}</div>
                </>
              )}
              <div className="w-20 font-semibold">Imported</div>
              <div>{uploadingState.processedResults?.Imported}</div>
              <div className="w-20 font-semibold">Failed</div>
              <div>{uploadingState.processedResults?.Failed}</div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
