export const cellWithDataDifference = (newValue: any, oldValue: any) => {
  return (
    <div className="flex-col bg-gray-200 h-10 w-full px-1">
      <div className="flex items-center h-5 w-full space-x-[2px]">
        <span className="flex text-[9px] font-normal h-full items-center">
          Now:
        </span>
        <span className="flex text-[12px] font-semibold h-full items-center">
          {newValue}
        </span>
      </div>
      <div className="flex h-5 items-center font-normal w-full space-x-[2px]">
        <span className="flex text-[9px] font-normal h-full items-center">
          Was:
        </span>
        <span className="flex text-[11px] font-normal h-full items-center">
          {oldValue}
        </span>
      </div>
    </div>
  );
};

export const cellWithActualPlannedDates = (
  plannedValue: any,
  actualValue: any
) => {
  return (
    <div className="flex-col h-10 w-full px-1">
      <div className="flex items-center h-5 w-full space-x-[2px]">
        <span className="flex text-[9px] font-normal h-full items-center">
          Planned:
        </span>
        <span className="flex text-[11px] font-normal h-full items-center">
          {plannedValue}
        </span>
      </div>
      <div className="flex h-5 items-center font-normal w-full space-x-[2px]">
        <span className="flex text-[9px] font-normal h-full items-center">
          Actual:
        </span>
        <span className="flex text-[11px] font-normal h-full items-center">
          {actualValue}
        </span>
      </div>
    </div>
  );
};

export const cellWithActualDateDifference = (
  newValue: any,
  oldValue: any,
  actualValue: any
) => {
  return (
    <div className="flex-col">
      <div className="flex-col bg-gray-200 h-10 w-full px-1">
        <div className="flex items-center h-5 w-full space-x-[2px]">
          <span className="flex text-[9px] font-normal h-full items-center">
            Now:{" "}
          </span>
          <span className="flex text-[12px] font-semibold h-full items-center">
            {newValue}
          </span>
        </div>
        <div className="flex h-5 items-center font-normal w-full space-x-[2px]">
          <span className="flex text-[9px] font-normal h-full items-center">
            Was:{" "}
          </span>
          <span className="flex text-[11px] font-normal h-full items-center">
            {oldValue}
          </span>
        </div>
      </div>
      <div className="flex items-center h-5 w-full">
        <span className="flex text-[9px] font-normal h-full items-center space-x-[2px]">
          Actual:{" "}
        </span>
        <span className="flex text-[11px] font-normal h-full items-center">
          {actualValue}
        </span>
      </div>
    </div>
  );
};

export const cellWithPlannedDate = (newValue: any) => {
  return (
    <div className="flex flex-col h-10 w-full font-normal ">
      <span className="flex items-center h-3 text-[9px]">Planned:</span>
      <span className="flex items-center h-6 text-xm">{newValue}</span>
    </div>
  );
};
