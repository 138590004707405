import { IntegrationType } from "pages/subscription-settings/integrations-tab-new/utils";
import { getAccessToken } from "./auth";
import CIQClient from "./ciq-client";
import { INTEGRATION_BASE_URL } from "./endpoints";

export const getOAuthUrl = async (
  system: string,
  payload: any,
  projectToken?: string
): Promise<any> => {
  const subscriptionToken: any = getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${projectToken || subscriptionToken}` }
  };

  return CIQClient.post({
    url: `${INTEGRATION_BASE_URL}/${system.toLowerCase()}/oauth/new-auth/init`,
    data: payload,
    config
  });
};

export const getCompanies = async (
  system: string,
  authId: string,
  projectToken?: string
): Promise<any> => {
  const subscriptionToken: any = getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${projectToken || subscriptionToken}` }
  };

  const endpoint =
    system === IntegrationType[IntegrationType.AUTODESK]
      ? "accounts"
      : "companies";

  return CIQClient.get({
    url: `${INTEGRATION_BASE_URL}/${system.toLowerCase()}/oauth_operation/${authId}/${endpoint}`,
    config
  });
};

export const createSubscriptionIntegration = async (
  system: string,
  payload: any,
  projectToken?: string
): Promise<any> => {
  const subscriptionToken: any = getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${projectToken || subscriptionToken}` }
  };

  return CIQClient.post({
    url: `${INTEGRATION_BASE_URL}/${system.toLowerCase()}/subscription_config`,
    data: payload,
    config
  });
};

export const updateSubscriptionIntegration = async (
  system: string,
  integrationConfigId: any,
  payload: any,
  projectToken?: string
): Promise<any> => {
  const subscriptionToken: any = getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${projectToken || subscriptionToken}` }
  };

  return CIQClient.put({
    url: `${INTEGRATION_BASE_URL}/${system.toLowerCase()}/subscription_config/${integrationConfigId}`,
    data: payload,
    config
  });
};

export const getCompanyAccounts = (
  system: string,
  payload: { subscriptionConfigId: string },
  projectToken?: any
) => {
  const subscriptionToken: any = getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${projectToken || subscriptionToken}` }
  };

  return CIQClient.get({
    url: `${INTEGRATION_BASE_URL}/${system.toLowerCase()}/subscription_config/${
      payload.subscriptionConfigId
    }/projects`,
    config
  });
};

export const createProjectIntegration = async (
  system: string,
  payload: any,
  projectToken?: string
): Promise<any> => {
  const subscriptionToken: any = getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${projectToken || subscriptionToken}` }
  };

  return CIQClient.post({
    url: `${INTEGRATION_BASE_URL}/${system.toLowerCase()}/project_config`,
    data: payload,
    config
  });
};
export const updateProjectIntegration = async (
  system: string,
  integrationConfigId: any,
  payload: any,
  projectToken?: string
): Promise<any> => {
  const subscriptionToken: any = getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${projectToken || subscriptionToken}` }
  };

  return CIQClient.put({
    url: `${INTEGRATION_BASE_URL}/${system.toLowerCase()}/project_config/${integrationConfigId}`,
    data: payload,
    config
  });
};

export const invokeACCProjectSyncJob = (
  data: { project_config_id: string },
  projectToken: string
): Promise<any> => {
  const config = {
    headers: { Authorization: `Bearer ${projectToken}` }
  };

  return CIQClient.post({
    url: `${INTEGRATION_BASE_URL}/invoke-project-sync-job`,
    data,
    config
  });
};
